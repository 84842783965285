import React, {Component} from 'react';
import {Typography, Breadcrumbs, Link, Skeleton} from '@mui/material';
import {checklistDetails} from '../../DataAccessLayer/services';
import {getData} from '../../DataAccessLayer';
import {Container} from 'reactstrap';
import DetailsTabs from './DetailsTabs';

class Details extends Component {
    state = {
        isLoading: false,
        details: null
    };

    componentDidMount() {
        this.loadChecklistDetails(window.location.pathname.split('/')[2]);
    }

    toggleIsLoading = isLoading => {
        this.setState({isLoading});
    };

    /**
     * Fetches the checklist details
     * @param {string} code The id of the checklist
     */
    loadChecklistDetails = code => {
        this.toggleIsLoading(true);
        getData(checklistDetails(code)).then(details => {
            this.toggleIsLoading(false);
            this.setState({details});
        });
    };

    render() {
        return (
            <>
                {this.state.isLoading && <Skeleton variant="rectangular" />}
                {!this.state.isLoading && this.state.details && (
                    <Container className="myOdu__container_maxWidth">
                        <Typography
                            sx={{color: 'primary.main'}}
                            component="h2"
                            variant="h3"
                        >
                            {this.state.details.checklistDesc}
                        </Typography>
                        <Breadcrumbs aria-label="breadcrumb" sx={{mb: 2}}>
                            <Link
                                underline="hover"
                                color="inherit"
                                href="/"
                                sx={{cursor: 'pointer'}}
                            >
                                Dashboard
                            </Link>
                            <Link
                                underline="hover"
                                color="inherit"
                                href="/checklists"
                                sx={{cursor: 'pointer'}}
                            >
                                Checklists
                            </Link>
                            <Typography color="text.primary">
                                {this.state.details.checklistDesc}
                            </Typography>
                        </Breadcrumbs>
                        {this.state.isLoading && (
                            <Skeleton variant="rectangular" />
                        )}

                        {!this.state.isLoading && this.state.details && (
                            <DetailsTabs
                                items={this.state.details.itemsList ?? []}
                                isLoading={this.state.isLoading}
                                checklistTitle={
                                    this.state.details.checklistDesc
                                }
                            />
                        )}
                    </Container>
                )}
            </>
        );
    }
}

export default Details;
