import React, { Component } from 'react';
import Adhoc from './Adhoc';
import ClassMeetings from './ClassMeetings';
import Menu from './Menu';
import { connect } from 'react-redux';

class ScheduleMeeting extends Component {
    state = {
        isAdhoc: false,
        isClassMeeetings: false
    }

    onToggleAdhoc = () => {
        this.setState({isAdhoc: !this.state.isAdhoc})
    }

    onToggleClassMeetings = () => {
        this.setState({isClassMeeetings: !this.state.isClassMeeetings})
    }

    render() { 
        return <React.Fragment>
            {!this.state.isAdhoc && !this.state.isClassMeeetings && <Menu onToggleAdhoc={this.onToggleAdhoc} onToggleClassMeetings={this.onToggleClassMeetings}/>}
            {this.state.isAdhoc && <Adhoc onToggle={this.onToggleAdhoc} onTabChange = {this.props.onTabChange}/>}
            {this.state.isClassMeeetings && <ClassMeetings onToggle={this.onToggleClassMeetings} onTabChange = {this.props.onTabChange}/>}
        </React.Fragment>
    }
}

const mapStateToProps = (state) => {
    return {
        course: state.CCTReducer.course
    }
}
 
export default connect(mapStateToProps)(ScheduleMeeting);