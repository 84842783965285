import React, { Component } from 'react';
import { Col, Row, Container } from 'reactstrap';
import Applications from './Applications';
import Featured from './Featured';
import Head from './Head';
import SwapBanner from "../common/SwapBanner";

class Landing extends Component {
    state = {  }

    render() { 
        return <React.Fragment>
            <SwapBanner className="landing"/>
            <Container fluid className="myOdu__appContentWrapper landing">
                <Row><Col><Head /></Col></Row>
                {/* <Row><Col><Featured /></Col></Row> */}
                {/* <hr/> */}
                <Row className='mt-5'><Col><Applications /></Col></Row>
            </Container>
        </React.Fragment>
    }
}
 
export default Landing;