const myProgramLinks = [
    {
        id: '01',
        link: '#',
        title: 'Checklists'
    },
    {
        id: '02',
        link: 'https://transfer2.odu.edu/#/',
        title: 'MonarchTRANSFERmation',
        target: "_blank",
        rel: "noreferrer",
        tooltipTitle: 'Opens in new window'
    },
    {
        id: '03',
        link: 'https://leoonline.odu.edu/plsqlweb/bwskotrn.P_ViewTermTran',
        title: 'Academic Transcripts',
        target: "_blank",
        rel: "noreferrer",
        tooltipTitle: 'Opens in new window'
    },
];

export default myProgramLinks;