import {LinearProgress, Paper} from '@mui/material';
import React, {Component} from 'react';
import PortalDataGrid from '../../common/PortalDataGrid';

class TutoringTable extends Component {
    state = {
        page: 0,
        rowsPerPage: 10,
        innerWidth: window.innerWidth
    };

    render() {
        return (
            <React.Fragment>
                <Paper
                    sx={{
                        overflow: 'hidden'
                    }}
                    elevation={0}
                >
                    <PortalDataGrid
                        rows={this.props.rows}
                        columns={this.props.columns}
                        handleClick={this.props.onClick}
                        isLoading={this.props.isLoading}
                        columnVisibilityModel={{
                            ...this.props.columnVisibilityModel,
                            createdAt: false
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: {page: 0, pageSize: 10}
                            },
                            sorting: {
                                sortModel: [{field: 'createdAt', sort: 'desc'}]
                            }
                        }}
                        getRowId={row => {
                            return row.id
                                ? row.id
                                : row.SUBJECT.concat('_', row.COURSE);
                        }}
                        slots={{
                            ...this.props.slots,
                            loadingOverlay: LinearProgress
                        }}
                        slotProps={this.props.slotProps}
                        disableColumnFilter={this.props.disableColumnFilter}
                        disableColumnSelector={this.props.disableColumnSelector}
                        disableDensitySelector={
                            this.props.disableDensitySelector
                        }
                    />
                </Paper>
            </React.Fragment>
        );
    }
}

export default TutoringTable;
