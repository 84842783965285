import {Box, Button, Paper} from '@mui/material';
import React, {Component} from 'react';
import {Col, Row} from 'reactstrap';
import TutoringTable from './TutoringTable';
import TutoringDetails from './TutoringDetails';
import AddTutoringDialog from './AddTutoringDialog';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/pro-solid-svg-icons';
import DeleteDialog from './DeleteDialog';
import PortalSnackbar from '../../common/PortalSnackbar';
import {deleteData, getData, postData, putData} from '../../../DataAccessLayer';
import {
    courses,
    locations as locationService,
    tutoring
} from '../../../DataAccessLayer/services';
import getColumns from './columns';

class Tutoring extends Component {
    state = {
        columns: [],
        courses: [],
        tutorings: [],

        isAddTutoring: false,
        isDetails: false,
        isDeleteTutoring: false,
        isLoading: false,

        selectedTutoring: {
            SUBJECT: '',
            COURSE_NUMBER: '',
            COURSE_TITLE: '',
            locations: []
        },
        snackbar: {
            isOpen: false,
            message: '',
            success: ''
        },

        locations: []
    };

    componentDidMount() {
        this.loadCourses();
        this.loadTableHeaders();
        this.loadTutorings();
        this.loadLocations();
    }

    //O(1) Fetches all Courses
    loadCourses = async () => {
        getData(courses)
            .then(data => {
                this.setState({
                    courses: data
                });
            })
            .catch(err => console.log(err));
    };

    toggleIsLoading(isLoading) {
        if (isLoading === undefined) isLoading = !this.state.isLoading;
        this.setState({
            isLoading: isLoading
        });
    }

    //O(1) Fetches all Tutorings
    loadTutorings() {
        this.toggleIsLoading(true);
        getData(tutoring).then(data => {
            this.setState(
                {
                    tutorings: data
                },
                this.toggleIsLoading(false)
            );
        });
    }

    //O(N) where N is the number of column
    //Fetches all Columns from columns.js
    loadTableHeaders() {
        this.setState({
            columns: getColumns(this.setTutoringToDelete)
        });
    }

    loadLocations() {
        getData(locationService.locations).then(data => {
            this.setState(
                {
                    locations: data.map(item => ({
                        id: item.CATEGORY_ID + '_' + item.PLACE_ID,
                        text: item.TITLE,
                        url: item.URL,
                        mapUrl: item.MAP_URL
                    }))
                },
                this.toggleIsLoading(false)
            );
        });
    }

    toggleIsAddTutoring = () => {
        this.setState({
            isAddTutoring: !this.state.isAddTutoring
        });
    };

    toggleIsDetails = () => {
        this.setState({
            isDetails: !this.state.isDetails
        });
    };

    //O(1) Used to set Selected tutoring on row click
    onTutoringClick = event => {
        this.setState({
            selectedTutoring: event.row,
            isDetails: true
        });
    };

    //O(1) Updates the Locations selected in Dropdown
    onLocationsUpdate = event => {
        this.setState({
            selectedTutoring: {
                ...this.state.selectedTutoring,
                locations: event.target.value
            }
        });
    };

    //O(1) Updates the Tutoring
    updateTutoring = event => {
        this.toggleIsLoading(true);
        postData(tutoring, this.state.selectedTutoring, true)
            .then(result => {
                this.loadTutorings();
                this.setState(
                    {
                        snackbar: {
                            isOpen: true,
                            message: 'Course Tutoring Updated'
                        }
                    },
                    this.toggleIsDetails
                );
                this.toggleIsLoading(false);
            })
            .catch(err => console.log(err));
    };

    //O(1) Adds the Tutoring
    addTutoring = tutoringData => {
        this.toggleIsLoading(true);
        putData(tutoring, tutoringData)
            .then(result => {
                this.loadTutorings();
                this.setState(
                    {
                        snackbar: {
                            isOpen: true,
                            message: 'Course Tutoring Added'
                        }
                    },
                    this.toggleIsAddTutoring
                );
                this.toggleIsLoading(false);
            })
            .catch(err => console.log(err));
    };

    //O(1) Sets the tutoring to be deleted
    setTutoringToDelete = tutoring => {
        this.setState(
            {
                tutoringToDelete: tutoring
            },
            this.toggleIsDeleteTutoring
        );
    };

    toggleIsDeleteTutoring = () => {
        this.setState({
            isDeleteTutoring: !this.state.isDeleteTutoring
        });
    };

    //O(1) Deleted the Tutoring
    deleteTutoring = () => {
        this.toggleIsLoading(true);
        deleteData(tutoring + '/' + this.state.tutoringToDelete.COURSE)
            .then(result => {
                this.loadTutorings();
                this.setState(
                    {
                        tutoringToDelete: {},
                        snackbar: {
                            isOpen: true,
                            message: 'Course Tutoring Deleted'
                        }
                    },
                    this.toggleIsDeleteTutoring
                );
                this.toggleIsLoading(false);
            })
            .catch(err => console.log(err));
    };

    //O(1) Updates the Course Selected in Dropdown
    onCourseUpdate = selectedTutoring => {
        if (selectedTutoring) {
            this.setState({
                selectedTutoring: {
                    ...this.state.selectedTutoring,
                    SUBJECT: selectedTutoring.SUBJECT,
                    COURSE_NUMBER: selectedTutoring.COURSE_NUMBER,
                    COURSE_TITLE: selectedTutoring.COURSE_TITLE
                }
            });
        } else {
            this.setState({
                selectedTutoring: {
                    ...this.state.selectedTutoring,
                    SUBJECT: '',
                    COURSE_NUMBER: '',
                    COURSE_TITLE: ''
                }
            });
        }
    };

    onCloseSnackbar = (event, reason) => {
        this.setState({
            snackbar: {
                isOpen: false,
                message: ''
            }
        });
    };

    //O(1) Compare the courses
    compareCourses(course1, course2) {
        return (
            course1.SUBJECT === course2.SUBJECT &&
            course1.COURSE_NUMBER === course2.COURSE_NUMBER &&
            course1.COURSE_TITLE === course2.COURSE_TITLE
        );
    }

    //O(N) where N is the number of Tutorings
    //Checks if the course is already assigned to a tutoring
    disableOption = course => {
        let tutoringCourse = this.state.tutorings.find(tutoring =>
            this.compareCourses(tutoring, course)
        );
        if (!tutoringCourse) return false;

        return true;
    };

    render() {
        return (
            <React.Fragment>
            <div className="myOdu__container_maxWidth" style={{marginTop: '2rem'}}>
                <h3>Tutoring</h3>
                {this.state.isAddTutoring && (
                    <AddTutoringDialog
                        locations={this.state.locations}
                        courses={this.state.courses}
                        addTutoring={this.addTutoring}
                        toggleIsAddTutoring={this.toggleIsAddTutoring}
                        disableOption={this.disableOption}
                        isLoading={this.state.isLoading}
                        compareCourses={this.compareCourses}
                    />
                )}

                {this.state.isDeleteTutoring && (
                    <DeleteDialog
                        title="Delete Tutoring Item"
                        toggleIsDeleteTutoring={this.toggleIsDeleteTutoring}
                        onSubmit={this.deleteTutoring}
                        tutoringToDelete={this.state.tutoringToDelete}
                        isLoading={this.state.isLoading}
                    />
                )}
                <PortalSnackbar
                    isOpen={this.state.snackbar.isOpen}
                    onClose={this.onCloseSnackbar}
                    message={this.state.snackbar.message}
                    success={this.state.snackbar.success}
                />
                <Button
                    variant="outlined"
                    sx={{mt: 1}}
                    onClick={this.toggleIsAddTutoring}
                >
                    Add Tutoring
                </Button>
                <Box display={{xs: 'none', xl: 'block'}}>
                    <Row>
                        <Col>
                            <TutoringTable
                                rows={this.state.tutorings}
                                columns={this.state.columns}
                                onClick={this.onTutoringClick}
                                isLoading={this.state.isLoading}
                            />
                        </Col>
                        <Col>
                            <Paper
                                sx={{
                                    width: '100%',
                                    overflow: 'hidden',
                                    height: '100%',
                                    paddingX: 4,
                                    paddingY: 4
                                }}
                            >
                                <TutoringDetails
                                    selectedTutoring={
                                        this.state.selectedTutoring
                                    }
                                    locations={this.state.locations}
                                    courses={this.state.courses}
                                    isDetails={this.state.isDetails}
                                    isLoading={this.state.isLoading}
                                    onCourseUpdate={this.onCourseUpdate}
                                    onLocationsUpdate={this.onLocationsUpdate}
                                    onSubmit={this.updateTutoring}
                                    disableOption={this.disableOption}
                                    buttonText="Update Course"
                                    compareCourses={this.compareCourses}
                                />
                            </Paper>
                        </Col>
                    </Row>
                </Box>

                <Box display={{s: 'block', xl: 'none'}}>
                    <Row>
                        {!this.state.isDetails && (
                            <Col>
                                <TutoringTable
                                    rows={this.state.tutorings}
                                    columns={this.state.columns}
                                    onClick={this.onTutoringClick}
                                    isLoading={this.state.isLoading}
                                />
                            </Col>
                        )}
                        {this.state.isDetails && (
                            <Col>
                                {this.state.isDetails && (
                                    <Button
                                        variant="outlined"
                                        onClick={this.toggleIsDetails}
                                        sx={{marginTop: 1, marginBottom: 1}}
                                    >
                                        <FontAwesomeIcon icon={faArrowLeft} />
                                    </Button>
                                )}
                                <Paper
                                    sx={{
                                        width: '100%',
                                        overflow: 'hidden',
                                        height: '100%',
                                        paddingX: 4,
                                        paddingY: 4
                                    }}
                                >
                                    <TutoringDetails
                                        selectedTutoring={
                                            this.state.selectedTutoring
                                        }
                                        locations={this.state.locations}
                                        courses={this.state.courses}
                                        isDetails={this.state.isDetails}
                                        isLoading={this.state.isLoading}
                                        onCourseUpdate={this.onCourseUpdate}
                                        onLocationsUpdate={
                                            this.onLocationsUpdate
                                        }
                                        onSubmit={this.updateTutoring}
                                        disableOption={this.disableOption}
                                        buttonText="Update Course"
                                        compareCourses={this.compareCourses}
                                    />
                                </Paper>
                            </Col>
                        )}
                    </Row>
                </Box>
            </div>
            </React.Fragment>
        );
    }
}

export default Tutoring;
