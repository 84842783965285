const Apps = [
      {
        "id": "applyForAdmission",
        "name": "Apply for Admission",
        "link": "https://blue.odu.edu/admission/apply/",
        "iOsLink": "",
        "image": "apply.svg"
      },
      {
        "id": "banner",
        "name": "Banner",
        "link": "https://bannerxe.odu.edu:7005/applicationNavigator",
        "image": "banner.svg"
      },
      {
        "id": "brickell",
        "name": "Brickell Library",
        "link": "https://www.evms.edu/library/",
        "image": "evms.svg"
      },
      {
        "id": "canvas",
        "name": "Canvas",
        "link": "https://canvas.odu.edu/",
        "image": "canvas.svg"
      },
      {
        "id": "chromeRiver",
        "name": "Chrome River",
        "link": "https://app.chromeriver.com/index",
        "image": "chromeRiver.svg"
      },
      {
        "id": "courseSearch",
        "name": "Course Search",
        "link": "https://catalog.odu.edu/course-search/",
        "image": "courseSearch.svg"
      },
      {
        "id": "degreeWorks",
        "name": "Degree Works",
        "link": "https://degree.odu.edu/",
        "image": "degreeWorks.svg"
      },
      {
        "id": "deviceRegistration",
        "name": "Device Registration",
        "link": "https://device.odu.edu/",
        "image": "deviceRegistration.svg"
      },
      {
        "id": "duo",
        "name": "Duo",
        "link": "https://www.odu.edu/information-technology-services/two-factor-authentication",
        "image": "duo.svg"
      },
      {
        "id": "eva",
        "name": "eVA",
        "link": "https://eva.virginia.gov/",
        "image": "eva.svg"
      },
      {
        "id": "archibus",
        "name": "Archibus",
        "link": "https://odu.nmrkgcs.com/archibus/workplace",
        "image": "archibus.svg"
      },
      {
        "id": "gmail",
        "name": "Gmail",
        "link": "https://mail.google.com/a/odu.edu",
        "image": "gmail.svg"
      },
      {
        "id": "googleDrive",
        "name": "Google Drive",
        "link": "http://drive.google.com/",
        "image": "googleDrive.svg"
      },
      {
        "id": "itHelpDesk",
        "name": "IT Help Desk",
        "link": "https://www.odu.edu/ts/helpdesk",
        "image": "itsHelpDesk.svg"
      },
      {
        "id": "kalturaMediaspace",
        "name": "Kaltura MediaSpace",
        "link": "https://odumedia.mediaspace.kaltura.com/",
        "image": "kaltura.svg"
      },
      {
        "id": "laundry",
        "name": "Laundry",
        "link": "https://cardcenter.odu.edu/student/laundry/welcome.php",
        "image": "laundry.svg"
      },
      {
        "id": "leoOnline",
        "name": "LEO Online",
        "link": "https://leoonline.odu.edu",
        "image": "leoOnline.svg"
      },
      {
        "id": "lime",
        "name": "Lime",
        "link": "https://www.odu.edu/transportation-parking-services/alternative-transportation-programs/scooters",
        "image": "lime.svg"
      },
      {
        "id": "liveSafe",
        "name": "Live Safe",
        "link": "http://www.odu.edu/life/health-safety/safety/livesafe",
        "image": "liveSafe.svg"
      },
      {
        "id": "midas",
        "name": "MIDAS",
        "link": "http://midas.odu.edu/",
        "image": "midas.svg"
      },
      {
        "id": "monarchDining",
        "name": "Monarch Dining",
        "link": "https://olddominion.campusdish.com/",
        "image": "monarchDining.svg"
      },
      {
        "id": "monarchGroups",
        "name": "Monarch Groups",
        "link": "https://odu.campusgroups.com",
        "image": "monarchGroups.svg"
      },
      {
        "id": "monarchMovies",
        "name": "Monarch Movies",
        "link": "https://monarchmovies.odu.edu/#/browse",
        "image": "monarchMovies.svg"
      },
      {
        "id": "monarchPrint",
        "name": "Monarch Print",
        "link": "https://monarchprint.odu.edu",
        "image": "monarchPrint.svg"
      },
      {
        "id": "monarchTransfermation",
        "name": "Monarch Transfermation",
        "link": "https://www.odu.edu/transfer/transfermation",
        "image": "transfermation.svg"
      },
      {
        "id": "monarchWellnessPortal",
        "name": "Monarch Wellness Portal",
        "link": "https://odu.medicatconnect.com",
        "image": "monarchWellnessPortal.svg"
      },
      {
        "id": "move",
        "name": "MOVE",
        "link": "http://move.odu.edu/",
        "image": "move.svg"
      },
      {
        "id": "oduHandshake",
        "name": "ODU Handshake",
        "link": "https://odu.joinhandshake.com/",
        "image": "handshake.svg"
      },
      {
        "id": "oduJobs",
        "name": "ODU Jobs",
        "link": "https://jobs.odu.edu/",
        "image": "oduJobs.svg"
      },
      {
        "id": "odumobile",
        "name": "ODUMobile",
        "link": "https://ww1.odu.edu/ts/software-services/odumobile",
        "image": "oduMobileIcon.svg"
      },
      {
        "id": "office365",
        "name": "Office 365",
        "link": "http://office365.odu.edu/",
        "image": "office.svg"
      },
      {
        "id": "outlook",
        "name": "Outlook",
        "link": "http://outlook.odu.edu/",
        "image": "outlook.svg"
      },
      {
        "id": "papers",
        "name": "Papers",
        "link": "https://jobs.odu.edu/hr",
        "image": "papers.svg"
      },
      {
        "id": "parkingPortal",
        "name": "Parking Portal",
        "link": "https://odu.t2hosted.com/Account/Portal",
        "image": "parkingPortal.svg"
      },
      {
        "id": "qualtrics",
        "name": "Qualtrics",
        "link": "https://odu.qualtrics.com/",
        "image": "qualtrics.svg"
      },
      {
        "id": "quinncia",
        "name": "Quinncia",
        "link": "https://quinncia.io/access",
        "image": "quinncia.svg"
      },
      {
        "id": "recwellApp",
        "name": "RecWell App",
        "link": "https://www.odu.edu/recwell/about/recwell-app",
        "image": "recWell.svg"
      },
      {
        "id": "registration",
        "name": "Registration",
        "link": "https://xessb.odu.edu/StudentRegistrationSsb/ssb/registration",
        "image": "registration.svg"
      },
      {
        "id": "retain",
        "name": "Retain.io Success Services",
        "link": "https://odu.retain.io/",
        "image": "retain.svg"
      },
      {
        "id": "safeRide",
        "name": "Safe Ride",
        "link": "https://www.odu.edu/life/health-safety/safety/saferide",
        "image": "safeRide.svg"
      },
      {
        "id": "seesEventsDashboard",
        "name": "SEES Events Dashboard",
        "link": "https://analytics.odu.edu/analytics/bi/?pathRef=.public_folders%2FMy+Dashboards+and+Reports%2FStudent+Engagement+and+Enrollment+Services+%28SEES%29%2FSwipe+Dashboards+and+Reports%2FSEES+Swipe+Events+Dashboard&format=HTML&Download=false&prompt=true",
        "image": "seesEventsDashboard.svg"
      },
      {
        "id": "serviceNow",
        "name": "Service Now",
        "link": "https://oduprod.service-now.com",
        "image": "serviceNow.svg"
      },
      {
        "id": "studentOnlineBillPayment",
        "name": "Student Online Bill Payment",
        "link": "https://www.odu.edu/quickfix/pay-tuition-students",
        "image": "billPay.svg"
      },
      {
        "id": "studentOpinionSurveys",
        "name": "Student Opinion Surveys",
        "link": "https://www.odu.edu/academics/courses-registration/studentopinionsurvey",
        "image": "studentOpinionSurvey.svg"
      },
      {
        "id": "teams",
        "name": "Teams",
        "link": "http://teams.microsoft.com/",
        "image": "teams.svg"
      },
      {
        "id": "translocBusRoutes",
        "name": "Transloc",
        "link": "https://transloc.com/app/",
        "image": "transloc.svg"
      },
      {
        "id": "tuitionCalculator",
        "name": "Tuition Calculator",
        "link": "https://itsapps.odu.edu/calculator/",
        "image": "tuitionCalculator.svg"
      },
      {
        "id": "universityLibraries",
        "name": "University Libraries",
        "link": "https://www.odu.edu/library",
        "image": "universityLibraries.svg"
      },
      {
        "id": "vpn",
        "name": "VPN (GlobalProtect)",
        "link": "https://www.odu.edu/information-technology-services/vpn/vpnclient",
        "image": "vpn.svg"
      },
      {
        "id": "webTimeEntry",
        "name": "Web Time Entry",
        "link": "https://xessb.odu.edu:7005/EmployeeSelfService/ssb/hrDashboard#/hrDashboard",
        "image": "webTimekeeping.svg"
      },
      {
        "id": "wordpress",
        "name": "Wordpress",
        "link": "https://wp.odu.edu/wp-login.php?action=shibboleth",
        "image": "wordpress.svg"
      },
      {
        "id": "zendtoLargeFileTransfer",
        "name": "ZendTo Large File Transfer",
        "link": "https://lfs.odu.edu/",
        "image": "largeFileTransfer.svg"
      },
      {
        "id": "zoom",
        "name": "Zoom",
        "link": "https://odu.zoom.us/",
        "image": "zoom.svg"
      }
    ];


export default Apps