import { faX } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Snackbar } from "@mui/material";
import React, { Component } from "react";

class PortalSnackbar extends Component {
    state ={

    }

    render() {
        return <React.Fragment>
            <Snackbar 
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                open = {this.props.isOpen}
                onClose={this.props.onClose}
                message = {this.props.message}
                action = <React.Fragment>
                            <IconButton
                                size='small'
                                onClick={this.props.onClose}
                            >
                                <FontAwesomeIcon icon={faX} color='white'/>
                            </IconButton>
                        </React.Fragment>
                className={this.props.success ? 'myOdu__snackbar_success' : 'myOdu__snackbar_error'}
            />
        </React.Fragment>
    }
}

export default PortalSnackbar;