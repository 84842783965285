import React, { Component } from 'react';
import { Grid, IconButton, Link, MobileStepper, Typography } from '@mui/material';
import { connect } from 'react-redux';
import Widget from "../common/Widget";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import { savePreferences, saveSetup } from '../SetUp/actions';
import AppsCard from './AppsCard';
import { WithSmallScreen } from '../common/WithSmallScreen';

class MyApps extends Component {

    componentDidMount() {
        this.observer = new ResizeObserver((_) => {
            this.setState({
                appPanelRef: this.state.appPanelRef
            })
        })
        if(this.state.appPanelRef.current)
            this.observer.observe(this.state.appPanelRef?.current);
        this.setState({
            appPanelRef: this.state.appPanelRef
        })
    }

    componentWillUnmount() {
        this.observer.disconnect();
    }

    //Θ(1) Makes a copy of current prefereneces in setup and resets
    onReset = () => {
        if(Object.keys(this.props.preferences?.Apps?.Apps).length) {
            let setup = {reset: this.props.preferences}
            this.props.savePreferences({})
            this.props.saveSetup(setup)
        } else {
            this.props.savePreferences({});
        }
    }
    
    state = {
        isExpand: true,
        widgetId: 'widget__myApps',
        isTitleStylized: true,
        title: ["my", "Apps"],
        menu: [
        //    {id: 'myApps_editFavorites', title: 'Edit Favorites'}, 
           {id: 'myApps_resetSetup', title: 'Reset Setup', onClick: this.onReset}, 
        ],
        active: 1,
        itemsPerPanel: 12,
        itemsPerPanelSmall: 4,
        appPanelRef: React.createRef()
    }

    toggleExpand = () => {
        this.setState({isExpand: !this.state.isExpand})
    }

    //Θ(1) Moves to next panel
    onNext = () => {
        let lastPanel = Math.ceil(Object.keys(this.props.preferences.Apps?.Apps).length / (this.props.isSmall ? this.state.itemsPerPanelSmall : this.state.itemsPerPanel))
        if(this.state.active < lastPanel)
            this.setState({active: this.state.active + 1})
        else
            this.setState({active: 1})
    }

    //Θ(1) Moves to previous panel
    onPrev = () => {
        let lastPanel = Math.ceil(Object.keys(this.props.preferences.Apps?.Apps).length / (this.props.isSmall ? this.state.itemsPerPanelSmall : this.state.itemsPerPanel))
        if(this.state.active > 1)
            this.setState({active: this.state.active - 1})
        else
            this.setState({active: lastPanel})
    }

    render() {
        const itemsPerPanel = this.props.isSmall ? this.state.itemsPerPanelSmall : this.state.itemsPerPanel
        const currentApps = Object.keys(this.props.preferences?.Apps?.Apps).sort()?.slice(itemsPerPanel * this.state.active - itemsPerPanel, itemsPerPanel * this.state.active);
        // const currentApps = Object.keys(this.props.preferences?.Apps?.Apps).sort();
        const noPanels = Math.ceil(Object.keys(this.props.preferences.Apps?.Apps).length / itemsPerPanel);

        return <React.Fragment>
            <Widget 
                data={{
                    title: this.state.title, 
                    isTitleStylized: this.state.isTitleStylized, 
                    menu: this.state.menu, 
                    id: this.state.widgetId
                }} 
                {...this.props}
            >
                <Grid container direction='row' justifyContent='center'>
                    <Grid item container className="align-items-center" direction='row' wrap='nowrap' spacing={1}>
                        <Grid item container xs={'auto'}>
                            <Grid item xs='auto'>
                                <Tooltip title={noPanels <= 1 ? 'Navigation disabled because there are no apps' : 'Previous Panel'} enterDelay={850} leaveDelay={0} enterNextDelay={850} arrow>
                                    <div>
                                    <IconButton sx={{borderRadius: '0'}} aria-label='Previous App Panel' id="myApps__button_prev" onClick={this.onPrev} disabled={noPanels <= 1}>
                                        <FontAwesomeIcon icon={faChevronLeft} fixedWidth/>
                                    </IconButton>
                                    </div>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={'auto'}>
                        {   noPanels !== 1 &&
                                    <Grid item sx={{display: 'flex', overflow: 'hidden', width: '2rem', flexDirection: 'row-reverse', paddingLeft: 1, paddingRight: 1}}>
                                        <AppsCard apps={currentApps} isSideBox className="appsPanelLeft myOdu__appsPanelLeft" refDiv = {this.state.appPanelRef}/>
                                    </Grid>
                            }
                        </Grid> */}
                        <Grid sx={{width: '1.5rem'}}></Grid>

                        <Grid item xs = {12} className="px-0 text-center" style={{}}>
                        {   currentApps.length != 0 ?
                            <AppsCard apps={currentApps} className="appsPanelCenter" refDiv={this.state.appPanelRef} itsSmall = {this.itsSmall}/>
                            : <div ref={this.state.appPanelRef}><Typography component='p' variant='small'>No apps found. <Link style={{cursor: 'pointer'}} onClick={this.onReset}>Set up apps.</Link></Typography></div>
                        }
                        </Grid>

                        <Grid sx={{width: '1.5rem'}}></Grid>
                        {/* <Grid item xs={'auto'} style={{paddingLeft: 0}}>    
                            {   noPanels !== 1 &&
                                    <Box sx={{display: 'flex', overflowX: 'hidden', width: '2rem', flexDirection: 'row', paddingLeft: 1, paddingRight: 1}}>
                                        <AppsCard apps={currentApps} isSideBox className="appsPanelRight" refDiv = {this.state.appPanelRef}/>
                                    </Box>
                            }
                        </Grid> */}
                        <Grid item container xs={'auto'}>
                            <Tooltip title={noPanels <= 1 ? 'Navigation disabled because there are no apps' : 'Next Panel'} enterDelay={850} leaveDelay={0} enterNextDelay={850} arrow>
                                <div>
                                    <IconButton sx={{borderRadius: '0'}} aria-label='Next App Panel' id="myApps__button_next" onClick={this.onNext} disabled={noPanels <= 1}>
                                        <FontAwesomeIcon icon={faChevronRight} fixedWidth/>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Grid>

                    </Grid>
                    <Grid item>
                        <MobileStepper
                            variant='dots'
                            steps={noPanels}
                            position="static"
                            activeStep={this.state.active-1}
                        />
                    </Grid>
                </Grid>
            </Widget>   
        </React.Fragment>
    }
}
 
const mapStateToProps = (state) => {
    return {
        preferences: state.preferencesReducer.preferences,
        user: state.AWSReducer.user,
        isImpersonating: state.impersonationReducer.impersonation?.isImpersonating ?? false,
        impersonation: state.impersonationReducer.impersonation
    }
  }
  
const mapDispatchToProps = (dispatch) => ({
    saveSetup: (setup) => dispatch(saveSetup(setup)),
    savePreferences: (preferences) => dispatch(savePreferences(preferences))
});
  
export default connect(mapStateToProps, mapDispatchToProps)(WithSmallScreen(MyApps));