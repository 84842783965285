import React, { Component } from 'react';
import Widget from '../common/Widget';
import { Box, Divider, Link, Stack, Typography, Tooltip } from '@mui/material';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { getData } from '../../DataAccessLayer';
import { myTransferHistory } from '../../DataAccessLayer/services';
import GPADetails from './GPADetails';
import CreditDetails from './CreditDetails';
import myProgramLinks from './config'

class MyPrograms extends Component {
    state = {
        widgetId: "widget__myPrograms",
        isTitleStylized: true,
        title: ["my", "Programs"],
        totalTransferredCredits: 0,
        isDisplayGPA: false,
        currentSem: {},
        nextSem: {}
    }

    componentDidMount() {
        getData(myTransferHistory)
        .then(result => {
            this.loadTransferCredits(result[0]?.PREVIOUS_INSTITUTES);
        })
    }

    componentDidUpdate(prevProps) {
        if(prevProps.coursesTaking !== this.props.coursesTaking) {
            this.getSemesters();
        }
    }

    //O(N) where N is length of string previousInstitues
    //Retrieves transfer credits from previousInstitues and sums it up
    loadTransferCredits(previousInstitues) {
        if(!previousInstitues) {
            return;
        }
        let totalTransferredCredits = previousInstitues.split('|').reduce(
            (total, institute) => total + Number(institute.substring(institute.lastIndexOf('(') + 1, institute.lastIndexOf(')')).trim()),
            0
        );
        this.setState({
            totalTransferredCredits
        })
    }

    toggleIsDisplayGPA = () => {
        this.setState({
            isDisplayGPA: !this.state.isDisplayGPA
        })
    }

    //O(N) where N is length of coursesTaking
    //Retrieves current and next semesters from coursesTaking
    getSemesters = () => {
        let { coursesTaking } = this.props;
        
        if(coursesTaking.length) {
            let currentSem = coursesTaking?.find(el => el.ACADEMIC_PERIOD_5 === this.props.terms.CURRENT_TERM) || coursesTaking[0]
            let nextSem = coursesTaking?.find(el => el.ACADEMIC_PERIOD_5 === this.props.terms.NEXT_TERM) || {}
            this.setState({
                currentSem, nextSem
            })
        }
    }

    render() {
        //TODO: Add Updated time
        return <React.Fragment>
            <Widget data={{id: this.state.widgetId, isTitleStylized: this.state.isTitleStylized, title: this.state.title, menu: this.state.menu}} {...this.props}>
                <Box sx={{padding: 1, mt: 0}}>
                    <Row style={{marginBottom: '1rem'}}>
                        <Col xs={10}>
                            <Row>
                                <Typography component="h3" variant='h5'>Academic Standing</Typography>
                            </Row>
                            <Row>
                                <Box style={{float: 'left'}}>
                                    <Typography component="p" variant='small'>{this.state.currentSem?.ACADEMIC_STANDING_DESC || 'No academic standing found'}</Typography>
                                </Box>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            
                        </Col>
                    </Row>
                    <Row>
                            { this.state.currentSem?.STUDENT_LEVEL_DESC &&
                                <Typography component="h3" variant="h5">{this.state.currentSem?.STUDENT_LEVEL_DESC} - {this.state.currentSem?.MAJOR_DESC}</Typography>
                                || <Typography component="h3" variant="h5">No major found</Typography>
                            }
                    </Row>
                </Box>
                <Box sx={{border: '1px solid #DEDEDE', padding: 1}}>
                    <Row>
                        <Col xs={12}>
                            <Row>
                                <GPADetails 
                                    title = 'Current GPA'
                                    data = {this.state.currentSem?.CUML_GPA}
                                    isDisplayGPA = {this.state.isDisplayGPA}
                                    toggleIsDisplayGPA = {this.toggleIsDisplayGPA}
                                />
                                <Row xs={12} style={{marginBottom: '1rem'}}>
                                    <Col xs={4}>
                                        <Typography component="h4" variant='h6'>Credits</Typography>
                                    </Col>
                                    <Col xs={8}>
                                        <CreditDetails 
                                            data = {this.state.currentSem?.TOTAL_CREDITS_EARNED || 0}
                                            text = 'Total Credit Hours Completed'
                                        />
                                        <CreditDetails 
                                            data = {this.state.totalTransferredCredits || 0}
                                            text = 'Total Credit Hours Transferred'
                                        />
                                        <CreditDetails 
                                            data = {this.state.currentSem?.ACADEMIC_PERIOD_5 === this.props.terms.CURRENT_TERM ? this.state.currentSem.CURR_TERM_HOURS_REG : 0}
                                            text = 'Credit Hours in Progress'
                                        />
                                        <CreditDetails 
                                            data = {this.state.nextSem?.CURR_TERM_HOURS_REG || 0}
                                            text = {this.state.nextSem?.ACADEMIC_PERIOD_DESC_5 && 'Credit Hours Registered for ' + this.state.nextSem.ACADEMIC_PERIOD_DESC_5 || 'Credit Hours Registered'}
                                        />
                                    </Col>
                                </Row>
                            </Row>
                        </Col>
                    </Row>
                </Box>
                <Stack 
                    direction={{ md: "column", sm: "row", lg: "row", xl: "column", xxl: "column", xxxl: "row"}} 
                    sx={{mt: 2}} 
                    divider={<Divider orientation="vertical" flexItem sx={{borderColor: 'black'}} role="presentation" />} 
                    spacing={0} justifyContent="space-around">
                    {
                        myProgramLinks.map(item=> {
                            return <div className='text-center' key={"myProgramsWidget_link" + item.id}>
                                <Tooltip id={"link_" + item.id + "_tooltip"} title={item?.tooltipTitle} enterDelay={750} leaveDelay={0} enterNextDelay={750} arrow>
                                    <Link id={'myPrograms__link_' + item.id} rel={item.rel} target={item.target} href={item.link} underline={'hover'} style={{marginLeft: 0, marginBottom:1, textAlign: 'center'}} >{item.title}</Link>
                                </Tooltip>                     
                            </div>
                        })
                    }
                </Stack>
            </Widget>
        </React.Fragment>
    }
}

const mapStateToProps = (state) => {
    return {
        coursesTaking: state.myCoursesReducer.coursesTaking,
        terms: state.myCoursesReducer.terms
    }
}

export default connect(mapStateToProps)(MyPrograms)