import {SAVE_STATUS_ALERTS} from './actions';

const initialStateStatusAlerts = {
  statusAlerts: null,
  count: 0
};

// Persists status alerts across pages
export const myStatusAlertsReducer = (
  state = initialStateStatusAlerts,
  action
) => {
  const {type, payload} = action;
  switch (type) {
    case SAVE_STATUS_ALERTS: {
      const alerts = payload.statusAlerts;
      const alertCount =
        (alerts.balance?.length ?? 0) +
        (alerts.holds?.length ?? 0) +
        (alerts.upcomingHolds?.length ?? 0) +
        (alerts.financialAid?.length ?? 0);
      return {...state, statusAlerts: alerts, count: alertCount};
    }
    default:
      return state;
  }
};
