const moment = require('moment');

module.exports = {
    getDateTime: date => {
        return moment(date).format('MMMM D, YYYY, h:mm A');
    },

    // Redirects the user to old portal
    stopPreview: (href) => {
        // delete the preview cookie
      document.cookie='portal-preview=; Path=/; Domain=.odu.edu; Expires=Thu, 01 Jan 1970 23:59:59 GMT';
      // navigate to old portal
      window.location.href = href;
    }
};
