import React, {Component} from 'react';
import {Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faX} from '@fortawesome/pro-light-svg-icons';
import {toggleCCT} from './actions';
import {connect} from 'react-redux';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    Tab,
    Tabs
} from '@mui/material';
import Zoom from './Zoom';
import {TabPanel} from '@mui/base';
import Teams from './Teams';
import GoogleDrive from './GoogleDrive';
import SharedDrive from './SharedDrive';

class CCT extends Component {
    state = {
        activeTab: 1
    };

    //Θ(1) Sets active tab
    onTabChange = (e, activeTab) => {
        this.setState({activeTab});
    };

    render() {
        return (
            <React.Fragment>
                <Dialog
                    open={this.props.isCCT}
                    fullWidth
                    maxWidth="xl"
                    className="myOdu__wizard"
                    onClose={() => this.props.toggleCCT({})}
                >
                    <DialogTitle
                        sx={{
                            backgroundColor: '#003057',
                            color: 'white',
                            borderBottom: '3px solid #2188A2'
                        }}
                    >
                        <Row>
                            <Col>
                                <Typography variant="h4" component="h2">
                                    <Row>
                                        <Col md={2}>
                                            {this.props.course.SUBJECT_CODE +
                                                ' ' +
                                                this.props.course.COURSE_NUMBER}
                                        </Col>
                                        <Col md={10}>
                                            <Row>
                                                <Col>
                                                    {
                                                        this.props.course
                                                            .COURSE_TITLE
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    {'CRN: ' +
                                                        this.props.course.CRNS}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Typography>
                            </Col>
                            <Col
                                xs={1}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'right'
                                }}
                            >
                                <IconButton
                                    size="small"
                                    onClick={() => this.props.toggleCCT({})}
                                >
                                    <FontAwesomeIcon
                                        icon={faX}
                                        style={{color: '#ffffff'}}
                                    />
                                </IconButton>
                            </Col>
                        </Row>
                    </DialogTitle>
                    <DialogContent>
                        <Box
                            sx={{
                                width: '100%',
                                borderBottom: 1,
                                borderColor: 'divider'
                            }}
                        >
                            <Tabs
                                value={this.state.activeTab}
                                onChange={this.onTabChange}
                                aria-label="secondary tabs example"
                                variant="fullWidth"
                            >
                                <Tab value={1} label="Zoom" />
                                <Tab value={2} label="Goolgle Drive" />
                                <Tab value={3} label="Teams" />
                                <Tab value={4} label="Shared Drive" />
                            </Tabs>
                            <Box sx={{width: '100%'}}>
                                {this.state.activeTab == 1 && <Zoom />}
                                {this.state.activeTab == 2 && <GoogleDrive />}
                                {this.state.activeTab == 3 && <Teams />}
                                {this.state.activeTab == 4 && <SharedDrive />}
                            </Box>
                        </Box>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isCCT: state.CCTReducer.isCCT,
        course: state.CCTReducer.course
    };
};

const mapDispatchToProps = dispatch => ({
    toggleCCT: course => dispatch(toggleCCT(course))
});

export default connect(mapStateToProps, mapDispatchToProps)(CCT);
