import { Button, TextField } from '@mui/material';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';

class SharedDrive extends Component {
    state = {  } 
    render() { 
        return <React.Fragment>
            <Row><Col>Drive Name</Col></Row>
            <Row><Col><TextField id="outlined-basic" label="Outlined" variant="outlined" /></Col></Row>
            <Row><Col><Button variant='contained'>Create a shared drive</Button></Col></Row>
        </React.Fragment>
    }
}
 
export default SharedDrive;