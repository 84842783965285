import React, {Component} from 'react';
import {
  Box,
  Avatar,
  Link,
  Stack,
  Typography,
  Tooltip,
  Card,
  IconButton
} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPhone, faEnvelope, faTrash, faCheck} from '@fortawesome/pro-light-svg-icons';
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import PortalTooltip from './PortalTooltip';
import { isProfileUrlValid } from '../Layout/SideNav';


class ContactCard extends Component {

  render() {
    let {data} = this.props;
	if(!isProfileUrlValid(data.img) && data.firstName && data.lastName) {
		let fN = data?.firstName;
		fN = fN?.charAt(0).toUpperCase();

		let lN = data?.lastName;
		lN = lN?.charAt(0).toUpperCase();

		data.initials = fN+lN;
	}
	const firstName = data?.firstName?.toUpperCase() ?? '';
	const lastName = data?.lastName?.toUpperCase() ?? ''
	
    return (
		<Card
			sx={{p: 1, height: '100%'}}
			className={'myOdu__appCard'}
			elevation={0}
			justifyContent="center"
		>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<Stack direction="row">
					{data?.directoryLink ? (
						<div>
							<PortalTooltip id={data.id + '_imgLink'} title={`Open ODU Directory page for ${data.name}`}>
								<Link id={"contactCard__avatarLink_" + data.id} href={data.directoryLink} target="_blank" underline="none" id={data.id + '_imgLink'} >
									<Avatar sx={{height: '40px', mr: 1, mb: 1, bgcolor: '#003057'}} {...(isProfileUrlValid(data.img) ? {alt:data.name, src: data.img} : {})} className="myOdu__logoZoom" >
										{data.initials}
									</Avatar>
								</Link>
							</PortalTooltip>
						</div>
					) : (
							<Avatar sx={{height: '40px', mt: 1, mr: 1, mb: 1, bgcolor: '#003057'}} {...(isProfileUrlValid(data.img) ? {alt:data.name, src: data.img} : {})} className="myOdu__logoZoom" >
								{data.initials}
							</Avatar>
					)}

					<Box>
						<Stack>
							{data.directoryLink ? (
								<PortalTooltip id={data.id + '_textLink'} title={`Open ODU Directory page for ${data.name}`}>
									<Link href={data.directoryLink} target="_blank" underline="hover" id={"contactCard__nameLink_" + data.id} >
										<Typography component="h3" variant="p" sx={{fontWeight: 600}} >
											{lastName + ', ' + firstName}
										</Typography>
									</Link>
								</PortalTooltip>
							) : (
								<Typography id={"contactCard__name_" + data.id} component="h3" variant="p" sx={{fontWeight: 600}}>
									{lastName + ', ' + firstName}
								</Typography>
							)}

							<Typography variant="smallest" sx={{textTransform: 'uppercase'}}>
								{data.department}
							</Typography>
							{data.phoneNumber && (
								<PortalTooltip id={data.id + '_phoneLink'} title={`Call  ${lastName + ', ' + firstName}`} >
									<Link id={"contactCard__link_phone_" + data.id} href={'tel:' + data.phoneNumber} variant="smaller" underline={'hover'} >
										<FontAwesomeIcon icon={faPhone} style={{marginRight: '0.25rem'}}/>
										{data.phoneNumber}
									</Link>
								</PortalTooltip>
							)}
							{data.email && (
								<PortalTooltip id={data.id + '_emailLink'} title={`Send email to ${lastName + ', ' + firstName}`} >
									<Link id={"contactCard__link_email_" + data.id} href={'mailTo:' + data.email} variant="smaller" underline={'hover'} >
										<FontAwesomeIcon icon={faEnvelope} style={{marginRight: '0.25rem'}} />
										{data.email}
									</Link>
								</PortalTooltip>
							)}
						</Stack>
					</Box>
				</Stack>
				<Box>
					{this.props.isAdd && <PortalTooltip id={data.id + '_add'} title={'Add contact to ' + lastName + ', ' + firstName + ' in saved contacts'}>
						<IconButton id={"contactCard__button_add_" + data.id} aria-label="add" onClick={() => this.props.onAdd(data)} disabled={this.props.isDisabled} ><FontAwesomeIcon icon={faCirclePlus} /></IconButton>
					</PortalTooltip>}
					{this.props.isDelete && <PortalTooltip id={data.id + '_delete'} title={'Delete contact for ' + lastName + ', ' + firstName + ' from saved contacts'}>
						<IconButton id={"contactCard__button_delete_" + data.id}aria-label="delete" onClick={() => this.props.onDelete(data)} disabled={this.props.isDisabled} ><FontAwesomeIcon icon={faTrash} /></IconButton>
						</PortalTooltip>}
					{this.props.isCheck && <IconButton aria-label="check" disabled ><FontAwesomeIcon className='myOdu__good' icon={faCheck} /></IconButton>}
				</Box>
			</Stack>
      </Card>
    );
  }
}

export default ContactCard;
