import React, { Component } from 'react';
import Widget from '../common/Widget';
import { Link, List, ListItem, Typography, Box, Button, Stack, Tooltip, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import PortalTooltip from '../common/PortalTooltip';

class BalanceBox extends Component {
    render() {        
        return <>
            <Box sx={{}} textAlign={"center"} className={"balanceBox"}>
                <Stack sx={{p:1}} direction={"column"} alignItems="center" justifyContent={"center"}>
                    <Typography component="h3" variant="h6">{this.props.title}</Typography>
                    
                    <Stack direction={"row"} alignItems="center" justifyContent={"center"}>
                        
                        <Box sx={{height: '40px', width: '40px', mr:0}}>
                            <img src={this.props.icon} alt={this.props.alt} />
                        </Box>

                        <Stack direction={"column"} spacing={0} sx={{width:'100%'}}>
                            <div className="ms-2">
                                <Typography component="h4" variant="smaller">Balance</Typography>
                                <Stack direction="row" alignItems="center" justifyContent={"center"}>
                                    {
                                        this.props.balance == "NA" ? 
                                        <>
                                            <Typography component="p" variant="h5"><PortalTooltip title="Balance Not Available">N/A</PortalTooltip></Typography>
                                        </>
                                        :
                                        <>
                                            <Typography component="p" variant="h5">${this.props.isDisplayBalance ? this.props.balance : ' ---'}</Typography>
                                            <IconButton aria-label="hide-balance" onClick={this.props.toggleIsDisplayBalance}>
                                                <FontAwesomeIcon icon={this.props.isDisplayBalance && faEyeSlash || faEye} fixedWidth size="xs"/>
                                            </IconButton>
                                        </>
                                    }
                                </Stack>
                            </div>
                        </Stack>
                    </Stack>
                    <PortalTooltip>
                        <Button target="_blank" className="myOdu__button secondary myBalancesButton" href={this.props.depositLink} variant="outlined" sx={{mt:1}}>Make Deposit</Button>
                    </PortalTooltip>                   
                </Stack>  


            </Box>
        </>
    }
}
 
export default BalanceBox;