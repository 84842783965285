import React, {Component} from "react";
import { DataGrid } from "@mui/x-data-grid";
import MeetingActions from "./MeetingActions";
import moment from 'moment';
import { connect } from "react-redux";
import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { Col, Row } from "reactstrap";
import {toast} from 'react-toastify';
import { deleteData } from "../../../../../DataAccessLayer";
import { cct } from "../../../../../DataAccessLayer/services";
import { ZoomTabMapping } from "../config";
import { getColumns } from "./columns";

class Meetings extends Component {
    state = {
        columns: [],
        isLoading: false
    }

    toggleIsLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading
        })
    }

    //Θ(1) deletes all zoom meetings
    onDeleteAll = () => {
        this.toggleIsLoading();
        let crns = this.props.course.CRNS.split(',')
        deleteData(cct + '/' + crns[0]  + '/' + this.props.course.SSBSECT_TERM_CODE)
        .then(result => {
            if(!result)
                toast.error('Unable to delete meetings')
            else {
                toast.success('Meetings Deleted Successfully')
                this.props.loadMeetings();
            }
            this.toggleIsLoading();
        })
        .catch(err => {
            this.toggleIsLoading();
            console.log(err);
        })
    }

    componentDidMount() {
        if(this.props.activeTab === ZoomTabMapping.currentMeeting)
            this.props.loadMeetings();

        this.setState({
            columns: getColumns(this.props)
        });
    }

    //Θ(1) returns the row id for each meeting
    getRowId = (row) => {
        let id = row.id + (row?.queued !== undefined ? (row.start_time) : (row.occurrence_id !== undefined ? row.occurrence_id: '')); 
        return id;
    }

    render() {
        return <React.Fragment>
            <Row>
                {
                    this.props.activeTab === ZoomTabMapping.currentMeeting &&
                    <Row style={{float: 'right'}}>
                        <Col><Button disabled={this.state.isLoading} onClick={this.onDeleteAll}>Delete All Meetings</Button></Col>
                    </Row>
                }
                <Row>
                    <div style={{marginTop: 5}}>
                        <DataGrid 
                            columns={this.state.columns}
                            rows={this.props.meetings}
                            loading = {this.props.isLoading}
                            slots={{
                                loadingOverlay: LinearProgress,
                            }}
                            getRowId={this.getRowId}
                        />
                    </div>
                </Row>
            </Row>
        </React.Fragment>
    }
}

const mapStateToProps = (state) => {
    return {
        course: state.CCTReducer.course
    }
}

export default connect(mapStateToProps)(Meetings)