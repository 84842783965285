import React from 'react';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    useRouteError
} from 'react-router-dom';
import AdminPanel from '../../AdminPanel';
import Tutoring from '../../AdminPanel/Tutoring';
import Checklists from '../../Checklists';
import Details from '../../Checklists/Details';
import Dashboard from '../../Dashboard';
import DevSpace from '../../DevSpace';
import Crud from '../../DevSpace/Crud';
import {MyInfo} from '../../MyInfo';
import Settings from '../../Settings';
import StatusAlerts from '../../StatusAlerts';
import Error from './Error';
import AdminPanelRoute from './AdminPanelRoute';
import Layout from '../../Layout';
import {Locations} from '../../AdminPanel/Locations';

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" errorElement={<Error />}>
            <Route
                index
                element={
                    <Layout>
                        <Dashboard />
                    </Layout>
                }
            />
            <Route path="devSpace">
                <Route
                    index
                    element={
                        <Layout>
                            <AdminPanelRoute element={<DevSpace />} />
                        </Layout>
                    }
                />
                <Route
                    path="crud"
                    element={
                        <Layout>
                            <AdminPanelRoute element={<Crud />} />
                        </Layout>
                    }
                />
            </Route>
            <Route path="admin">
                <Route
                    index
                    element={
                        <Layout>
                            <AdminPanelRoute element={<AdminPanel />} />
                        </Layout>
                    }
                />
                <Route
                    path="tutoring"
                    element={
                        <Layout>
                            <AdminPanelRoute element={<Tutoring />} />
                        </Layout>
                    }
                />
                <Route
                    path="locations"
                    element={
                        <Layout>
                            <AdminPanelRoute element={<Locations />} />
                        </Layout>
                    }
                />
            </Route>
            <Route
                path="statusAlerts"
                element={
                    <Layout>
                        <StatusAlerts />
                    </Layout>
                }
            />
            <Route
                path="settings"
                element={
                    <Layout>
                        <Settings />
                    </Layout>
                }
            />
            <Route
                path="checklists"
                element={
                    <Layout>
                        <Checklists />
                    </Layout>
                }
            />
            <Route
                path="checklists/:code"
                element={
                    <Layout>
                        <Details />
                    </Layout>
                }
            />
            <Route
                path="myInfo"
                element={
                    <Layout>
                        <MyInfo />
                    </Layout>
                }
            />
        </Route>
    )
);

export default router;
