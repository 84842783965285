export const OptionsMapping = {
    isRecordMeeting: 'isRecordMeeting',
    isPublishToCanvas: 'isPublishToCanvas',
    isJoinBeforeHost: 'isJoinBeforeHost',
    isHideMeeting: 'isHideMeeting',
    isEnableWaitingRoom: 'isEnableWaitingRoom',
    isShare: 'isShare',
    isShareOtherCourse: 'isShareOtherCourse',
    meetingTitle: 'meetingTitle',
    meetingStartTime: 'meetingStartTime',
    meetingEndTime: 'meetingEndTime'
}

export const RecurringOptions = {
    noRepeat: {
        value: 'noRepeat',
        type: 0
    },
    daily: {
        value: 'daily',
        recurrenceLength: 15,
        occurenceLength: 20,
        displayHelper: 'day(s)',
        type: 1
    },
    weekly: {
        value: 'weekly',
        recurrenceLength: 12,
        occurenceLength: 20,
        displayHelper: 'week(s)',
        weekdays: {
            sunday: {id: 1, name: 'Sunday'},
            monday: {id: 2, name: 'Monday'},
            tuesday: {id: 3, name: 'Tuesday'},
            wednesday: {id: 4, name: 'Wednesday'},
            thursday: {id: 5, name: 'Thursday'},
            friday: {id: 6, name: 'Friday'},
            saturday: {id: 7, name: 'Saturday'}
        },
        weekday: 'weekday',
        type: 2
    },
    monthly: {
        value: 'monthly',
        recurrenceLength: 3,
        occurenceLength: 20,
        displayHelper: 'month(s)',
        daysOfMonthLength: 31,
        occursOnDay: 'day',
        occursOnWeek: 'week',
        monthlyDay: 'monthlyDay',
        monthlyWeek: 'monthlyWeek',
        monthlyWeekday: 'monthlyWeekDay',
        weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        weekFrequency: ['Last Week of Month', 'First Week', 'Second Week', 'Third Week', 'Fourth Week'],
        type: 3
    },
    endDateBy: 'by',
    endDateAfter: 'after',
    repeatsEvery: 'repeatsEvery',
    occurence: 'occurence',
    byDate: 'byDate'
}

export const MeetingDateFormat = 'YYYY-MM-DD'
export const MeetingTimeFormat = 'HH:mm:ss'