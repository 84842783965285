import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function ZoomClass() {
  return (
    <SvgIcon>
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
            {/* <defs>
                <style>
                .cls-1,.cls-2{stroke-width:5.23px;}
                .cls-1,.cls-2,.cls-3,.cls-4,.cls-5,.cls-6{stroke:#000;}
                .cls-1,.cls-2,.cls-4,.cls-5{stroke-miterlimit:10;}
                .cls-1,.cls-5{fill:#fff;}
                .cls-2{fill:#eaf3fb;}
                .cls-3,.cls-4{fill:#003057;}
                .cls-3,.cls-6{stroke-linecap:round;stroke-linejoin:round;stroke-width:4.8px;}
                .cls-4,.cls-5{stroke-width:4.31px;}
                .cls-6{fill:none;}
              </style>
            </defs> */}
            <rect class="cls-1" fill="#FFF" strokeMiterlimit="10" strokeWidth="5.23px" stroke="#000" x="24.83" y="7.92" width="249.38" height="196.64" rx="26.58" ry="26.58"/>
            <path class="cls-2" fill="#eaf3fb" strokeMiterlimit="10" strokeWidth="5.23px" stroke="#000" d="m24.83,182.7c0,12.58,10.34,22.78,23.09,22.78h203.2c12.75,0,23.09-10.2,23.09-22.78v-28.16H24.83v28.16Z"/>
            <ellipse class="cls-4" strokeWidth="4.31px" fill="#003057" strokeMiterlimit="10" stroke="#000" cx="144.87" cy="182.73" rx="9.98" ry="9.84"/>
            <rect class="cls-5" strokeWidth="4.31px"  fill="#FFF" strokeMiterlimit="10" stroke="#000" x="115.4" y="205.9" width="58.93" height="48.11"/>
            <line class="cls-5" strokeWidth="4.31px"  fill="#FFF" strokeMiterlimit="10" stroke="#000" x1="69.69" y1="254.01" x2="221.35" y2="254.01"/>
            <circle class="cls-3" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4.8px" fill="#003057" stroke="#000" cx="91.8" cy="75.15" r="21.42" transform="translate(-26.26 86.92) rotate(-45)"/>
            <path class="cls-3"  strokeLinecap="round" strokeLinejoin="round" strokeWidth="4.8px" fill="#003057" stroke="#000" d="m71.93,108.7h39.73c7.38,0,13.37,5.99,13.37,13.37v31.22H58.56v-31.22c0-7.38,5.99-13.37,13.37-13.37Z"/>
            <path class="cls-6"  strokeLinecap="round" strokeLinejoin="round" strokeWidth="4.8px" stroke="#000" d="m176.85,53.07c0,1.85-1.5,3.34-3.34,3.34s-3.34-1.5-3.34-3.34,1.5-3.34,3.34-3.34,3.34,1.5,3.34,3.34Z"/>
            <path class="cls-6"  strokeLinecap="round" strokeLinejoin="round" strokeWidth="4.8px" stroke="#000" d="m195.9,53.07c0,1.85-1.5,3.34-3.34,3.34s-3.34-1.5-3.34-3.34,1.5-3.34,3.34-3.34,3.34,1.5,3.34,3.34Z"/>
            <path class="cls-6"  strokeLinecap="round" strokeLinejoin="round" strokeWidth="4.8px" stroke="#000" d="m214.95,53.07c0,1.85-1.5,3.34-3.34,3.34s-3.34-1.5-3.34-3.34,1.5-3.34,3.34-3.34,3.34,1.5,3.34,3.34Z"/>
            <path class="cls-6"  fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4.8px" stroke="#000" d="m216.85,26.12h-48.58c-8.63,0-15.63,7-15.63,15.63v11.32l-16.82,18.92,17.44-3.48c1.82,6.62,7.82,11.51,15.01,11.51h48.58c8.63,0,15.63-7,15.63-15.63v-22.63c0-8.63-7-15.63-15.63-15.63Z"/>
      </svg>
    </SvgIcon>
  );
}