import { Amplify, Auth } from 'aws-amplify';
import config from './config';


Amplify.configure(config)

//log in through shibboleth
export const logIn = async () => {
    Auth.federatedSignIn({
        provider: 'Shibboleth'
    }).catch(err => {
        console.log('error sign in ', err)
        return false
    })
}

//log out
export const logOut = async () => {
    Auth.signOut()
        .catch(err => {
            console.log('error sign out ', err)
            return false
        })
}

// get jwt token for the logged-in user to authorize requests to API gateway
export const authorize = async () => {
    return new Promise((resolve, reject) => {
        Auth.currentSession()
            .then(session => {
                // console.log(session, session?.idToken, session?.idToken?.jwtToken)
                try {
                    resolve(session?.idToken?.jwtToken)
                }
                catch (err) {
                    reject("AWS Cognito token not found")
                }
            })
            .catch(err => {
                reject("invalid AWS Cognito session")
            })
    })
}

//test funtion to identify the logged in user
export const identifyUser = async () => {
    // authorize()
    const user = await Auth.currentUserInfo()
    return user ? parseUser(user) : {}
}

//parse the user object
export const parseUser = (user) => {
    return {
        midas: user?.attributes?.['custom:EPPN'],
        firstName: user?.attributes?.['custom:GIVEN_NAME'],
        lastName: user?.attributes?.['custom:FAMILY_NAME'],
        email: user?.attributes?.['email'],
        roles: user?.attributes?.['custom:ODU_ROLES']?.slice(1, user?.attributes?.['custom:ODU_ROLES'].length-1).split(',').reduce((collection, item) => ({...collection, [item.trim()]: true}), {}),
        groups: user?.attributes?.['custom:EDUPERSONENTITLEMENT']?.slice(1, user?.attributes?.['custom:EDUPERSONENTITLEMENT'].length-1).split(',').reduce((collection, item) => ({...collection, [item.trim()]: true}), {}),
    }
}



