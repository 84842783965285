import React, { Component } from 'react';
import Widget from '../common/Widget';
import TutoringTable from '../AdminPanel/Tutoring/TutoringTable';
import getColumns from '../AdminPanel/Tutoring/columns';
import { getData } from '../../DataAccessLayer';
import { tutoring } from '../../DataAccessLayer/services';
import { GridToolbar } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { IconButton, InputAdornment } from '@mui/material';
import { columnsToHideForMobile } from './config';
import { WithSmallScreen } from '../common/WithSmallScreen';

class CourseTutoring extends Component {
    state = {
        tutorings: [],
        widgetId: 'widget__courseTutoring',
        title: "Course Tutoring",
    }

    componentDidMount() {
        this.loadTutorings();
    }

    //O(1) Fetches all Tutorings
    loadTutorings() {
        getData(tutoring)
        .then(data => {
            this.setState({
                tutorings: data
            })
        })
    }

    render() {
        return <React.Fragment>
            <Widget data={{title: this.state.title, id: this.state.widgetId}} {...this.props}>
                <TutoringTable 
                    rows = {this.state.tutorings}
                    columns = {getColumns()}
                    columnVisibilityModel = { this.props.isSmall ? columnsToHideForMobile : {actions: false}}
                    slots={{toolbar: GridToolbar}}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            csvOptions: { disableToolbarButton: true },
                            printOptions: { disableToolbarButton: true },
                            quickFilterProps: {
                                sx: {m:1, width: '100%'},
                                variant: "outlined",
                                size: "small",
                                placeholder: "Search Course Tutoring"                                
                            }
                        }
                    }}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                />
            </Widget>
        </React.Fragment>
    }
}

export default WithSmallScreen(CourseTutoring);