import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faCircleExclamation
} from '@fortawesome/pro-light-svg-icons';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Grid,
  Box
} from '@mui/material';
import {
  ACTION_HIDE,
  ACTION_UNHIDE,
  ALERT_KEY_FINANCIAL_AID,
  CRITICAL
} from '../common/constants';
import AlertAction from './AlertAction';
import AlertContact from './AlertContact';
import AlertProhibits from './AlertProhibits';
import AlertDescription from './AlertDescription';

class Alert extends Component {
  state = {
    isExpand: false,
    isCritical: false
  };

  componentDidMount() {
    if (this.props.item?.def?.severity === CRITICAL) {
      this.setState({isCritical: true, isExpand: true});
    } else if (this.props.expand) {
      this.setState({isExpand: true});
    }
  }

  toggleIsExpand = () => {
    this.setState({isExpand: !this.state.isExpand});
  };

  render() {
    const isProhibitAvailable = this.props.item.def.prohibits?.length > 0;
    let finalAddressLine = this.props.item.def.primaryContactOffice;
    const addressLine1 = this.props.item.def.primaryAddressLine1;
    if (addressLine1) {
      finalAddressLine = `${finalAddressLine ?? ''}, ${addressLine1}`;
    }
    const addressLine2 = this.props.item.def.primaryAddressLine2;
    const phoneNumber = this.props.item.def.primaryPhone;
    const email = this.props.item.def.primaryContactEmail;
    const isContactsSectionVisible = finalAddressLine || phoneNumber || email;
    const linkTitle = this.props.item.def.linkTitle;
    const linkUrl = this.props.item.def.linkUrl;

    return (
      <Accordion
        elevation={0}
        expanded={this.state.isExpand}
        id={this.props.alertKey + '_' + this.props.index + '_accordion'}
        className={'myOdu__accordion myOdu__border p-2 ' + (this.state.isCritical ? 'error' : ' nonCritical')}
        sx={{
          width: '100%',
          p: this.state.isCritical ? '1' : 0,
          m: 0
        }}
      >
        <AccordionSummary
          expandIcon={<FontAwesomeIcon icon={faChevronDown} size="lg" />}
          id={
            this.props.alertKey + '_' + this.props.index + '_accordionSummary'
          }
          aria-controls={
            this.props.alertKey + '_' + this.props.index + '_accordionDetails'
          }
          sx={{
            m: 0,
            p: 0,
            flexDirection: 'row-reverse',
            flexGrow: 1
          }}
          onClick={this.toggleIsExpand}
        >
          <Stack direction="row" sx={{ml: 0.5}}>
            {this.state.isCritical && (
              <FontAwesomeIcon
                fixedWidth
                className="myOdu__error"
                icon={faCircleExclamation}
                style={{alignSelf: 'center', marginRight: '.25rem'}}
                size="lg"
              />
            )}
            <Typography variant="h6" component="h4">
              {this.props.item.title}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails
          id={
            this.props.alertKey + '_' + this.props.index + '_accordionDetails'
          }
          sx={{p: 1, mt: 1}}
        >
          <Stack
            direction={{
              // xs: 'column-reverse', //JMD remove
              lg: 'row'
            }}
            sx={{m: 0, p: 0}}
            justifyContent="space-between"
          >
            <Box>
              <AlertDescription
                description={this.props.item.def.text}
                alertKey={this.props.alertKey}
                index={this.props.index}
                linkTitle={linkTitle}
                linkUrl={linkUrl}
              />

              <Grid container>
                {isProhibitAvailable && (
                  <Grid item xs={12} md={6} sx={{mt: 1}}>
                    <AlertProhibits
                      prohibits={this.props.item.def.prohibits}
                      alertKey={this.props.alertKey}
                    />
                  </Grid>
                )}

                {isContactsSectionVisible && (
                  <Grid
                    item
                    xs={12}
                    md={isProhibitAvailable ? 6 : 12}
                    sx={{mt: 1}}
                  >
                    <AlertContact
                      addressLine1={finalAddressLine}
                      contactUrl={this.props.item.def.primaryContactUrl}
                      addressLine2={addressLine2}
                      phoneNumber={phoneNumber}
                      email={email}
                      alertKey={this.props.alertKey}
                      index={this.props.index}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>

            {this.props.alertKey === ALERT_KEY_FINANCIAL_AID &&
              ((this.props.item.def.hideable &&
                this.props.action === ACTION_HIDE) ||
                this.props.action === ACTION_UNHIDE) && (
                <Box sx={{minWidth: '7rem', mb: 1, ml: {lg: 1}}}>
                  <AlertAction
                    item={this.props.item}
                    action={this.props.action}
                    toggleAlertVisibility={this.props.toggleAlertVisibility}
                    label={
                      this.props.item.def.hideable &&
                      this.props.action === ACTION_HIDE
                        ? 'Hide Alert'
                        : 'Unhide Alert'
                    }
                  />
                </Box>
              )}
          </Stack>
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default Alert;
