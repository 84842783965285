import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Button,
    Stack,
    Chip
} from '@mui/material';
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {DateRangePicker} from '@mui/x-date-pickers-pro/DateRangePicker';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import React, {Component} from 'react';
import {Col, Row} from 'reactstrap';
import {
    MeetingDateFormat,
    MeetingTimeFormat,
    OptionsMapping,
    RecurringOptions
} from './config';
import moment from 'moment';
import RepeatMeetings from './RepeatMeetings';
import {connect} from 'react-redux';
import {postData} from '../../../../../DataAccessLayer';
import {adhoc, cct} from '../../../../../DataAccessLayer/services';
import {toast} from 'react-toastify';
import {ZoomTabMapping} from '../config';
import {zoomDevEmail} from '../../../../common/constants';

class Adhoc extends Component {
    state = {
        isLoading: false,
        meetingTitle: '',
        recurrence: RecurringOptions.noRepeat.value,

        [OptionsMapping.isRecordMeeting]: false,
        [OptionsMapping.isPublishToCanvas]: false,
        [OptionsMapping.isJoinBeforeHost]: false,
        [OptionsMapping.isEnableWaitingRoom]: false,
        [OptionsMapping.isHideMeeting]: false,
        [OptionsMapping.isShare]: false,
        [OptionsMapping.meetingStartTime]: dayjs(moment()),
        [OptionsMapping.meetingEndTime]: '',

        otherClasses: [],
        meetingDates: [dayjs(moment())],

        recurring: {
            endDate: RecurringOptions.endDateBy, //End Date type under Recurrence
            byDate: dayjs(this.props.course.END_DATE), //End Date By
            [RecurringOptions.occurence]: 1, //End Date Occurence

            [RecurringOptions.repeatsEvery]: 1, //Recurrence

            weekdays: {}, //Weeklu Occurs on weekdays

            monthOccursOn: RecurringOptions.monthly.occursOnDay, //Monthly occurs on type
            monthlyDay: 1, //Month Occurs on Day
            monthlyWeek: 1, //Month Occurs on Week
            monthlyWeekDay: 0 //Month Occurs on: Weekday
        }
    };

    toggleIsLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading
        });
    };

    //Θ(1) Sets meetingTitle, start and end time
    componentDidMount() {
        let course = this.props.course;
        let crns = course.CRNS.split(',');
        let meetingTitle =
            course.SSBSECT_TERM_CODE +
            '_' +
            course.SUBJECT_CODE +
            '_' +
            course.COURSE_NUMBER +
            '_' +
            crns[0];

        let currentTime = moment();
        let startTime = '';
        let endTime = '';
        if (currentTime.minute() >= 30) {
            startTime = currentTime
                .add(60 - (currentTime.minute() % 60), 'minutes')
                .format('YYYY-MM-DDTHH:mm');
            endTime = currentTime
                .add(60 - (currentTime.minute() % 60) + 60, 'minutes')
                .format('YYYY-MM-DDTHH:mm');
        } else {
            startTime = currentTime
                .subtract(currentTime.minute() % 60, 'minutes')
                .format('YYYY-MM-DDTHH:mm');
            endTime = currentTime
                .add((currentTime.minute() % 60) + 60, 'minutes')
                .format('YYYY-MM-DDTHH:mm');
        }

        this.setState({
            meetingTitle,
            [OptionsMapping.meetingStartTime]: dayjs(startTime),
            [OptionsMapping.meetingEndTime]: dayjs(endTime)
        });
    }

    //Θ(1) Sets recurrence value
    onRecurrenceChange = event => {
        this.setState({recurrence: event.target.value});
    };

    //Θ(1) Sets respective checkbox value
    onCheckBoxUpdate = event => {
        this.setState({
            [event.target.name]: event.target.checked
        });
    };

    //Θ(1) Sets textfield value
    onTextFieldUpdate = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    //Θ(1) Sets date
    onDateChange = value => {
        let meetingDates = [];
        meetingDates.push(value);
        this.setState({
            meetingDates
        });
    };

    //Θ(1) Sets from and to date
    onDatesChange = values => {
        let meetingDates = values.map(value => {
            if (value) return moment(value['$d']).format(MeetingDateFormat);
        });

        this.setState({
            meetingDates
        });
    };

    //Θ(1) Sets time
    onTimeChange = (value, name) => {
        this.setState({
            [name]: value
        });
    };

    //Θ(N) where N is the length of other classes
    //Adds class to the share list
    onOtherClasses = event => {
        let otherClasses = this.state.otherClasses;
        if (
            otherClasses.find(
                otherClass => otherClass.CRNS === event.target.value.CRNS
            ) !== undefined
        )
            return;
        otherClasses.push(event.target.value);
        this.setState({
            otherClasses
        });
    };

    //Θ(N) where N is length of other classes
    //Deletes class from the share list
    onDeleteOtherClass = classToDelete => {
        let otherClasses = this.state.otherClasses.filter(
            otherClass => otherClass.CRNS !== classToDelete.CRNS
        );
        this.setState({
            otherClasses
        });
    };

    //Θ(N + M) where N is the length of days in week and M is the length of the classes to be shared with
    //Creates the body of post request and hits the api
    onSchedule = () => {
        this.toggleIsLoading();
        let course = this.props.course;

        //Basic details
        let email = zoomDevEmail;
        let crns = course.CRNS;
        let termCode = course.SSBSECT_TERM_CODE;
        let topic = this.state.meetingTitle;

        let agenda = ''; //Empty in pprd
        let date = moment(this.state.meetingDates[0]['$d']).format(
            MeetingDateFormat
        );
        let beginTime = moment(
            this.state[OptionsMapping.meetingStartTime]['$d']
        ).format(MeetingTimeFormat);

        //Duration
        let startMoment = moment(
            this.state[OptionsMapping.meetingStartTime]['$d']
        );
        let endMoment = moment(this.state[OptionsMapping.meetingEndTime]['$d']);
        let duration = endMoment.diff(startMoment, 'minutes').toString(); //Calculate total duration of meeting from start and end time

        //Basic details
        let record = this.state.isRecordMeeting;
        let waitingRoom = this.state[OptionsMapping.isEnableWaitingRoom];

        //Weekly - These elements are sent only if recurrence is weekly
        //Forms the weekly_days string from array [1,2,3] => "1,2,3"
        let weekly_days = Object.keys(this.state.recurring.weekdays).reduce(
            (weekly, weekDay, idx) => {
                return weekly + (idx !== 0 ? ',' : '') + weekDay;
            },
            ''
        );
        let isWeekly =
            RecurringOptions[this.state.recurrence].type ===
            RecurringOptions.weekly.type; //Checks if the recurrence is weekly

        //Sets date if end by date is selected under recurrence
        let isEndDateBy =
            this.state.recurring.endDate === RecurringOptions.endDateBy;
        //Sets after if end after is selected under recurrence
        let isEndDateAfter =
            this.state.recurring.endDate === RecurringOptions.endDateAfter;

        //Monthly - These elements are sent only if recurrence is monthly
        let month_day = this.state.recurring.monthlyDay;
        let monthly_week = this.state.recurring.monthlyWeek;
        let monthly_week_day = this.state.recurring.monthlyWeekDay;

        //Checks if is monthly recurrence and occurs on day
        let isMonthlyAndDay =
            RecurringOptions[this.state.recurrence].type ===
                RecurringOptions.monthly.type &&
            this.state.recurring.monthOccursOn ===
                RecurringOptions.monthly.occursOnDay;
        //Checks if is monthly recurrence and occurs on month setting
        let isMonthlyAndMonth =
            RecurringOptions[this.state.recurrence].type ===
                RecurringOptions.monthly.type &&
            this.state.recurring.monthOccursOn ===
                RecurringOptions.monthly.occursOnWeek;

        //Recurrence object
        let recurrence =
            this.state.recurrence !== RecurringOptions.noRepeat.value
                ? {
                      type: RecurringOptions[this.state.recurrence].type,
                      repeat_interval:
                          this.state.recurring[RecurringOptions.repeatsEvery],
                      ...(isEndDateBy && {
                          end_date_time: moment(
                              this.state.recurring[RecurringOptions.byDate][
                                  '$d'
                              ]
                          ).format('YYYY-MM-DDTHH:MM:SS[Z]')
                      }),
                      ...(isEndDateAfter && {
                          end_times:
                              this.state.recurring[RecurringOptions.occurence]
                      }),

                      //Weekly
                      ...(isWeekly && {weekly_days}),

                      //Monthly
                      ...(isMonthlyAndDay && {month_day}),
                      ...(isMonthlyAndMonth && {monthly_week, monthly_week_day})
                  }
                : {};

        let shareWith = this.state.otherClasses.map(
            otherClass => otherClass.CRNS
        );

        //Returns of sharewith is checked and empty
        if (this.state[OptionsMapping.isShare] && !shareWith?.length) {
            toast.error('No other courses added');
            this.toggleIsLoading();
            return;
        }

        let meetingDetails = {
            email,
            crns,
            termCode,
            topic,
            agenda,
            date,
            beginTime,
            duration,
            record,
            waitingRoom,
            ...(this.state.recurrence !== RecurringOptions.noRepeat.value && {
                recurrence
            }),
            ...(this.state.isShare && {shareWith})
        };

        postData(adhoc, meetingDetails)
            .then(result => {
                if (!result) toast.error('Unable to create meetings');
                else {
                    toast.success('Meetings Created Successfully');
                    this.props.onTabChange('', ZoomTabMapping.currentMeeting);
                }
                this.toggleIsLoading();
            })
            .catch(err => {
                this.toggleIsLoading();
                console.log(err);
            });
    };

    //Θ(1) Updates end date
    onEndDateChanged = event => {
        this.setState({
            recurring: {
                ...this.state.recurring,
                [event.target.name]: event.target.value
            }
        });
    };

    //Θ(1) Sets recurring object
    setRecurring = recurring => {
        this.setState({
            recurring
        });
    };

    render() {
        return (
            <React.Fragment>
                <Row style={{padding: 4}}>
                    <Col>Meeting Title</Col>
                    <Col>
                        <TextField
                            id="textfield-meeting-title"
                            label="Meeting Title"
                            variant="outlined"
                            name={OptionsMapping.meetingTitle}
                            onChange={this.onTextFieldUpdate}
                            value={this.state[OptionsMapping.meetingTitle]}
                        />
                    </Col>
                </Row>
                <Row style={{padding: 4}}>
                    <Col>Recurrence</Col>
                    <Col>
                        <FormControl fullWidth>
                            <InputLabel id="lbl-select-recurrence">
                                Recurrence
                            </InputLabel>
                            <Select
                                labelId="lbl-select-Recurrence"
                                id="select-Recurrence"
                                value={this.state.recurrence}
                                label="Repeats"
                                onChange={this.onRecurrenceChange}
                            >
                                <MenuItem
                                    value={RecurringOptions.noRepeat.value}
                                >
                                    Does Not Repeat
                                </MenuItem>
                                <MenuItem value={RecurringOptions.daily.value}>
                                    Daily
                                </MenuItem>
                                <MenuItem value={RecurringOptions.weekly.value}>
                                    Weekly
                                </MenuItem>
                                <MenuItem
                                    value={RecurringOptions.monthly.value}
                                >
                                    Monthly
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                </Row>
                {this.state.recurrence !== RecurringOptions.noRepeat.value && (
                    <Row style={{padding: 4}}>
                        <RepeatMeetings
                            recurrence={RecurringOptions[this.state.recurrence]}
                            onEndDateChanged={this.onEndDateChanged}
                            recurring={this.state.recurring}
                            setRecurring={this.setRecurring}
                        />
                    </Row>
                )}
                <Row style={{padding: 4}}>
                    <Col>Date</Col>
                    <Col>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker
                                    defaultValue={this.state.meetingDates[0]}
                                    onChange={this.onDateChange}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Col>
                </Row>
                <Row style={{padding: 4}}>
                    <Col>Time</Col>
                    <Col>
                        <Box sx={{display: 'flex'}}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['TimePicker']}>
                                    <TimePicker
                                        value={
                                            this.state[
                                                OptionsMapping.meetingStartTime
                                            ]
                                        }
                                        label="From"
                                        onAccept={value =>
                                            this.onTimeChange(
                                                value,
                                                OptionsMapping.meetingStartTime
                                            )
                                        }
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                            -
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['TimePicker']}>
                                    <TimePicker
                                        value={
                                            this.state[
                                                OptionsMapping.meetingEndTime
                                            ]
                                        }
                                        label="To"
                                        onAccept={value =>
                                            this.onTimeChange(
                                                value,
                                                OptionsMapping.meetingEndTime
                                            )
                                        }
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Box>
                    </Col>
                </Row>
                <Row style={{padding: 4}}>
                    <Col>Options</Col>
                    <Col>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={OptionsMapping.isShare}
                                        onChange={this.onCheckBoxUpdate}
                                    />
                                }
                                label="Share this meeting with my other courses"
                            />
                            <Row>
                                {this.state.isShare && (
                                    <Row>
                                        <Row>
                                            <FormControl fullWidth>
                                                <InputLabel id="lbl-select-other-classes">
                                                    Select other classes
                                                </InputLabel>
                                                <Select
                                                    labelId="lbl-select-other-classes"
                                                    id="select-other-classes"
                                                    label="Select other classes"
                                                    value={0}
                                                    onChange={
                                                        this.onOtherClasses
                                                    }
                                                >
                                                    {this.props.courses.map(
                                                        course => {
                                                            return (
                                                                <MenuItem
                                                                    value={
                                                                        course
                                                                    }
                                                                >
                                                                    {course.COURSE_TITLE +
                                                                        ' (' +
                                                                        course.CRNS +
                                                                        ')'}
                                                                </MenuItem>
                                                            );
                                                        }
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Row>
                                        <Row>
                                            <Stack
                                                direction={'row'}
                                                spacing={1}
                                                sx={{flexWrap: 'wrap'}}
                                            >
                                                {this.state.otherClasses.map(
                                                    otherClass => {
                                                        return (
                                                            <Chip
                                                                size="small"
                                                                label={
                                                                    otherClass.COURSE_TITLE +
                                                                    ' (' +
                                                                    otherClass.CRNS +
                                                                    ')'
                                                                }
                                                                onDelete={() => {
                                                                    this.onDeleteOtherClass(
                                                                        otherClass
                                                                    );
                                                                }}
                                                            />
                                                        );
                                                    }
                                                )}
                                            </Stack>
                                        </Row>
                                    </Row>
                                )}
                            </Row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={OptionsMapping.isRecordMeeting}
                                        onChange={this.onCheckBoxUpdate}
                                    />
                                }
                                label="Record Meeting"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={
                                            OptionsMapping.isEnableWaitingRoom
                                        }
                                        onChange={this.onCheckBoxUpdate}
                                    />
                                }
                                label="Enable Waiting Room"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col></Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col></Col>
                </Row>
                <Row style={{float: 'right'}}>
                    <Col xs={'auto'}>
                        <Button onClick={this.props.onToggle}>Back</Button>
                    </Col>
                    <Col>
                        <Button
                            onClick={this.onSchedule}
                            disabled={this.state.isLoading}
                        >
                            Schedule Adhoc Meeting
                        </Button>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        course: state.CCTReducer.course,
        courses: state.myCoursesReducer.coursesTeaching
    };
};

export default connect(mapStateToProps)(Adhoc);
