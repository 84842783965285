import { Box, Grid, ListItem, Skeleton, Typography } from '@mui/material';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ContactCard from '../common/ContactCard';
import { saveContactsUpdate } from './actions';
import {toast} from 'react-toastify'

class Results extends Component {
    state = {  }

    //Θ(1) add new midas to the list and update
    onAdd = (data) => {
        this.props.saveContactsUpdate([...this.props.contacts.map(contact => contact.midas), data.midas])
        toast.success('Contact for ' + data.lastName.toUpperCase() + ', ' + data.firstName.toUpperCase() + ' added')
    }

    render() { 
        return <div className={this.props.className} style={{height: '100%'}}>
            <Typography variant="h6" component="h4" className="pt-3">Search Results</Typography>
            <Box sx={{overflow: 'auto', maxHeight: '20rem'}}>
                <Grid container alignItems={'stretch'} sx={{p: 0, mt: 1}} spacing={1}>
                    {   !this.props.isSearching && this?.props?.results ?
                        this.props.results.map(contact => {
                        const isExists = this.props.contacts.some(existingContact => existingContact.midas == contact.midas)
                        return <Grid
                            item
                            sx={{pt: 1}}
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            key={contact.midas}
                        >
                            <ContactCard data={contact} onAdd={this.onAdd} isCheck={isExists} isAdd={!isExists} isDisabled={this.props.isLoading} />
                        </Grid>
                        })
                        : Array.from({length: 2}, (_, index) => (
                            <ListItem
                                sx={{width: '100%', py:1, px:0}}
                                key={index}
                            >
                                <Box sx={{width: '100%'}}>
                                    <Skeleton
                                        variant="rectangular"
                                        height={80}
                                    />
                                </Box>
                            </ListItem>
                        ))
                    }
                    {this.props.results.length == 0 && !this.props.isSearching && <Typography component="p" variant="small" sx={{ml:2}}>No search results</Typography>}
                </Grid>
            </Box>
        </div>
    }
}
 
const mapStateToProps = (state) => {
    return {
        contacts: state.myContactsReducer.contacts,
        results: state.myContactsReducer.results,
        isLoading: state.myContactsReducer.isLoading,
    }
  }
  
  const mapDispatchToProps = (dispatch) => ({
    saveContactsSearch: (search) => dispatch(saveContactsSearch(search)),
    saveContactsUpdate: (updates) => dispatch(saveContactsUpdate(updates)),
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(Results);