import React, { Component } from 'react';
import {FormLabel, FormControl, FormGroup, Checkbox, RadioGroup, Radio, FormControlLabel, ThemeProvider} from '@mui/material';
import { Container, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { saveSetup } from './actions';
import theme from '../../theme';
import { CAMPUS, HOUSING, constants } from '../common/constants';
import { campus } from '../common/constants';

class Questions extends Component {
    state = {}

    //Θ(1) handle campus check change
    onCampusChange = (campusName, isChecked) => {
        let setup = this.props.setup
        if(isChecked)
            setup['campus'] = {...(setup?.campus ?? {}), [campusName]: true}
        else{
            delete setup.campus[campusName]
            if(!Object.keys(setup.campus).length)
                delete setup['campus']
        }
        this.props.saveSetup(setup);
        this.props.onAnswersChanged(CAMPUS);
    }

    //Θ(1) handle housing change
    onHousingChange = (isHousing) => {
        let setup = this.props.setup
        setup['isHousing'] = isHousing
        this.props.saveSetup(setup)
        this.props.onAnswersChanged(HOUSING);
    }

    //Θ(1) handle meal change
    onMealChange = (isMeal) => {
        let setup = this.props.setup
        setup['isMeal'] = isMeal
        this.props.saveSetup(setup)
        //TODO: Call onAnswersChanged
    }

    render() {
        const isStudent = "STUDENT" in this.props.user.roles
        const isStaff = "STAFF" in this.props.user.roles
        return <Container fluid>
            <ThemeProvider theme={theme}>
            {/* //TODO: default q1&2 */}
            
                {/* <li>
                    <Row className="mb-2">
                        <Col xs={12}><FormLabel id="isStudentLabel" color="error">1. Are you a student?</FormLabel></Col>
                        <Col xs={12} className="ps-3">
                            <FormControl sx={{pl:2}}>
                                <RadioGroup row aria-labelledby="isStudentLabel" name="isStudent">
                                    <FormControlLabel value="yes" control={<Radio size='small' color="secondary"/>} label="Yes" disabled checked={isStudent}/>
                                    <FormControlLabel value="no" control={<Radio size='small' color="secondary"/>} label="No" disabled checked={!isStudent}/>
                                </RadioGroup>
                            </FormControl>
                        </Col>
                    </Row>
                </li>
                <li>
                    <Row className="mb-2">
                        <Col xs={12}><FormLabel id="doesWorkLabel">2. Do you work for the University?</FormLabel></Col>
                        <Col xs={12} className="ps-3">
                            <FormControl sx={{pl:2}}>
                                <RadioGroup row aria-labelledby="doesWorkLabel" name="doesWork">
                                    <FormControlLabel value="yes" control={<Radio size='small' color="secondary"/>} label="Yes" disabled checked={isStaff} />
                                    <FormControlLabel value="no" control={<Radio size='small' color="secondary"/>} label="No" disabled checked={!isStaff} />
                                </RadioGroup>
                            </FormControl>
                        </Col>
                    </Row>
                </li> */}
              
                    <Row className="mb-2">
                        <Col xs={12}><FormLabel id="doesLiveOduLabel">1. Do you live in University housing?</FormLabel></Col>
                        <Col xs={12} className="ps-3">
                            <FormControl sx={{pl:2}}>
                                <RadioGroup row aria-labelledby="doesLiveOduLabel" name="doesLiveOdu">
                                    <FormControlLabel value={true} control={<Radio size='small' color="secondary" onChange={e => this.onHousingChange(true)}/>} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio size='small' color="secondary" onChange={e => this.onHousingChange(false)} />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Col>
                    </Row>
              
                    <Row className="mb-2">
                        <Col xs={12}><FormLabel id="hasMealPlanLabel">2. Do you have a meal plan?</FormLabel></Col>
                        <Col xs={12} className="ps-3">
                            <FormControl sx={{pl:2}}>
                                <RadioGroup row aria-labelledby="hasMealPlanLabel" name="hasMealPlan">
                                    <FormControlLabel value={true} control={<Radio size='small' color="secondary" onChange={e => this.onMealChange(true)}/>} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio size='small' color="secondary" onChange={e => this.onMealChange(false)}/>} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Col>
                    </Row>
               
                    <Row>
                        <Col><FormLabel id="campusesLabel">3. Which campus(es) are you associated with?</FormLabel></Col>
                        <Col xs={12} className="ps-3">
                            <FormGroup sx={{pl:2}}>
                                <FormControlLabel sx={{alignItems: 'flex-start'}} control={<Checkbox size='small' color="secondary" onChange={e => this.onCampusChange('main', e.target.checked)}/>} size="small" label="Norfolk Main Campus" />
                                <FormControlLabel sx={{alignItems: 'flex-start'}} control={<Checkbox size='small' color="secondary" onChange={e => this.onCampusChange('evms', e.target.checked)}/>} label="EVHS Campus" />
                                <FormControlLabel sx={{alignItems: 'flex-start'}} control={<Checkbox size='small' color="secondary" onChange={e => this.onCampusChange('triCities', e.target.checked)}/>} label="ODU Tri-Cities Center" />
                                <FormControlLabel sx={{alignItems: 'flex-start'}} control={<Checkbox size='small' color="secondary" onChange={e => this.onCampusChange('virginiaBeach', e.target.checked)}/>} label="ODU Virginia Beach Center" />
                                <FormControlLabel sx={{alignItems: 'flex-start'}} control={<Checkbox size='small' color="secondary" onChange={e => this.onCampusChange('peninsula', e.target.checked)}/>} label="ODU Peninsula Center" />
                            </FormGroup>
                        </Col> 
                    </Row>
              
            
            </ThemeProvider>
        </Container>
        // <React.Fragment>
        //     <Row style={{borderRight: '1px solid grey'}}>
        //         <Row className='mb-3'>
        //         <FormControl>
        //                 <FormLabel id="lbl-campus">1. What campus(es) are you associated with?</FormLabel>
        //                 <FormGroup>
        //                     <FormControlLabel control={<Checkbox size='small' onChange={e => this.onCampusChange('main', e.target.checked)}/>} label="Norfolk Main Campus" />
        //                     <FormControlLabel control={<Checkbox size='small' onChange={e => this.onCampusChange('evms', e.target.checked)}/>} label="EVMS Campus" />
        //                     <FormControlLabel control={<Checkbox size='small' onChange={e => this.onCampusChange('triCities', e.target.checked)}/>} label="ODU Tri-Cities Center" />
        //                     <FormControlLabel control={<Checkbox size='small' onChange={e => this.onCampusChange('virginiaBeach', e.target.checked)}/>} label="ODU Virginia Beach Center" />
        //                     <FormControlLabel control={<Checkbox size='small' onChange={e => this.onCampusChange('peninsula', e.target.checked)}/>} label="ODU Peninsula Center" />
        //                 </FormGroup>
        //             </FormControl>
        //         </Row>
        //         <Row className='mb-3'>
        //             <FormControl>
        //                 <FormLabel id="lbl-housing">2. Do you live in ODU housing?</FormLabel>
        //                 <RadioGroup aria-labelledby="lbl-housing">
        //                     <FormControlLabel value={true} control={<Radio size='small' onChange={e => this.onHousingChange(true)}/>} label="Yes" />
        //                     <FormControlLabel value={false} control={<Radio size='small' onChange={e => this.onHousingChange(false)} />} label="No" />
        //                 </RadioGroup>
        //             </FormControl>
        //         </Row>
        //         <Row>
        //             <FormControl>
        //                 <FormLabel id="lbl-meal">3. Do you have a meal plan?</FormLabel>
        //                 <RadioGroup aria-labelledby="lbl-meal">
        //                     <FormControlLabel value={true} control={<Radio size='small' onChange={e => this.onMealChange(true)} />} label="Yes" />
        //                     <FormControlLabel value={false} control={<Radio size='small' onChange={e => this.onMealChange(false)} />} label="No" />
        //                 </RadioGroup>
        //             </FormControl>
        //         </Row>
        //     </Row>
        // </React.Fragment>
    }
}

const mapStateToProps = (state) => {
    return {
        setup: state.setupReducer.setup,
        user: state.AWSReducer.user,
    }
  }
  
const mapDispatchToProps = (dispatch) => ({
    saveSetup: (setup) => dispatch(saveSetup(setup))
});
  
export default connect(mapStateToProps, mapDispatchToProps)(Questions);