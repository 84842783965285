import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLocationDot} from '@fortawesome/pro-solid-svg-icons';
import {Link, Typography, Stack, Tooltip} from '@mui/material';
import Alarm from '../common/icons/Alarm.js';
import ZoomClass from '../common/icons/ZoomClass.js';

const getIcon = (isSoon, isOnline) => {
    return isSoon ? (
        <Alarm />
    ) : isOnline ? (
        <ZoomClass />
    ) : (
        <FontAwesomeIcon icon={faLocationDot} fixedWidth size={'xl'} />
    );
};

export const makeTitle = (courseSubjectNumber, courseTitle) => {
    return (
        <Stack>
            <Typography
                component="h3"
                variant="small"
                className="myOdu__lineClamp-1"
            >
                {courseSubjectNumber} {courseTitle}
            </Typography>
        </Stack>
    );
};

export const getClickableIcon = ({id, isSoon, isOnline, locationUrl}) => {
    if (locationUrl) {
        return (
            <Tooltip
                id={id + '_tooltip'}
                title={isOnline ? 'Launch Zoom' : 'Open in new window'}
                enterDelay={750}
                leaveDelay={0}
                enterNextDelay={750}
                arrow
            >
                <Link href={locationUrl} target="_blank">
                    {getIcon(isSoon, isOnline)}
                </Link>
            </Tooltip>
        );
    } else return getIcon(isSoon, isOnline);
};
