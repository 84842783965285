import { adminGroupName, MAIN_BRANCH, powerUserGroupName } from "../common/constants"

export const isProduction = () => {
    const isProduction = process.env.REACT_APP_GIT_BRANCH == MAIN_BRANCH
    return isProduction
}

export const isUserAdmin = (user) => {
    const isAdmin = user?.groups?.[adminGroupName]
    return isAdmin
}

export const isUserPowerUser = (user) => {
    const isPowerUser = user?.groups?.[powerUserGroupName]
    return isPowerUser
}