import { Box, Button } from '@mui/material';
import React, { Component } from 'react';

class Teams extends Component {
    state = {  } 
    render() { 
        return <React.Fragment>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around'}}>
                <Button variant='contained'>Create a team for my class</Button>
                <Button variant='contained' disabled>Launch teams</Button>
                <Button variant='contained' disabled>Delete team</Button>
            </Box>
        </React.Fragment>
    }
}
 
export default Teams;