import { Box, Button, InputLabel, OutlinedInput, Typography } from '@mui/material';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { saveContactsSearch } from './actions';
import { FormTextField } from '../common/Form/FormTextField'

const boxStyle ={
    xs: 0.5,
    xxl:2 
}

class Search extends Component {
    state = {
        firstName: '',
        lastName: ''
    }

    onFirstNameChange = (value) => {
        this.props.saveContactsSearch({...this.props.search, firstName: value})
    }

    onLastNameChange = (value) => {
        this.props.saveContactsSearch({...this.props.search, lastName: value})
    }

    render() { 
        return <div className={this.props.className} >
            <Typography variant="h4" component="h3" className="py-3">Add Contact</Typography>
            <Typography variant="h6" component="h4" mb={2}>Add from University Directory</Typography>
            <Row>
                <Col>
                    <Box>
                        <FormTextField 
                            id="contact-fname"
                            fullWidth size='small' 
                            label='First Name'
                            disabled={this.props.isLoading} 
                            placeholder='First Name'
                            value={this.props.search.firstName}
                            onChange={this.onFirstNameChange}
                            textAlign={{xs: "start" }}
                            labelGridProps={{xs: 12, sm: 12, md: 3}}
                            inputGridProps={{xs: 12, sm: 12, md: 9}}
                        />
                    </Box>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Box py={boxStyle}>
                        <FormTextField 
                            id="contact-lname"
                            fullWidth size='small' 
                            label='Last Name'
                            disabled={this.props.isLoading} 
                            placeholder='Last Name'
                            value={this.props.search.lastName}
                            onChange={this.onLastNameChange}
                            textAlign={{xs: "start"}}
                            labelGridProps={{xs: 12, sm:12, md: 3}}
                            inputGridProps={{xs: 12, sm:12,  md: 9}}
                        />
                    </Box>
                </Col>
            </Row>
            <Row className="text-end">
                <Col>
                    {/* <Box style={{float: 'right'}}> */}
                        <Button 
                            variant="outlined" sx={{mt:1}}
                            className='myOdu__button primary contactManagerButton'
                            onClick={this.props.onSearch}
                            disabled={this.props.isLoading || (!this.props.search.firstName && !this.props.search.lastName)}>
                            {'Search'}
                        </Button>
                    {/* </Box> */}
                </Col>
            </Row>
        </div>
    }
}
 
const mapStateToProps = (state) => {
    return {
        search: state.myContactsReducer.search,
        isLoading: state.myContactsReducer.isLoading
    }
  }
  
  const mapDispatchToProps = (dispatch) => ({
    saveContactsSearch: (search) => dispatch(saveContactsSearch(search)),
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(Search);