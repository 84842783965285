import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, Typography } from "@mui/material";
import React, {Component} from "react";
import { Col, Row } from "reactstrap";

class GPADetails extends Component {
    state = {

    }

    render() {
        return <React.Fragment>
            <Row style={{marginBottom: '1rem'}}>
                <Col xs={4} style={{marginTop: '4px'}}>
                    <Typography component='h4' variant="h6">{this.props.title}</Typography>
                </Col>
                <Col xs={8}>
                    { 
                        this.props.data &&
                        <Row>
                            <Col xs={3}>
                                <Box style={{float: 'right', marginTop: '4px'}}>
                                    <Typography component="p" variant='h4' style={{wordWrap: 'break-word'}}>{this.props.isDisplayGPA ? this.props.data : '---'}</Typography>
                                </Box>
                            </Col>
                            <Col xs={3}>
                                <IconButton id="myPrograms__button_toggleGPA" aria-label="hide-gpa" onClick={this.props.toggleIsDisplayGPA}>
                                    <FontAwesomeIcon icon={this.props.isDisplayGPA && faEyeSlash || faEye}/>
                                </IconButton>
                            </Col>
                        </Row> 
                        || 
                        <Row>
                            <Box style={{float: 'left', marginTop: '4px'}}>
                                <Typography component="p" variant='large' style={{wordWrap: 'break-word'}}>No GPA found</Typography>
                            </Box>
                        </Row>
                    }
                </Col>
            </Row>
        </React.Fragment>
    }
}

export default GPADetails;