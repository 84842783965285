import {mobileTypes, countryCodes} from './FormOptions';
import {Typography, Grid, useMediaQuery} from '@mui/material';
import {FormControlledDropdown} from './FormDropdown';
import {FormControlledTextField} from './FormTextField';
import {useFormContext} from 'react-hook-form';
import {FieldRequiredMark} from './FieldRequiredMark';
import {FormControlledCheckbox} from './FormCheckbox';

export const FormControlledPhoneField = ({
    control,
    name,
    label,
    rules,
    required,
    showPhoneTypeInput = true,
    showPhoneCountryCodeInput = true,
    labelGridProps = {
        xs: 12,
        md: 3,
        lg: 4,
        xl: 3
    },
    inputContainerGridProps = {xs: 0, md: 9, lg: 8, xl: 9},
    phoneTypeInputGridProps = {
        xs: 12,
        md: 6,
        lg: 6,
        xl: 3
    },
    phoneCountryCodeInputGridProps = {xs: 12, md: 6, lg: 6, xl: 4},
    phoneNumberInputGridProps = {xs: 12, md: 12, lg: 12, xl: 5},
    sameAsOther
}) => {
    const isSm = useMediaQuery(theme => theme.breakpoints.only('sm'));
    const isMd = useMediaQuery(theme => theme.breakpoints.only('md'));
    const isLg = useMediaQuery(theme => theme.breakpoints.only('lg'));

    const {
        formState: {errors},
        watch
    } = useFormContext();

    const isUSPhone = watch(`${name}.countryCode`) === 1 ? true : false;

    const hasError =
        _.get(errors, `${name}.voiceOnly`) ||
        _.get(errors, `${name}.countryCode`) ||
        _.get(errors, `${name}.number`);

    return (
        <Grid container spacing={1} alignItems={!(isLg || isMd) && 'center'}>
            <Grid
                item
                {...labelGridProps}
                textAlign={{xs: 'start', md: 'end', lg: 'end'}}
            >
                <Typography className="myOdu__label" component="label">
                    {label} {required && <FieldRequiredMark label={label} />}
                </Typography>
                {!isSm && hasError && <p></p>}
            </Grid>

            {!sameAsOther?.value && (
                <Grid item container {...inputContainerGridProps} spacing={1}>
                    {showPhoneTypeInput && (
                        <Grid item {...phoneTypeInputGridProps}>
                            <FormControlledDropdown
                                name={`${name}.voiceOnly`}
                                control={control}
                                options={mobileTypes}
                                disabled={name === 'mobilePhone'}
                                rules={rules?.phoneTypeRule}
                                labelGridProps={{
                                    xs: 0,
                                    md: 12,
                                    lg: 4,
                                    xl: 3
                                }}
                                inputGridProps={{
                                    xs: 12,
                                    md: 12,
                                    lg: 12,
                                    xl: 12
                                }}
                            />
                        </Grid>
                    )}

                    {showPhoneCountryCodeInput && (
                        <Grid item {...phoneCountryCodeInputGridProps}>
                            <FormControlledDropdown
                                name={`${name}.countryCode`}
                                control={control}
                                options={countryCodes}
                                rules={rules?.phoneCountryCodeRule}
                                labelGridProps={{
                                    xs: 0,
                                    md: 12,
                                    lg: 4,
                                    xl: 3
                                }}
                                inputGridProps={{
                                    xs: 12,
                                    md: 12,
                                    lg: 12,
                                    xl: 12
                                }}
                            />
                        </Grid>
                    )}

                    <Grid item {...phoneNumberInputGridProps}>
                        <FormControlledTextField
                            name={`${name}.number`}
                            control={control}
                            rules={{
                                ...rules?.phoneNumberRule,

                                validate: {
                                    correctPhoneFormat: value => {
                                        let message;

                                        if (
                                            isUSPhone &&
                                            !/^(?!.*\b\d{3}555\d{4}\b)\d{10}$/.test(
                                                value
                                                    .toString()
                                                    .replace(/\D/g, '')
                                            )
                                        ) {
                                            message =
                                                'Enter valid US phone number';
                                        } else if (
                                            !isUSPhone &&
                                            !/^\d+$/.test(value)
                                        ) {
                                            message =
                                                'Enter valid phone number';
                                        }

                                        return message;
                                    }
                                }
                            }}
                            ariaLabel={`${label} number`}
                            mask={isUSPhone && '(999) 999-9999'}
                            labelGridProps={{
                                xs: 12,
                                md: 12,
                                lg: 4,
                                xl: 3
                            }}
                            inputGridProps={{
                                xs: 12,
                                md: 12,
                                lg: 12,
                                xl: 12
                            }}
                        />
                    </Grid>
                </Grid>
            )}

            {sameAsOther && (
                <>
                    {!sameAsOther.value && (
                        <Grid item {...sameAsOther.gridProps}></Grid>
                    )}
                    <Grid item>
                        <FormControlledCheckbox
                            name={sameAsOther.name}
                            label={sameAsOther.label}
                            inputGridProps={{xs: 12, md: 12, lg: 12, xl: 12}}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
};
