import { Box, Typography } from "@mui/material";
import React, {Component} from "react";
import { Col, Row } from "reactstrap";

class CreditDetails extends Component {
    state = {

    }

    render() {
        return <React.Fragment>
            <Row>
                <Col xs={3}>
                    <Box style={{float: 'right'}}>
                        <Typography component="h5" variant='h4'>{this.props.data}</Typography>
                    </Box>
                </Col>
                <Col xs={9}>
                    <Typography component="p" variant ="small">{this.props.text}</Typography>
                </Col>
            </Row>
        </React.Fragment>
    }
}

export default CreditDetails;