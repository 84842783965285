import React, { Component } from 'react';
import { Typography, Box } from '@mui/material';
import { Col, Row } from 'reactstrap';
import PortalModal from '../common/PortalModal';
import AddBookmark from './AddBookmark';
import EditBoomark from './EditBookmark';

class BookmarksManager extends Component {
    state = {  } 
    render() { 
        return <React.Fragment>
            <PortalModal isOpen={this.props.isOpen} onClose={this.props.onToggle} title={'myBookmarks'} isCloseable className="myOdu__myBookmarksManager">
                <Box className="addBookmark">
                    <Typography variant="h4" component="h3" className="py-3">Add Bookmark</Typography>
                    <AddBookmark onAdd={this.props.onAdd} />
                </Box>

                <Box className="editBookmarks">
                    <Typography variant="h4" component="h3" className="py-3">Edit Bookmarks</Typography>
                    <EditBoomark bookmarks={this.props.bookmarks} onEdit={this.props.onEdit} onDelete={this.props.onDelete}/>
                </Box>
            </PortalModal>
        </React.Fragment>
    }
}
 
export default BookmarksManager;