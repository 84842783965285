export const ResourceLinks = [
    {
        id: "resource_1",
        title: "Career Services",
        link: "https://www.odu.edu/career-services/",
    }, 
    {
        id: "resource_2",
        title: "Financial Aid",
        link: "https://www.odu.edu/finaidoffice/",
    }, 
    {
        id: "resource_3",
        title: "Graduate School",
        link: "https://www.odu.edu/admission/graduateTR/WCAG22/",
    }, 
    {
        id: "resource_4",
        title: "ODU Job Postings",
        link: "https://jobs.odu.edu/",
    }, 
    {
        id: "resource_5",
        title: "Prior Learning Assessments",
        link: "https://ww1.odu.edu/academics/academic-records/evaluation-of-credit/prior-learning/",
    },
    {
        id: "resource_6",
        title: "Student Opinion Surveys",
        link: "https://www.odu.edu/information-technology-services/student-opinion-survey/",
    },  
    {
        id: "resource_7",
        title: "SEES Student Resources",
        link: "https://www.odu.edu/sees/student-resources",
    },  
    {
        id: "resource_8",
        title: "Tuition Calculator",
        link: "https://blue.odu.edu/admissions/calculator/",
    }, 
    {
        id: "resource_9",
        title: "Tuition Payment",
        link: "Adobe https://ww1.odu.edu/tuition-aid/tuition/billing/payments/",
    },
];