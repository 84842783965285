import React, { Component } from 'react';


class PlaceMap extends Component {
    render() {
        
        return <>
            <iframe
                title="map"
                width="100%"
                height="300"
                style={{border:0}}
                loading="lazy"
                allowFullScreen
                referrerPolicy="no-referrer-when-downgrade"
                src={this.props.mapIframeEmbedUrl + "&key=AIzaSyBZJv2MJakMK3IYwu9bG7itwjAA3JPrhZ0&zoom=18&maptype=satellite&mapId=" + this.props.mapId}>
            </iframe>
        </>
    }
}
 
export default PlaceMap;
