import React, { Component } from 'react';
import Widget from '../common/Widget.js';
import { getDataPublic } from '../../DataAccessLayer';
import { announcements } from '../../DataAccessLayer/services';
import AnnouncementTabs from './AnnouncementTabs.js';
import { connect } from 'react-redux';
class Announcements extends Component {
    state = {
        isExpand: true,
        widgetId: 'widget__announcements',

        // menu:[            
        //     {id: "announcements_postAnnouncement", title: "Post Announcement"},
        //      // TODO: enable in v1.1
        //      // {id: "announcements_hide", title: "Hide"},
        // ],
        selectedTab: 0,
        universityAnnouncements: {},
        studentAnnouncements: {},
        tabIndex: 0
    }

    componentDidMount() {
        this.loadAnnouncements()
        const user = this.props.isImpersonating
            ? this.props.impersonation
            : this.props.user;
        if (user?.roles?.STUDENT)
            this.setState({ tabIndex: 0 })
        else
            this.setState({ tabIndex: 1 })
    }

    loadAnnouncements = () => {
        getDataPublic(announcements).then(announcements => {
            this.setState({
                studentAnnouncements: announcements.studentAnnouncements,
                universityAnnouncements: announcements.facultyStaffAnnouncements,
            });

        })
    }

    toggleExpand = () => {
        this.setState({ isExpand: !this.state.isExpand })
    }

    onTabChange = (event, tabIndex) => {
        this.setState({ tabIndex });
    };


    render() {

        return <>
            <Widget data={{ title: "Announcements" }} {...this.props}>
                <AnnouncementTabs tabIndex={this.state.tabIndex} onTabChange={this.onTabChange} universityAnnouncements={this.state.universityAnnouncements} studentAnnouncements={this.state.studentAnnouncements} isEvms={false} evmsAnnouncements={""} />
            </Widget>
        </>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.AWSReducer.user,
        user: state.impersonationReducer.impersonation
    }
}

export default connect(mapStateToProps)(Announcements);;