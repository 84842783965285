import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Typography } from '@mui/material';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { MeetingDateFormat, RecurringOptions } from './config';
import moment from 'moment';

class RepeatMeetings extends Component {

    state = {
    }

    //Θ(1) Sets value for selects
    onSelectChange = (event) => {
        let recurring = {...this.props.recurring};

        recurring[event.target.name] = event.target.value

        this.props.setRecurring(recurring);
    }

    //Θ(1) Sets date
    onDateChange = (value) => {
        let recurring = {...this.props.recurring};
        recurring.byDate = value;

        this.props.setRecurring(recurring)
    }

    //Θ(1) Sets checkbox value for days in week
    onWeekday = (event) => {
        let recurring = {...this.props.recurring};
        if(event.target.checked)
            recurring.weekdays[event.target.value] = event.target.checked;
        else
            delete recurring.weekdays[event.target.value];

        this.props.setRecurring(recurring)
    }

    //Θ(1) Sets byDay or byWeek in Month
    onMonthOccurs = (event) => {
        let recurring = {...this.props.recurring};
        recurring.monthOccursOn = event.target.value
        this.props.setRecurring(recurring)
    }



    render() {
        return <React.Fragment>
                {/* For each type */}
                <Row style={{padding: 4}}>
                    <Col>Repeats Every</Col>
                    <Col>
                        <FormControl fullWidth>
                            <InputLabel id="lbl-select-repeats-every">Repeats Every</InputLabel>
                            <Row>
                                <Col xs={10}>
                                    <Select
                                        labelId="lbl-select-repeats-every"
                                        id="select-repeats-every"
                                        label="Repeats Every"
                                        fullWidth
                                        onChange={this.onSelectChange}
                                        name={RecurringOptions.repeatsEvery}
                                        value={this.props.recurring[RecurringOptions.repeatsEvery]}
                                        >
                                        {
                                            Array(this.props.recurrence.recurrenceLength).fill(0).map((_, idx) => {
                                                return <MenuItem value={idx+1}>{(idx+1)}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </Col>
                                <Col xs={2}>
                                    <p>{this.props.recurrence.displayHelper}</p>
                                </Col>
                            </Row>
                        </FormControl>
                    </Col>
                </Row>

                {/* Weekly */}
                {
                    this.props.recurrence.value === RecurringOptions.weekly.value &&
                    <Row style={{padding: 4}}>
                        <Col>Occurs on</Col>
                        <Col>
                            <FormGroup row>
                                <FormControlLabel control={<Checkbox name={RecurringOptions.weekly.weekdays.sunday} value={RecurringOptions.weekly.weekdays.sunday.id} onChange={this.onWeekday}/>} label="Sunday" />
                                <FormControlLabel control={<Checkbox name={RecurringOptions.weekly.weekdays.monday} value={RecurringOptions.weekly.weekdays.monday.id} onChange={this.onWeekday}/>} label="Monday" />
                                <FormControlLabel control={<Checkbox name={RecurringOptions.weekly.weekdays.tuesday} value={RecurringOptions.weekly.weekdays.tuesday.id} onChange={this.onWeekday}/>} label="Tuesday" />
                                <FormControlLabel control={<Checkbox name={RecurringOptions.weekly.weekdays.wednesday} value={RecurringOptions.weekly.weekdays.wednesday.id} onChange={this.onWeekday}/>} label="Wednesday" />
                                <FormControlLabel control={<Checkbox name={RecurringOptions.weekly.weekdays.thursday} value={RecurringOptions.weekly.weekdays.thursday.id} onChange={this.onWeekday}/>} label="Thursday" />
                                <FormControlLabel control={<Checkbox name={RecurringOptions.weekly.weekdays.friday} value={RecurringOptions.weekly.weekdays.friday.id} onChange={this.onWeekday}/>} label="Friday" />
                                <FormControlLabel control={<Checkbox name={RecurringOptions.weekly.weekdays.saturday} value={RecurringOptions.weekly.weekdays.saturday.id} onChange={this.onWeekday}/>} label="Saturday" />
                            </FormGroup>
                        </Col>
                    </Row>
                }

                {/* Monthly */}
                {
                    this.props.recurrence.value === RecurringOptions.monthly.value &&
                    <Row style={{padding: 4}}>
                        <Col>Occurs on</Col>
                        <Col>
                            <Row>
                                <Col>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            name='radio-occurson-month'
                                            value={this.props.recurring.monthOccursOn}
                                            onChange={this.onMonthOccurs}
                                            >
                                            <Row>
                                                <Row>
                                                    <Col xs={2}>
                                                        <FormControlLabel value={RecurringOptions.monthly.occursOnDay} control={<Radio />} label="Day"></FormControlLabel>
                                                    </Col>
                                                    <Col>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="lbl-select-day-of-month" />
                                                            <Row>
                                                                <Col xs={10}>
                                                                    <Select
                                                                        labelId="lbl-select-day-of-month"
                                                                        id="select-day-of-month"
                                                                        label="Recurrence"
                                                                        fullWidth
                                                                        onChange={this.onSelectChange}
                                                                        name={RecurringOptions.monthly.monthlyDay}
                                                                        value={this.props.recurring[RecurringOptions.monthly.monthlyDay]}
                                                                        >
                                                                        {
                                                                            Array(this.props.recurrence.daysOfMonthLength).fill(0).map((_, idx) => {
                                                                                return <MenuItem value={idx+1}>{(idx+1)}</MenuItem>
                                                                            })
                                                                        }
                                                                    </Select>
                                                                </Col>
                                                                <Col xs={2}>
                                                                    <p>{this.props.recurrence.displayHelper}</p>
                                                                </Col>
                                                            </Row>
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={2}>
                                                        <FormControlLabel value={RecurringOptions.monthly.occursOnWeek} control={<Radio />} label=""></FormControlLabel>
                                                    </Col>
                                                    <Col>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="lbl-select-week-frequency" />
                                                            <Row>
                                                                <Col>
                                                                    <Select
                                                                        labelId="lbl-select-week-frequency"
                                                                        id="select-week-frequency"
                                                                        label="Frequency"
                                                                        fullWidth
                                                                        onChange={this.onSelectChange}
                                                                        name={RecurringOptions.monthly.monthlyWeek}
                                                                        value={this.props.recurring[RecurringOptions.monthly.monthlyWeek]}
                                                                        >
                                                                        {
                                                                            this.props.recurrence.weekFrequency.map((frequency, idx) => {
                                                                                return <MenuItem value={idx}>{frequency}</MenuItem>
                                                                            })
                                                                        }
                                                                    </Select>
                                                                </Col>
                                                            </Row>
                                                        </FormControl>
                                                    </Col>
                                                    <Col>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="lbl-select-month-weekday" />
                                                            <Row>
                                                                <Col>
                                                                    <Select
                                                                        labelId="lbl-select-month-weekday"
                                                                        id="select-month-weekday"
                                                                        label="Weekday"
                                                                        fullWidth
                                                                        onChange={this.onSelectChange}
                                                                        name={RecurringOptions.monthly.monthlyWeekday}
                                                                        value={this.props.recurring[RecurringOptions.monthly.monthlyWeekday]}
                                                                        >
                                                                        {
                                                                            this.props.recurrence.weekdays.map((weekday, idx) => {
                                                                                return <MenuItem value={idx}>{weekday}</MenuItem>
                                                                            })
                                                                        }
                                                                    </Select>
                                                                </Col>
                                                                <Col xs={3}>
                                                                    <p>of the month</p>
                                                                </Col>
                                                            </Row>
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                            </Row>
                                        </RadioGroup>
                                    </FormControl>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }

                {/* For each */}
                <Row>
                    <Col><FormLabel id="lbl-radio-enddate">End Date</FormLabel></Col>
                    <Col>
                        <Row>
                            <Col>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        name='endDate'
                                        value={this.props.recurring.endDate}
                                        onChange={this.props.onEndDateChanged}
                                        >
                                        <FormControlLabel value = {RecurringOptions.endDateBy} control={<Radio />} label="By">
                                        </FormControlLabel>
                                        <FormControlLabel value = {RecurringOptions.endDateAfter} control={<Radio />} label="After"></FormControlLabel>
                                    </RadioGroup>
                                </FormControl>
                            </Col>
                            <Col>
                                {
                                    this.props.recurring.endDate === RecurringOptions.endDateBy
                                    ? <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker defaultValue={this.props.recurring.byDate} onChange={this.onDateChange} />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                    : <FormControl fullWidth>
                                        <InputLabel id="lbl-select-occurence">Occurence</InputLabel>
                                        <Select
                                            labelId="lbl-select-occurence"
                                            id="select-occurence"
                                            label="Occurence"
                                            fullWidth
                                            name={RecurringOptions.occurence}
                                            value={this.props.recurring[RecurringOptions.occurence]}
                                            onChange={this.onSelectChange}
                                            >
                                            {
                                                Array(this.props.recurrence.occurenceLength).fill(0).map((_, idx) => {
                                                    return <MenuItem value={idx+1}>{(idx+1)}</MenuItem>
                                                })
                                            }
                                    </Select>
                                    </FormControl>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
        </React.Fragment>
    }
}

export default RepeatMeetings;
