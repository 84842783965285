import React from 'react';
import {Tooltip} from '@mui/material';

export const FieldRequiredMark = ({label}) => {
  return (
    <Tooltip
      title={`${label} is required`}
      enterDelay={750}
      leaveDelay={0}
      enterNextDelay={750}
    >
      <span style={{color: '#C23335', cursor: 'pointer'}}>*</span>
    </Tooltip>
  );
};
