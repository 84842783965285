import React, { Component } from 'react';
import { styled } from '@mui/material/styles';
import { Tooltip, ListItem, ListItemButton, ListItemText, ListItemIcon, Typography, Badge} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class MenuListItem extends Component {

    render() {
        return ( <React.Fragment>
            <ListItem disableGutters sx={{p:0, display: 'block'}}>
                <ListItemButton id={"sideNav__button_" + this.props.title.replace(' ', '')} onClick={this.props.onClick} sx={{justifyContent: "left"}}>
                    {!this.props.isOpen && <>
                        <Tooltip title={this.props.title} placement="right" arrow>
                            <ListItemIcon sx={{color: '#DADADA', minWidth: '1.75rem', pt:1, pb:1}} role="button">
                                <Badge variant={this.props.variant} badgeContent={this.props.badgeCount} color='error' max={10} className={"myOdu__iconBadge smallSideNav " + this.props?.variant}>
                                    <FontAwesomeIcon icon={this.props.icon} size={"lg"} fixedWidth />
                                </Badge>
                            </ListItemIcon>
                        </Tooltip>
                    </>}
                    {this.props.isOpen && <>
                        <ListItemIcon sx={{color: '#DADADA', minWidth: '1.75rem'}}>
                            <FontAwesomeIcon icon={this.props.icon} fixedWidth/>
                        </ListItemIcon> 
                        <Badge variant={this.props.variant} badgeContent={this.props.badgeCount} color='error' max={10} className={"myOdu__iconBadge " + this.props?.variant}>
                            <ListItemText disableTypography sx={{ opacity: this.props.isOpen ? 1 : 0 }} primary={<Typography sx={{ fontWeight: '600' }}>
                                {this.props.title}
                            </Typography>}/>
                        </Badge>
                    </>}
                </ListItemButton>
            </ListItem>
        </React.Fragment>);
    }
}

export default MenuListItem;



