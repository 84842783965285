import React, { Component } from 'react';
import Widget from '../common/Widget';
import { Link, Box, Grid, Stack, Typography  } from '@mui/material';
import { SafetyContacts } from './SafetyContacts';
import { safety } from '../../DataAccessLayer/services';
import { getData } from '../../DataAccessLayer';


class Safety extends Component {
    state = {
        isExpand: true,
        contacts: {}
    }

    toggleExpand = () => {
        this.setState({isExpand: !this.state.isExpand})
    }

    componentDidMount() {
        this.loadSafetyContacts()
    }

    loadSafetyContacts = () => {
        getData(safety).then(data => {
            if (data) {
                this.setState({contacts: data})
            }
        }).catch(err => console.log(err))
    }

    render() {
        
        return <>
            <Widget data={{title: "Safety", id:'widget__safety'}} {...this.props}>
            <Grid container spacing={2}>
                {this.state.contacts.titleContact && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{justifyContent: 'center'}} className="ms-xl-3 pt-0 ps-0">
                    <Box sx={{p:2, textAlign: "center", alignContent: 'center', alignItems: 'center', borderBottom: '1px solid #DEDEDE'}}>
                        <Typography component="h3" variant="h6">
                            {this.state.contacts.titleContact.title}
                        </Typography>
                        <Stack>
                        {this.state.contacts.titleContact.phoneNumber && <Link id={"safety__link_phone_" + this.state.contacts.titleContact.phoneNumber} href={`tel:${this.state.contacts.titleContact.phoneNumber}`} underline={"hover"} title={`Call ${this.state.contacts.titleContact.title}`}>{this.state.contacts.titleContact.phoneNumber}</Link>}
                            {this.state.contacts.titleContact.alternativeNumber && <span>Or <Link id={"safety__link_phone_" + this.state.contacts.titleContact.alternativeNumber} href={`tel:${this.state.contacts.titleContact.alternativeNumber}`} underline={"hover"} title={`Call ${this.state.contacts.titleContact.alternativeNumber}`}>{this.state.contacts.titleContact.alternativeNumber}</Link></span>}
                        </Stack>
                    </Box>
                </Grid>}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box className="myOdu__safetyList">
                        {this.state.contacts.contactsList?.length && this.state.contacts.contactsList.map(campus =>
                            <Box className="safetyContact" key={"safety_safetyContact_" + campus.id}>
                                <Typography component="h3" variant="p" sx={{fontWeight: 600}}>{campus.title}</Typography>
                                    {campus.contacts.map(contact =>
                                        <SafetyContacts data={contact} key={"safety_safetyContact_" + campus.id + "_" + contact.id }/>
                                    )}
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>

            </Widget>
        </>
    }
}
 
export default Safety;