import React, { Component } from "react";
import { Typography } from "@mui/material";
import WidgetCard from "./WidgetCard";
import { DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';

class Section extends Component {
    state ={ 

    }

    getStyle = (provided) => {
        let updatedProvided = provided;
        updatedProvided.style = {
            ...updatedProvided.style,
            cursor: this.props.isDroppable ? 'no-drop' : ''
        }
        return updatedProvided;
    }

    render() {
        //TODO Needs to be optimised
        const fixedWidgets = this.props.widgetsOrder?.map(widgetId => {
            let widget = this.props.widgets.find(widget => {
                if(widget.id === widgetId && widget.isNoDrag)
                    return widget;
            });
            if(widget) {
                return widget
            }
        }).filter(widget => widget !== undefined);

        const orderedWidgets = this.props.widgetsOrder?.map(widgetId => {
            let widget = this.props.widgets.find(widget => {
                if(widget.id === widgetId && !widget.isNoDrag)
                    return widget;
            });
            if(widget) {
                return widget
            }
        }).filter(widget => widget !== undefined);
        
        return <React.Fragment>
            {
                <div style={ this.props.isDragging ? (this.props.isDroppable ? {opacity: 0.5, cursor: 'no-drop'} : {borderTop: 'solid 2px', borderBottom: 'solid 2px'}) : {}}>
                    <div style={ Number(this.props.selectedSection) > 0 ? {opacity: 0.5, cursor: 'no-drop'} : {}}>
                        {
                            fixedWidgets.map(widget => {
                                return <WidgetCard widget = {widget} isDragging = {false} provided={{}} />
                            })
                        }
                    </div>
                    <DragDropContext onDragEnd={this.props.dragEnd} onDragStart={this.props.dragStart}>
                        <Droppable droppableId={this.props.sectionNumber.toString()}>
                            {
                                (provided) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {
                                        orderedWidgets.map((widget, idx) => {
                                            return (
                                                <Draggable key={widget.id} draggableId={widget.id} index={fixedWidgets.length + idx}>
                                                    {
                                                        (provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} key={widget.id + "_card"}>
                                                                <WidgetCard widget = {widget} isDragging = {this.props.selectedItem === widget.id} provided={this.getStyle(provided)}/>
                                                            </div>                                                
                                                        )
                                                    }
                                                </Draggable>
                                            )
                                        })
                                    }
                                    {provided.placeholder}
                                </div>
                            )
                            }
                        </Droppable>
                    </DragDropContext>
                </div>
            }
        </React.Fragment>
    }
}

export default Section;