import React, { Component } from 'react';
import { CssBaseline, ThemeProvider, Box, Card, Typography, Grid, Stack, Link } from '@mui/material';
import Apps from './config';
import { Button, CardActionArea, CardActions, Tooltip } from '@mui/material';
import { Col, Row, Container } from 'reactstrap';
import theme from '../../../theme';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PortalTooltip from '../../common/PortalTooltip';

class Featured extends Component {
    state = {
        iconPath: '/img/logos/',
        active: {},
        chunkSize: 4,
        tooltipOpen: "",
    }

    activate = (rowId, colId) => {
        this.setState({active: {[rowId]: colId}})
    }

    deactivate = (rowId) => {
        let active = this.state.active
        delete active[rowId]
        this.setState({active})
    } 

    // All 8 featured apps clickaways active all the time, clicking icon one is overwritten by the other seven to null
    handleTooltipClose = () =>{
        this.setState({tooltipOpen: null})
    }
    
    handleTooltipOpen = (id) =>{
        this.setState({tooltipOpen: id})
    }

    render() {
        // console.log('before render', this.state.tooltipOpen)
        const rows = Apps.reduce((chunks, app) => {
            if((chunks[chunks.length - 1]?.length ?? this.state.chunkSize) < this.state.chunkSize)
                chunks[chunks.length - 1].push(app)
            else
                chunks.push([app])
            return chunks
        }, [])
        return <React.Fragment>
            <ThemeProvider theme={theme}>
                <Container fluid>
                    
                    <Row className='my-3' style={{overflowX: 'hidden'}}>
                        <Col className="mb-4">
                            {rows.map((row, rowId) => {
                                return <Row key={rowId}>
                                    {row.map((app, colId, index) => {
                                        const isExpanded = false;
                                        const isCollapsed = false;
                                        // const isExpanded = this.state.active[rowId] == colId
                                        // const isCollapsed = (this.state.active[rowId] > -1) && !isExpanded
                                        return <Col xl={isExpanded ? 9 : isCollapsed ? 1 : 3} md={isExpanded ? 10 : isCollapsed ? 2 : 6} key={rowId + colId} className='p-2'>
                                            <Card className={isCollapsed ? 'pt-3 pb-3 pe-2 ps-2' : 'p-3'} sx={{height: '100%'}}>
                                                <div className={!isCollapsed ? "float-start me-2 mt-1" : "cursor-pointer"}>
                                                    {/* <Box {...(isCollapsed ? {onClick: () => this.activate(rowId, colId)} : '')} sx={{height: '40px', width: '40px', margin: '0 auto', p: "3px"}} title={isCollapsed ? "Click/Tap to get more info about " + app.name : ""} className={isCollapsed? 'appLogo' : ""}> */}
                                                    <Link href={app.link} target="_blank" underline="none">
                                                        <Box sx={{height: '40px', width: '40px', margin: '0 auto', p:'3px', pt:'0'}} title={"Open " + app.name + " in a new window."} className='myOdu__logoZoom'>
                                                            <img src={this.state.iconPath + app.icon} alt={app.icon} />
                                                        </Box>
                                                    </Link>
                                                </div>
                                                
                                                {!isCollapsed && <div>
                                                    <div>
                                                        <Typography component="h2" variant="h4" sx={{cursor: 'pointer'}} className="d-inline">
                                                            <Link href={app.link} underline="none" target="_blank" title={"Open " + app.name + " in new window"} className="myOdu__appCardTitleLink myOdu__funderline">
                                                                {app.name}
                                                            </Link>
                                                        </Typography>

                                                        <div className="float-end">
                                                            {isExpanded && <Button variant='' onClick={() => this.deactivate(rowId)} sx={{minWidth: 'unset', pr:2, pl:2}}><i className='fal fa-x'/><div class='sr-only visually-hidden'>Close</div></Button>}
                                                        </div>
                                                    </div>
                                                </div>
                                                }

                                                {!isCollapsed && <div>
                                                    {!isExpanded && 
                                                        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={() => {this.handleTooltipClose();}}>
                                                            <Tooltip title={app.desc} PopperProps={{disablePortal: true}}
                                                                open={this.state.tooltipOpen === app.id}
                                                                disableFocusListener
                                                                disableHoverListener
                                                                disableTouchListener
                                                                arrow
                                                            >
                                                                <FontAwesomeIcon icon={faCircleInfo} onClick={() => this.handleTooltipOpen(app.id)} className="float-end moreInfo fa-md mt-4 me-0 mb-0 ms-2 px-2 cursor-pointer"/>
                                                            </Tooltip>
                                                        </ClickAwayListener>
                                                    }
                                                    <PortalTooltip title={app.desc}>
                                                        <Typography  component="p" variant="smaller" className="myOdu__lineClamp-2" sx={{pt:1}}>
                                                            {app.desc} 
                                                        </Typography>
                                                    </PortalTooltip>
                                                </div>}
                                                {isCollapsed && <div>
                                                    <Typography component="h3" variant="smaller" sx={{pt:1, cursor: 'pointer'}} className="text-center">
                                                        <Link href={app.link} target="_blank" title={"Open " + app.name + " in new window"} className="myOdu__appCardTitleLink myOdu__funderline">{app.name}</Link>
                                                    </Typography>
                                                </div>}
                                            </Card>
                                        </Col>
                                    })}
                                </Row>
                            })}
                        </Col>
                    </Row>
                </Container>



                
            </ThemeProvider>
        </React.Fragment>
    }
}
 
export default Featured;