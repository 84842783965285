import React from "react";
import { useRouteError } from "react-router-dom";
import { faFaceRaisedEyebrow } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Typography, Button, Stack, Link} from "@mui/material"

const Unauthorized = () => {
    return <React.Fragment>
        <Stack alignItems={"center"}>
            <FontAwesomeIcon icon={faFaceRaisedEyebrow} fixedWidth size="9x"/>
            <Typography component="h2" className="myOdu__errorCode">401</Typography>
            <Typography component="h3" className="myOdu__errorCode">Unauthorized Access</Typography>
            <Typography component="p" className="myOdu__errorCode">You need permissions to view this page.</Typography>
            <Button className="myOdu__errorCodeButton" href="/" sx={{mt:3}} variant="outlined">
                Go to the home page
            </Button>
        </Stack>
    </React.Fragment>
}

export default Unauthorized