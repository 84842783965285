import { AppConfigs, AppVersionHistory, WidgetConfigs, WidgetVersionHistory } from "./config"


export const isSameWidgetsVersion = (widgets) => {
    if (widgets.Version === WidgetConfigs.Version)
        return true
    
    return false;
}

export const updateWidgetsOrder = (widgets) => {
    let versions = Object.keys(WidgetVersionHistory).filter(version => version > widgets.Version);
    let widgetsOrder = widgets.WidgetsOrder;

    versions.forEach(version => {
        //First check for deletions
        if(WidgetVersionHistory[version].Deleted.length) {
            WidgetVersionHistory[version].Deleted.forEach(widgetId => {
                let widget = WidgetConfigs.Widgets.find(widget => widget.id === widgetId);
                let idx = widgetsOrder[widget.section - 1].indexOf(widgetId);
                widgetsOrder[widget.section - 1].splice(idx, 1, widgetId);
            })
        }

        //Check for new widgets
        if(WidgetVersionHistory[version].Added.length) {
            WidgetVersionHistory[version].Added.forEach(widgetId => {
                let widget = WidgetConfigs.Widgets.find(widget => widget.id === widgetId)
                //Check if there is any fixed widghet in the lane
                let fixedWidgets = WidgetConfigs.Widgets.filter(wid => wid.section === widget.section && wid.isNoDrag)
                widgetsOrder[widget.section - 1].splice(fixedWidgets.length, 0, widgetId);
            })
        }
    });

    widgets.WidgetsOrder = widgetsOrder;
    widgets.Version = WidgetConfigs.Version;

    return widgets
}

export const isSameAppsVersion = (apps) => {
    if(apps.Version === AppConfigs.Version)
        return true;

    return false;
}

export const updateApps = (preferences, isImpersonating, impersonation, user) => {
    let versions = Object.keys(AppVersionHistory).filter(version => version > preferences.Version);
    versions.forEach(version => {
        //Check for Deletes
        if(AppVersionHistory[version].Deleted.length) {
            AppVersionHistory[version].Deleted.forEach(appId => {
                delete preferences.Apps[appId];
            })
        }

        if(AppVersionHistory[version].Added.length) {
            AppVersionHistory[version].Added.forEach(appId => {
                let app = AppConfigs.Apps.find(app => app.id === appId)
                preferences = addAppBasedOnRole(preferences, app, isImpersonating, impersonation, user)
            })
        }
    });

    preferences.Version = AppConfigs.Version;

    return preferences;
}

const addAppBasedOnRole = (preferences, app, isImpersonating, impersonation, user) => {
    if(!(app.id in (preferences?.Apps ?? {}))) {
        if(app.isDefaultSelected || app?.preSelected?.role?.some((item) => item in (isImpersonating ? impersonation?.roles ?? {} : user?.roles ?? {}))) {
            preferences['Apps'] = {...(preferences?.Apps ?? {}), [app.id]: true}
            return preferences;
        }
    }
    return preferences;
}