export const impersonation = 'impersonation';
export const myCourses = 'my/courses';
export const courses = 'courses';
export const tutoring = 'tutoring';
export const myTransferHistory = 'my/transferhistory';
export const statusAlerts = 'my/statusAlerts';
export const hideStatusAlerts = 'my/statusAlerts/hide';
export const unhideStatusAlerts = 'my/statusAlerts/unhide';
export const checklists = 'my/checklists';
export const checklistDetails = code => `my/checklists/${code}`;
export const safety = 'safety';
export const myPreferences = 'my/preferences';
export const myAdvisingTeam = 'my/advisingTeam';
export const placesAndHours = 'placesandhours';
export const mySchedule = 'my/schedule';
export const news = 'news';
export const myBookmarks = 'my/bookmarks';
export const cct = 'cct';
export const adhoc = 'cct/adhoc';
export const announcements = 'announcements';
export const systemStatus = 'systemStatus';
export const myContacts = 'my/contacts';
export const myInfo = 'my/info';
export const notifications = 'notifications';
export const dismissNotification = id => `notifications/hide/${id}`;
export const feedback = 'feedback';
export const myDining = 'my/dining';
export const locations = {
    locations: `locations`,
    getLocation: ({catergoryId, placeId}) =>
        `locations/${catergoryId}/${placeId}`,
    deleteLocation: ({catergoryId, placeId}) =>
        `locations/${catergoryId}/${placeId}`
};
export const terms = 'terms';
export const profilePhoto = 'my/profile/photo';
