import React from 'react';
import {Box, Typography, Stack, Grid, IconButton} from '@mui/material';
import {FormControlledTextField} from '../../common/Form/FormTextField';
import {FormButton} from '../../common/Form/FormButton';

import {useFieldArray} from 'react-hook-form';
import {faTrash} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {FormControlledTimePicker} from '../../common/Form/FormTimePicker';

export const LocationDetails = ({control, mode}) => {
    const {fields, append, remove} = useFieldArray({
        control,
        name: 'hours'
    });

    const handleAddHours = () => {
        append({
            title: '',
            times: {openTime: '', closeTime: ''}
        });
    };

    return (
        <>
            <Stack
                alignItems={'center'}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2
                }}
            >
                <Typography component="h3" variant="h3">
                    {mode} Location
                </Typography>
                <FormControlledTextField
                    name="title"
                    control={control}
                    label="Location Title"
                    rules={{
                        required: 'Title is required'
                    }}
                    placeholder={'Writing Center'}
                />
                <FormControlledTextField
                    name="url"
                    control={control}
                    label="Location URL"
                    placeholder={
                        'https://www.odu.edu/al/centers/writing-center'
                    }
                />
                <FormControlledTextField
                    name="mapUrl"
                    control={control}
                    label="Location Map URL"
                    placeholder="https://www.google.com/maps/place"
                />
                <FormControlledTextField
                    name="mapIframeEmbedUrl"
                    control={control}
                    label="Location Iframe URL"
                    placeholder="https://www.google.com/maps/embed?pb="
                />
                <FormControlledTextField
                    name="address"
                    control={control}
                    label="Location Address"
                    placeholder={
                        'Perry Library, 4427 Hampton Blvd, Room 1208, Norfolk, VA 23529'
                    }
                />

                {/* <FormButton label="Add Hours" onClick={handleAddHours} /> */}
            </Stack>

            {/* Remove for future design and use
            
            {fields.map((field, index) => {
                return (
                    <Box className="myOdu__box" key={field.id} sx={{p: 3}}>
                        <Grid container>
                            <Grid item xs={4}>
                                <FormControlledTextField
                                    name={`hours.${index}.title`}
                                    control={control}
                                    label={'Days'}
                                    placeholder={'Monday or Monday - Friday'}
                                    rules={{
                                        required: 'Days range is required',
                                        pattern: {
                                            value: /^(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday)( - (Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday))?$/,
                                            message: 'Invalid day range'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item xs={3.5}>
                                <FormControlledTimePicker
                                    label={'From'}
                                    name={`hours.${index}.times.openTime`}
                                    control={control}
                                    rules={{
                                        required: 'Close time is required'
                                    }}
                                />
                            </Grid>

                            <Grid item xs={3.5}>
                                <FormControlledTimePicker
                                    label={'To'}
                                    name={`hours.${index}.times.closeTime`}
                                    control={control}
                                    rules={{
                                        required: 'Close time is required'
                                    }}
                                />
                            </Grid>

                            <Grid item>
                                <IconButton onClick={() => remove(index)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                );
            })} */}
        </>
    );
};
