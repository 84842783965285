import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Typography, Breadcrumbs, Link} from '@mui/material';
import AlertCategory from './AlertCategory';
import HiddenAlerts from './HiddenAlerts';
import {
  hideStatusAlerts,
  statusAlerts,
  unhideStatusAlerts
} from '../../DataAccessLayer/services';
import {saveStatusAlerts} from './actions';
import {getData, postData} from '../../DataAccessLayer';
import {
  ACTION_HIDE,
  ACTION_UNHIDE,
  ALERT_KEY_BALANCE,
  ALERT_KEY_FINANCIAL_AID,
  ALERT_KEY_HOLDS,
  ALERT_KEY_UPCOMING_HOLDS
} from '../common/constants';
import {Container} from 'reactstrap';
import Loader from '../common/Loader';
import {toast} from 'react-toastify';

class StatusAlerts extends Component {
  state = {
    isLoading: false,
    isShowHiddenAlerts: false,
    isHideAlertInProgress: false,
    isUnhideAlertInProgress: false
  };

  componentDidMount() {
    if (!this.props.statusAlerts) {
      this.toggleIsLoading(true);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.statusAlerts !== this.props.statusAlerts &&
      this.props.statusAlerts
    ) {
      this.toggleIsLoading(false);
    }
  }

  toggleIsLoading = isLoading => {
    this.setState({isLoading: isLoading});
  };

  toggleIsShowHiddenAlerts = () => {
    this.setState({
      isShowHiddenAlerts: !this.state.isShowHiddenAlerts
    });
  };

  toggleIsHideAlertInProgress = isHide => {
    this.setState({
      isHideAlertInProgress: isHide
    });
  };

  toggleIsUnhideAlertInProgress = isUnhide => {
    this.setState({
      isUnhideAlertInProgress: isUnhide
    });
  };

  toggleAlertVisibility = (action, data) => {
    switch (action) {
      case ACTION_UNHIDE:
        this.unhideAlert(data);
        break;
      case ACTION_HIDE:
        this.hideAlert(data);
        break;
    }
  };

  /**
   * Hides the alert
   * @param {Object} alert The alert to be hidden
   */
  hideAlert = async alert => {
    this.toggleIsHideAlertInProgress(true);
    postData(hideStatusAlerts, alert, true)
      .then(result => {
        this.loadStatusAlerts();
        this.toggleIsHideAlertInProgress(false);
        toast.success('Financial Aid alert hidden');
      })
      .catch(err => {
        console.log(err);
        this.toggleIsHideAlertInProgress(false);
        toast.error('Could not hide alert. Try again later.');
      });
  };

  /**
   * Unhides the alert
   * @param {Object} alert The alert to be unhidden
   */
  unhideAlert = async alert => {
    this.toggleIsUnhideAlertInProgress(true);
    postData(unhideStatusAlerts, alert, true)
      .then(result => {
        this.loadStatusAlerts();
        this.toggleIsUnhideAlertInProgress(false);
        toast.success('Financial Aid alert unhidden');
      })
      .catch(err => {
        console.log(err);
        this.toggleIsUnhideAlertInProgress(false);
        toast.error('Could not unhide alert. Try again later.');
      });
  };

  /**
   * Loads the status alerts
   */
  loadStatusAlerts = () => {
    this.toggleIsLoading(true);
    getData(statusAlerts).then(statusAlerts => {
      this.toggleIsLoading(false);
      this.props.saveStatusAlerts(statusAlerts);
    });
  };

  render() {
    return (
      <>
        <Loader
          isOpen={
            this.state.isHideAlertInProgress ||
            this.state.isUnhideAlertInProgress
          }
        />
        {this.state.isShowHiddenAlerts && (
          <HiddenAlerts
            hiddenAlerts={this.props.statusAlerts?.hiddenFinancialAid}
            toggleIsShowHiddenAlerts={this.toggleIsShowHiddenAlerts}
            toggleAlertVisibility={this.toggleAlertVisibility}
            isLoading={this.state.isLoading}
          />
        )}
        <Container className="myOdu__container_maxWidth" style={{marginTop: '2rem'}}>
          <Typography sx={{color: 'primary.main'}} component="h2" variant="h3">
            Status Alerts
          </Typography>
          <Breadcrumbs aria-label="breadcrumb" sx={{mb: 2}}>
            <Link
              underline="hover"
              color="inherit"
              href="/"
              sx={{cursor: 'pointer'}}
            >
              Dashboard
            </Link>
            <Typography color="text.primary">Status Alerts</Typography>
          </Breadcrumbs>

          <AlertCategory
            data={this.props.statusAlerts?.holds ?? []}
            title="Current Holds"
            alertKey={ALERT_KEY_HOLDS}
            isLoading={this.state.isLoading}
          />

          <AlertCategory
            data={this.props.statusAlerts?.upcomingHolds ?? []}
            title="Upcoming Holds"
            alertKey={ALERT_KEY_UPCOMING_HOLDS}
            isLoading={this.state.isLoading}
          />

          <AlertCategory
            data={this.props.statusAlerts?.balance ?? []}
            title="Account Balance"
            alertKey={ALERT_KEY_BALANCE}
            isLoading={this.state.isLoading}
          />

          <AlertCategory
            data={this.props.statusAlerts?.financialAid ?? []}
            title="Financial Aid"
            alertKey={ALERT_KEY_FINANCIAL_AID}
            isLoading={this.state.isLoading}
            hiddenFinancialAidLength={
              this.props.statusAlerts?.hiddenFinancialAid?.length
            }
            action={ACTION_HIDE}
            toggleIsShowHiddenAlerts={this.toggleIsShowHiddenAlerts}
            toggleAlertVisibility={this.toggleAlertVisibility}
          />
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    statusAlerts: state.myStatusAlertsReducer.statusAlerts
  };
};

const mapDispatchToProps = dispatch => ({
  saveStatusAlerts: statusAlerts => dispatch(saveStatusAlerts(statusAlerts))
});

export default connect(mapStateToProps, mapDispatchToProps)(StatusAlerts);
