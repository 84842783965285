import React, { Component } from 'react';
import { Box, List, Link, Typography, Tooltip } from '@mui/material/';
import NewsItem from './NewsItem';

class UniversityNews extends Component {

  render() {
    return <>
      <Box sx={{ width: '100%', textAlign: 'right', p: 0 }}>        
        <Tooltip title="Opens in new window" enterDelay={750} leaveDelay={0} enterNextDelay={750} arrow>
          <Link id="myNews__link_universityNews" href="http://www.odu.edu/news" target="_blank" underline='hover'>University News</Link>
        </Tooltip>
      </Box>

      <List sx={{ py: 0 }} dense={true}>
        {this.props.news.length ? this.props.news.map((newsItem, index) =>
          <NewsItem data={newsItem} index={index} widgetId={this.props.widgetId} key={"universityNews_" + index} />
        ) : <Typography component='p' variant='small'>No news found</Typography>}
      </List>
    </>
  }
}

export default UniversityNews;