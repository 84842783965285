import React, { Component } from 'react';
import { Box, Link, Stack, Typography, ListItem} from '@mui/material';
import DateBox from '../common/DateBox.js';
import PortalTooltip from '../common/PortalTooltip.js';

class NewsItem extends Component {

    getDescription = (description, link) => {
        if (description) {
            return <Typography variant="smaller" className="myOdu__lineClamp-2">{description}</Typography>;
        } else {
            return <Typography variant="smaller" className="myOdu__lineClamp-2">For more information see the <a href={link} target='_blank'>ODU news</a> webpage</Typography>;
        }
    }
    
    render() {
        return <>
            {this.props.data.description && <ListItem sx={{ p: 1 }} key={this.props.widgetId + "_ul_li_" + this.props.index} divider>
                <Stack direction="row">
                    <DateBox date={this.props.data.date} />
                    <Box>
                        <Stack>
                            {this.props.data.title && 
                            <PortalTooltip title="Opens in new window">
                                <Link id={"myNews__link_newsItem_" + this.props.data.title.replace(" ", "")} href={this.props.data.url} target="_blank" underline="hover" className="myOdu__lineClamp-1">
                                    <Typography component="h3" variant="p" sx={{ fontWeight: 600 }}>{this.props.data.title}</Typography>
                                </Link>
                            </PortalTooltip>
                            }
                            {this.getDescription(this.props.data.description, this.props.data.url)}
                        </Stack>
                    </Box>
                </Stack>
            </ListItem>}
        </>
    }
}

export default NewsItem;
