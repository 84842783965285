import {faTrash} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from '@mui/material';
import {GridActionsCellItem} from '@mui/x-data-grid';
import {faLocationDot} from '@fortawesome/pro-solid-svg-icons';

const getColumns = actionFunction => {
    return [
        {field: 'TITLE', headerName: 'Title', flex: 1},
        {
            field: 'locations',
            headerName: 'Location',
            flex: 2,
            renderCell: params => {
                return (
                    <>
                        {params.row.URL && (
                            <Link mr={1} target="_blank" href={params.row.URL}>
                                {params.row.URL}
                            </Link>
                        )}
                        {params.row.MAP_URL && (
                            <Link target="_blank" href={params.row.MAP_URL}>
                                <FontAwesomeIcon
                                    fixedWidth
                                    size="lg"
                                    icon={faLocationDot}
                                />
                            </Link>
                        )}
                    </>
                );
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 0.5,
            type: 'actions',
            getActions: event => [
                <GridActionsCellItem
                    icon={<FontAwesomeIcon icon={faTrash} />}
                    label="Delete"
                    onClick={() => actionFunction(event.row)}
                />
            ]
        }
    ];
};

export default getColumns;
