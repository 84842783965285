import React, {Component} from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    IconButton,
    Typography,
    Box,
    Menu,
    MenuItem
} from '@mui/material';
import {
    faChevronDown,
    faEllipsisVertical
} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import WidgetMenu from './WidgetMenu';

class Widget extends Component {
    state = {
        isExpand: true
    };

    toggleExpand = () => {
        this.setState({isExpand: !this.state.isExpand});
    };

    writeTitle = () => {

       if(this.props.data.isTitleStylized){
        return <Typography component="span" className="myOdu__stylizedText">
            {this.props?.data?.title?.map(word =>
                 <Typography component="span">{word}</Typography>
            )}
            </Typography>;
       }
       else return this.props.data.title;
    }

    render() {
        return (
            <React.Fragment>
                <Accordion
                    expanded={this.state.isExpand && !this.props.isDragging}
                    elevation={0}
                    className={'myOdu__widget ' + (this.props.className || '')}
                    id={this.props.data.id + '_accordion'}
                >
                    {/* To make it library independent update provided props */}
                    <div {...this.props.provided?.dragHandleProps}>
                        <AccordionSummary
                            expandIcon={
                                <FontAwesomeIcon
                                    icon={faChevronDown}
                                    size="lg"
                                    onClick={() => this.toggleExpand()}
                                    fixedWidth
                                />
                            }
                            id={this.props.data.id + '_accordionSummary'}
                            aria-controls={
                                this.props.data.id + '_accordionDetails'
                            }
                            sx={{flexDirection: 'row-reverse', flexGrow: 1}}
                        >
                            <Typography
                                component="h2"
                                variant="h4"
                                sx={{color: '#003057', ml: 1}}
                                onClick={() => this.toggleExpand()}
                                className="myOdu__widget_title"
                            >
                                {this.writeTitle()}
                            </Typography>
                            <Box
                                sx={{flexGrow: 1}}
                                onClick={() => this.toggleExpand()}
                            />
                            {this.props.data.menu && (
                                <>
                                    <WidgetMenu
                                        data={{
                                            title: this.props.data.title,
                                            id: this.props.data.id
                                        }}
                                        menu={this.props.data.menu}
                                    />
                                </>
                            )}
                        </AccordionSummary>
                    </div>
                    <AccordionDetails
                        id={this.props.data.id + '_accordionDetails'}
                        sx={{pt: 0}}
                    >
                        {this.props.children}
                    </AccordionDetails>
                </Accordion>
            </React.Fragment>
        );
    }
}

export default Widget;
