import '../../App.css';
import React, { Component, useEffect, useState } from 'react';
import { Box, Tooltip, IconButton, Menu, MenuItem, ListItemIcon } from '@mui/material';
import { faArrowRightToBracket, faLaptopCode, faIdCard, faArrowRightFromBracket, faArrowRotateLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { identifyUser, logIn } from '../../AWS';
import { savePreferences } from '../SetUp/actions';
import { connect } from 'react-redux';
import { saveUser } from '../../AWS/actions';


class DevButton extends Component {
	state = {
		anchor: false
	}


	componentDidMount(){
		//this.identifyUser()
	}

	identifyUser = async () => {
		const user = await identifyUser()
		console.log("User:", user)
		this.props.saveUser(user)
	}

	resetSetup = () => {
		this.props.savePreferences({})
	}

	dropAnchor = (event) => {
		this.setState({anchor: event.currentTarget})
	}

	pullAnchor = () => {
		this.setState({anchor: false})
	}

  render(){
    const isLoggedIn = this.props.user?.firstName ? true : false
    return <React.Fragment>

			<Tooltip title="Do Developer Stuff" arrow>
				<IconButton id="topNav__button_developer" size="" edge="end"  color="inherit" sx={{p:3, mr:0}} aria-label="developer menu" aria-haspopup="true" onClick={this.dropAnchor} className="myOdu__iconButton">
					<FontAwesomeIcon icon={faLaptopCode} fixedWidth size="sm"/>
				</IconButton>
			</Tooltip>

			<Menu anchorEl={this.state.anchor} disableScrollLock={true} id="developer-menu" open={this.state.anchor} onClose={this.pullAnchor} onClick={this.pullAnchor} transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} >
				
				{/* hide if not landing */}
				<MenuItem onClick={() => {logIn(); this.pullAnchor();}}>
					<ListItemIcon>
						<FontAwesomeIcon icon={faArrowRightToBracket} size="lg" fixedWidth/>
					</ListItemIcon>
					Log In
				</MenuItem>
				
				<MenuItem onClick={() => {this.identifyUser(); this.pullAnchor()}}>
					<ListItemIcon>
						<FontAwesomeIcon icon={faIdCard} size="lg" fixedWidth/>
					</ListItemIcon>
					Identify User
				</MenuItem>
			</Menu>

    </React.Fragment>
  }
}

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(saveUser(user)),
  savePreferences: (preferences) => dispatch(savePreferences(preferences))
});

export default connect(null, mapDispatchToProps)(DevButton);