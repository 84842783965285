import './App.css';
import React, {Component, useEffect, useState} from 'react';
import {CssBaseline, ThemeProvider, Box, IconButton} from '@mui/material';
import theme from './theme';
import {identifyUser, logIn, logOut, saveImpersonationDataset} from './AWS';
import {connect, useDispatch, useSelector} from 'react-redux';
import {saveUser} from './AWS/actions';
import {savePreferences} from './components/SetUp/actions';
import Layout from './components/Layout';
import Landing from './components/Landing';
import SetUp, {storePreferences} from './components/SetUp';
import ImpersonationBanner from './components/common/ImpersonationBanner';
import {getData, putData} from './DataAccessLayer';
import {myPreferences} from './DataAccessLayer/services';
import PortalSnackbarProvider from './components/common/PortalSnackbarProvider';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider
} from 'react-router-dom';
import AuthRedirect from './components/AuthRedirect';
import {
    compareWidgetsVersion,
    isSameAppsVersion,
    isSameWidgetsVersion,
    updateApps,
    updateWidgetsOrder
} from './components/SetUp/utils';
import GoogleAnalytics from './GoogleAnalytics';
import { saveImpersonation } from './components/Impersonation/actions';
import FeedbackButton from './components/common/FeedbackButton';
import { isProduction } from './components/Layout/utils';
import router from './components/Layout/Router';
import PortalPageLoader from './components/common/PortalPageLoader';

// import { AuthTestPanel } from './components/common/AuthTestPanel';

// specify global routes other than a default route here
// later in redner(), we specify <Landing/> for unauthed and <Layout/> for authed clients
const routerPublic = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/">
            <Route path="/login" element={<AuthRedirect />} />
        </Route>
    )
);

class App extends Component {
    state = {
        showAuthTestPanel: true,
        showImpersonationPanel: true,
        isLoading: true
    };

  componentDidMount(){
    this.identifyUser()
    .then(() => {
        this.loadUserPreferences();
    })
    this.checkImpersonation()
    GoogleAnalytics()
  }

    identifyUser = async () => {
        const user = await identifyUser();
        !isProduction() && console.log(user);
        this.props.saveUser(user);
        this.toggleLoading()
    };

    //Θ(1) Loads all user prederences from backend
    loadUserPreferences = () => {
        getData(
            myPreferences +
                '/' +
                (this.props.isImpersonating
                    ? this.props.impersonation.midas
                    : this.props.user.midas)
        )
            .then(result => {
                if (result && result.Widgets && result.Apps) {
                    if (!isSameWidgetsVersion(result?.Widgets)) {
                        let widgets = updateWidgetsOrder(result?.Widgets);
                        result.Widgets = widgets;
                        storePreferences(result, this.props);
                    }
                    if (!isSameAppsVersion(result?.Apps)) {
                        result.Apps = updateApps(
                            result?.Apps,
                            this.props.isImpersonating,
                            this.props.impersonation,
                            this.props.user
                        );
                        storePreferences(result, this.props);
                    }
                    this.props.savePreferences(result);
                } else this.props.savePreferences({});
            })
            .catch(err => {
                console.log(err);
                //TODO: Set to default preferences layout
            });
    };

  checkImpersonation = () => {
    if(!this.props.user?.firstName)
      this.props.saveImpersonation({})
  }

  toggleLoading = () => {
      this.setState({isLoading: !this.state.isLoading})
  }

  render(){
    const isLoggedIn = this.props.user?.firstName ? true : false
    return <React.Fragment>

      <RouterProvider router={routerPublic} />
    	<ThemeProvider theme={theme}>
			<CssBaseline />
            <FeedbackButton />
            <PortalSnackbarProvider />

            {
                this.state.isLoading ? <PortalPageLoader /> :
                <React.Fragment>
                    {!isLoggedIn && <Landing />}
                    {isLoggedIn && (
                        <>
                            {!Object.keys(this.props.preferences).length ? (
                                <SetUp />
                            ) : (
                                <>
                                    <RouterProvider router={router} />
                                    {/* <Layout /> */}
                                </>
                            )}
                        </>
                    )}
                </React.Fragment>
            }
            
        </ThemeProvider>
    </React.Fragment>
  }
}

const mapStateToProps = state => {
    return {
        isSideNav: state.headerReducer.isSideNav,
        user: state.AWSReducer.user,
        preferences: state.preferencesReducer.preferences,
        showAuthTestPanel: state.showAuthTestPanel,
        isImpersonating:state.impersonationReducer.impersonation?.isImpersonating ?? false,
        impersonation: state.impersonationReducer.impersonation
    };
};

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(saveUser(user)),
  savePreferences: (preferences) => dispatch(savePreferences(preferences)),
  saveImpersonation: (impersonation) => dispatch(saveImpersonation(impersonation))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
