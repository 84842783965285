import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Link, Menu, MenuItem } from "@mui/material";
import React, {Component} from "react";
import { ZoomTabMapping } from "../config";

class MeetingActions extends Component {
    state = {
        anchor: null,
        isOpen: false,
    }

    //Θ(1) open anchor
    dropAnchor = (e) => {
        this.setState({anchor: e.currentTarget, isOpen: true})
    }
    
    //Θ(1) Close anchor 
    pullAnchor = () => {
        this.setState({anchor: null, isOpen: false})
    }

    render() {
        return <React.Fragment>
            <div>
                <Button variant="outlined" endIcon={<FontAwesomeIcon icon={faChevronRight}/>} onClick={this.dropAnchor}>
                    Actions
                </Button>
                <Menu
                    anchorEl={this.state.anchor}
                    open={this.state.isOpen}
                    onClose={this.pullAnchor}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    {this.props.startUrl && this.props.activeTab === ZoomTabMapping.currentMeeting && <MenuItem><Link target = "_blank" href={this.props.startUrl} underline="none">Start Meeting</Link></MenuItem>}
                    <MenuItem>Delete</MenuItem>
                </Menu>
            </div>
        </React.Fragment>
    }
}

export default MeetingActions;