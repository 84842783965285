export const AppConfigs = {
    Version: 1.0,
    Apps: [
        {
            id: 'apply',
            name: 'Apply to ODU',
            icon: 'apply.svg',
            desc: 'New students can apply for admission to ODU here.',
            required: [{role: 'FACULTY'}, {role: 'STAFF'}],
            link: "https://www.odu.edu/apply",
            linkTarget: "_blank"
        },
        {
            id: 'archibus',
            name: 'Archibus',
            icon: 'archibus.svg',
            desc: 'Submit campus maintenance requests and view ticket status.\n',
            required: [{}],
            preSelected: {isHousing: true},
            link: 'https://odu.nmrkgcs.com/archibus/workplace',
            linkTarget: "_blank"},
        {
            id: 'banner',
            name: 'Banner',
            icon: 'banner.svg',
            desc: 'Banner is the university\'s centralized academic and administrative records system.',
            required: [{role: 'FACULTY'}, {role: 'STAFF'}],
            link: "https://bannerxe.odu.edu:7005/applicationNavigator",
            linkTarget: "_blank",
            preSelected: {role: ['FACULTY', 'STAFF']}
        },
        {
            id: 'billPay',
            name: 'Bill Payment',
            icon: 'billPay.svg',
            desc: 'Use this service to pay tuition and other ODU bills.',
            required: [{role: 'STUDENT'}],
            link: "https://www.odu.edu/quickfix/pay-tuition-students",
            linkTarget: "_blank",
            preSelected: {role: ['STUDENT']}
        },
        {
            id: 'canvas',
            name: 'Canvas',
            icon: 'canvas.svg',
            desc: 'Canvas is ODU\'s web-based learning management system used by instructors and students to access and manage online course learning materials.',
            required: [{role: 'STUDENT'}, {role: 'INSTFAC'}],
            preSelected: {role: ['INSTFAC', 'STUDENT']},
            link: 'https://canvas.odu.edu/',
            linkTarget: "_blank"
        },
        {
            id: 'chromeRiver',
            name: 'Chrome River',
            icon: 'chromeRiver.svg',
            desc: 'Chrome River is ODU\'s travel and expense management tool, required for employee reimbursements.',
            required: [{role: 'FACULTY'}, {role: 'STAFF'}],
            preSelected: {role: ['FACULTY', 'STAFF']},
            link: 'https://app.chromeriver.com/index',
            linkTarget: "_blank"
        },
        {
            id: 'courseSearch',
            name: 'Course Search',
            icon: 'courseSearch.svg',
            desc: 'This is a tool to search the university course catalog by keyword.',
            required: [{role: 'STUDENT'}],
            preSelected: {role: ['STUDENT']},
            link: 'https://catalog.odu.edu/course-search/',
            linkTarget: "_blank"
        },
        {
            id: 'degreeWorks',
            name: 'Degree Works',
            icon: 'degreeWorks.svg',
            desc: 'The Degree Works degree evaluation tool is designed to assist advisors and students with course planning, measuring progress toward program completion, and curriculum advising.',
            required: [{role: 'STUDENT'}],
            preSelected: {role: ['STUDENT']},
            link: 'https://degree.odu.edu/',
            linkTarget: "_blank"
        },
        {
            id: 'deviceRegistration',
            name: 'Device Registration',
            icon: 'deviceRegistration.svg',
            desc: 'Register your game consoles and media devices here for DeviceODU Wi-Fi access.',
            required: [{}],
            preSelected: {isHousing: true},
            link: 'https://device.odu.edu/',
            linkTarget: "_blank"
        },
        {
            id: 'duo',
            name: 'Duo',
            icon: 'duo.svg',
            desc: 'Duo is software that allows all users to perform "2-factor" authentication when they login.',
            required: [{}],
            isDefaultSelected: true,
            link: 'https://www.odu.edu/information-technology-services/two-factor-authentication',
            linkTarget: "_blank"
        },
        {
            id: 'eva',
            name: 'eVA',
            icon: 'eva.svg',
            desc: 'some desc goes here in a somewhat long sentence like this one',
            required: [{role: 'FACULTY'}, {role: 'STAFF'}],
            preSelected: {role: ['FACULTY', 'STAFF']},
            link: 'https://eva.virginia.gov/',
            linkTarget: "_blank"
        },
        {
            id: 'gmail',
            name: 'Gmail',
            icon: 'gmail.svg',
            desc: 'Google Workspace for Education - Productivity and collaboration tools from Google.',
            required: [{}],
            isDefaultSelected: true,
            link: 'https://mail.google.com/a/odu.edu',
            linkTarget: "_blank"
        },
        {
            id: 'googleDrive',
            name: 'Google Drive',
            icon: 'googleDrive.svg',
            desc: 'Google Workspace for Education - Productivity and collaboration tools from Google.',
            required: [{}],
            isDefaultSelected: true,
            link: 'https://drive.google.com/a/odu.edu',
            linkTarget: "_blank"
        },
        {
            id: 'handshake',
            name: 'Handshake',
            icon: 'handshake.svg',
            desc: 'Handshake is an online recruiting platform for higher education students and alumni. They partner with universities and employers to streamline and simplify the recruiting process.',
            required: [{}],
            isDefaultSelected: true,
            link: 'https://odu.joinhandshake.com/',
            linkTarget: "_blank"
        },
        {
            id: 'itsHelpDesk',
            name: 'IT Help Desk',
            icon: 'itsHelpDesk.svg',
            desc: 'The IT help desk offers in-person, phone, and email support for IT problems troubling students, faculty, and staff.',
            required: [{}],
            isDefaultSelected: true,
            link: 'https://www.odu.edu/ts/helpdesk',
            linkTarget: "_blank"
        },
        {
            id: 'kaltura',
            name: 'Kaltura',
            icon: 'kaltura.svg',
            desc: 'Kaltura is a cloud-based video management system for storing, publishing and streaming videos, video collections and other media.',
            required: [{}],
            isDefaultSelected: true,
            link: 'https://odumedia.mediaspace.kaltura.com/',
            linkTarget: "_blank"
        },
        {
            id: 'kuali',
            name: 'Kuali',
            icon: 'kuali.svg',
            desc: 'Build combines advanced form automation software and sophisticated workflow automation software into one platform. Create powerful digital forms and automated approvals.',
            required: [{}],
            preSelected: {role: ['FACULTY', 'STAFF']},
            link: 'https://odu.kualibuild.com/',
            linkTarget: "_blank"
        },
        {
            id: 'largeFileTransfer',
            name: 'Large File Transfer',
            icon: 'largeFileTransfer.svg',
            desc: 'ZendTo is the safe, secure and completely free way to transfer large files around the Web.',
            required: [{}],
            isDefaultSelected: true,
            link: 'https://lfs.odu.edu/',
            linkTarget: "_blank"
        },
        {
            id: 'laundry',
            name: 'Laundry',
            icon: 'laundry.svg',
            desc: 'The Laundry app lets users see how many washers and dryers are available around campus, and even place machines on hold.',
            required: [{}],
            preSelected: {isHousing: true},
            link: 'https://cardcenter.odu.edu/student/laundry/welcome.php',
            linkTarget: "_blank"
        },
        {
            id: 'leoOnline',
            name: 'LEO Online',
            icon: 'leoOnline.svg',
            desc: 'Leo Online is a secure, web-based self-service tool designed to allow students, faculty, and staff access to the tools and information required for doing business with the University, including registration, grades, and access to records associated with academic progress and employment.',
            required: [{}],
            isDefaultSelected: true,
            link: 'https://leoonline.odu.edu',
            linkTarget: "_blank"
        },
        {
            id: 'lime',
            name: 'Lime',
            icon: 'lime.svg',
            desc: 'Lime E-Scooters and bicycles are dockless electric mobility share vehicles in a program piloted by the City of Norfolk.',
            required: [{}],
            preSelected: {campus: ['main']},
            link: 'https://www.odu.edu/transportation-parking-services/alternative-transportation-programs/scooters',
            linkTarget: "_blank"
        },
        {
            id: 'liveSafe',
            name: 'Live Safe',
            icon: 'livesafe.svg',
            desc: 'LiveSafe enables direct and discreet two-way communication with your community safety officials using text, picture, video, and audio. It also lets you virtually walk your friends and family home with SafeWalk.',
            required: [{campus: 'main'}],
            preSelected: {campus: ['main'], isHousing: true},
            link: 'http://www.odu.edu/life/health-safety/safety/livesafe',
            linkTarget: "_blank"
        },
        {
            id: 'midas',
            name: 'MIDAS',
            icon: 'midas.svg',
            desc: 'MIDAS is a website for password resets, identity management, and provisioning services for your account.',
            required: [{role: 'STUDENT'}],
            isDefaultSelected: true,
            link: 'http://midas.odu.edu/',
            linkTarget: "_blank"
        },
        {
            id: 'monarchGroups',
            name: 'Monarch Groups',
            icon: 'monarchGroups.svg',
            desc: 'Monarch Groups is the ODU hub on campus groups, where people can connect over events or similar interests.',
            required: [{role: 'STUDENT'}],
            preSelected: {role: ['STUDENT']},
            link: 'https://odu.campusgroups.com',
            linkTarget: "_blank"
        },
        {
            id: 'monarchMovies',
            name: 'Monarch Movies',
            icon: 'monarchMovies.svg',
            desc: 'A free movie-streaming service for anyone physically at ODU.',
            required: [{role: 'STUDENT'}],
            preSelected: {isHousing: true},
            link: 'https://monarchmovies.odu.edu/#/browse',
            linkTarget: "_blank"
        },
        {
            id: 'monarchPrint',
            name: 'Monarch Print',
            icon: 'monarchPrint.svg',
            desc: 'Upload or email your document(s) to the MonarchPrint cloud and pick up your job from any campus printer.',
            required: [{role: 'STUDENT'}],
            isDefaultSelected: true,
            link: 'https://monarchprint.odu.edu',
            linkTarget: "_blank"
        },
        {
            id: 'monarchWellnessPortal',
            name: 'Wellness Portal',
            icon: 'monarchWellnessPortal.svg',
            desc: 'Visit the Monarch Wellness Portal to make healthcare appointments, manage immunization records, etc.',
            required: [{}],
            isDefaultSelected: true,
            link: 'https://odu.medicatconnect.com',
            linkTarget: "_blank"
        },
        {
            id: 'move',
            name: 'MOVE',
            icon: 'move.svg',
            desc: 'MOVE is ODU\'s virtual desktop environment which gives users a computer lab experience from anywhere.',
            required: [{}],
            isDefaultSelected: true,
            link: 'http://move.odu.edu/',
            linkTarget: "_blank"
        },
        {
            id: 'oduJobs',
            name: 'ODU Jobs',
            icon: 'oduJobs.svg',
            desc: 'ODU Jobs lets you browse and apply for open faculty and staff positions at ODU.',
            required: [{}],
            isDefaultSelected: true,
            link: 'https://jobs.odu.edu/',
            linkTarget: "_blank"
        },
        {
            id: 'oduMobile',
            name: 'ODU Mobile',
            icon: 'oduMobileIcon.svg',
            desc: 'ODUMobile is a free mobile application geared toward providing information on the go and allows users access to essential Old Dominion University information anytime on their mobile device.',
            required: [{}],
            isDefaultSelected: true,
            link: 'https://ww1.odu.edu/ts/software-services/odumobile',
            linkTarget: "_blank"
        },
        {
            id: 'office365',
            name: 'Office 365',
            icon: 'office.svg',
            desc: 'Office 365 is an office suite of products that include cloud collaboration tools and applications, provided for no cost, as long as users are a part of the University.',
            required: [{}],
            isDefaultSelected: true,
            link: 'http://office365.odu.edu/',
            linkTarget: "_blank"
        },
        {
            id: 'opinionSurveys',
            name: 'Opinion Surveys',
            icon: 'studentOpinionSurvey.svg',
            desc: 'Take anonymous surveys surveys to give us important feedback on your semester, which helps improve teaching and coursework.',
            required: [{role: 'STUDENT'}],
            preSelected: {role: ['STUDENT']},
            link: 'https://www.odu.edu/academics/courses-registration/studentopinionsurvey',
            linkTarget: "_blank"
        },
        {
            id: 'outlook',
            name: 'Outlook',
            icon: 'outlook.svg',
            desc: 'Old Dominion University uses Microsoft Outlook as the official e-mail system for distributing course-related communications, policies, announcements and other information.',
            required: [{}],
            isDefaultSelected: true,
            link: 'http://outlook.odu.edu/',
            linkTarget: "_blank"
        },
        {
            id: 'papers',
            name: 'Papers',
            icon: 'papers.svg',
            desc: 'PAPERS is ODU\'s online recruitment, position management and performance management system.',
            required: [{campus: 'main'}],
            preSelected: {campus: ['main'], role: ['FACULTY', 'STAFF']},
            link: 'https://jobs.odu.edu/hr',
            linkTarget: "_blank"
        },
        {
            id: 'parkingPortal',
            name: 'Parking Portal',
            icon: 'parkingPortal.svg',
            desc: 'ODU Parking Portal allows users to purchase virtual permits, update parking account information and pay outstanding citations.',
            required: [{campus: 'main'}],
            preSelected: {campus: ['main'], isHousing: true},
            link: 'https://odu.t2hosted.com/Account/Portal',
            linkTarget: "_blank"
        },
        {
            id: 'qualtrics',
            name: 'Qualtrics',
            icon: 'qualtrics.svg',
            desc: 'Qualtrics is web based software that allows the user to create surveys and generate reports without having any previous programming knowledge.',
            required: [{role: 'FACULTY'}, {role: 'STAFF'}],
            preSelected: {role: ['FACULTY', 'STAFF']},
            link: 'https://odu.qualtrics.com/',
            linkTarget: "_blank"
        },
        {
            id: 'quinnicia',
            name: 'Quinncia',
            icon: 'quinncia.svg',
            desc: 'Get résumé help and schedule a mock interview at Quinncia.',
            required: [{role: 'FACULTY'}, {role: 'STAFF'}],
            preSelected: {role: ['FACULTY', 'STAFF']},
            link: 'https://quinncia.io/access',
            linkTarget: "_blank"
        },
        {
            id: 'recWell',
            name: 'RecWell Portal',
            icon: 'recWell.svg',
            desc: 'The RecWell app is your one stop shop for all things ODU Recreation & Wellness.',
            required: [{role: 'FACULTY'}, {role: 'STAFF'}],
            isDefaultSelected: true,
            link: 'https://www.odu.edu/recreation-wellness/about/recwell-app',
            linkTarget: "_blank"
        },
        {
            id: 'retain',
            name: 'Retain',
            icon: 'retain.svg',
            desc: 'Retain is a web platform that streamlines the student experience and makes the staff supporting them more efficient in their work.',
            required: [{role: 'FACULTY'}, {role: 'STAFF'}],
            preSelected: {role: ['FACULTY', 'STAFF']},
            link: 'https://odu.retain.io/',
            linkTarget: "_blank"
        },
        {
            id: 'registration',
            name: 'Registration',
            icon: 'registration.svg',
            desc: 'Registration is the website for adding and dropping classes.',
            required: [{role: 'FACULTY'}, {role: 'STAFF'}],
            preSelected: {role: ['STUDENT']},
            link: 'https://xessb.odu.edu/StudentRegistrationSsb/ssb/registration',
            linkTarget: "_blank"
        },
        {
            id: 'safeRide',
            name: 'Safe Ride',
            icon: 'safeRide.svg',
            desc: 'Safe Ride is a free on-demand evening shuttle service for ODU students, faculty, and staff.',
            required: [{campus: 'main'}],
            preSelected: {campus: ['main'], isHousing: true},
            link: 'https://www.odu.edu/transportation-parking-services/transportation/safe-ride',
            linkTarget: "_blank"
        },
        {
            id: 'seesEventsDashboard',
            name: 'SEES Events Dashboard',
            icon: 'seesEventsDashboard.svg',
            desc: 'SEES swipe dashboard for SEES staff.',
            required: [{campus: 'main'}],
            preSelected: {campus: ['main']},
            link: 'https://analytics.odu.edu/analytics/bi/?pathRef=.public_folders%2FMy+Dashboards+and+Reports%2FStudent+Engagement+and+Enrollment+Services+%28SEES%29%2FSwipe+Dashboards+and+Reports%2FSEES+Swipe+Events+Dashboard&format=HTML&Download=false&prompt=true',
            linkTarget: "_blank"
        },
        {
            id: 'serviceNow',
            name: 'Service Now',
            icon: 'serviceNow.svg',
            desc: 'Service-Now is the website for the ODU ITS Help Desk, where users can create a trouble ticket or request IT services.',
            required: [{campus: 'main'}],
            preSelected: {campus: ['main']},
            isDefaultSelected: true,
            link: 'https://oduprod.service-now.com',
            linkTarget: "_blank"
        },
        {
            id: 'teams',
            name: 'Teams',
            icon: 'teams.svg',
            desc: 'Microsoft Teams is a collaboration platform that combines persistent chat, file collaboration, instant messaging, and meetings in a single platform available free to all students, faculty, and staff.',
            required: [{}],
            isDefaultSelected: true,
            link: 'http://teams.microsoft.com/',
            linkTarget: "_blank"
        },
        {
            id: 'transfermation',
            name: 'TRANSFERmation',
            icon: 'transfermation.svg',
            desc: 'Monarch TRANSFERmation is a massive online database of transfer information, including courses from other institutions that have been reviewed for credit.',
            required: [{role: 'STUDENT'}],
            link: 'https://www.odu.edu/transfer/transfermation',
            linkTarget: "_blank"
        },
        {
            id: 'transloc',
            name: 'Transloc',
            icon: 'transloc.svg',
            desc: 'Get the Transloc app to see bus routes, schedules, and request the Safe Ride shuttle.',
            required: [{role: 'STUDENT'}],
            preSelected: {campus: ['main'], isHousing: true},
            link: 'https://transloc.com/app/',
            linkTarget: "_blank"
        },
        {
            id: 'tuitionCalculator',
            name: 'Tuition Calculator',
            icon: 'tuitionCalculator.svg',
            desc: 'Use the tuition calculator to estimate cost of tuition, room & board, fees, and other charges based on current rates.',
            required: [{role: 'STUDENT'}],
            preSelected: {role: ['STUDENT']},
            link: 'https://itsapps.odu.edu/calculator/',
            linkTarget: "_blank"
        },
        {
            id: 'vpn',
            name: 'VPN (GlobalProtect)',
            icon: 'vpn.svg',
            desc: 'The GlobalProtect VPN is a secure way for faculty and staff to access sensitive resources such as Banner remotely or from Wi-Fi.',
            required: [{role: 'FACULTY'}, {role: 'STAFF'}],
            preSelected: {role: ['FACULTY', 'STAFF']},
            link: 'https://www.odu.edu/information-technology-services/vpn/vpnclient',
            linkTarget: "_blank"
        },
        {
            id: 'webTimeEntry',
            name: 'Web Time Entry',
            icon: 'webTimekeeping.svg',
            desc: 'Web Time Entry is used by ODU staff and student workers to submit timesheets, see pay stubs, download tax forms, etc.',
            required: [{}],
            preSelected: {role: ['STUWORKER']},
            link: 'https://xessb.odu.edu:7005/EmployeeSelfService/ssb/hrDashboard#/hrDashboard',
            linkTarget: "_blank"
        },
        {
            id: 'wordpress',
            name: 'Wordpress',
            icon: 'wordpress.svg',
            desc: 'WordPress is an online toolset that allows users to create and publish their own websites.',
            required: [{}],
            isDefaultSelected: true,
            link: 'https://wp.odu.edu/wp-login.php?action=shibboleth',
            linkTarget: "_blank"
        },
        {
            id: 'zoom',
            name: 'Zoom',
            icon: 'zoom.svg',
            desc: 'Zoom is a video conferencing platform that can be used through a computer desktop or mobile app and allows users to connect online for video conference meetings, classes, and other University events.',
            required: [{}],
            isDefaultSelected: true,
            link: 'https://odu.zoom.us/',
            linkTarget: "_blank"
        },
    ]
};

export const WidgetConfigs = {
    Version: 1.1,
    Widgets: [
        {id: 'widget__notifications', title: 'Notifications', desc: 'With the Notifications widget, you can effortlessly receive application notifications, ensuring you stay informed about important updates and events.', required: [{}], section: 1, isNoDrag: true},
        {id: 'widget__myApps', title: 'myApps', desc: 'Effortlessly manage your favorite apps and discover new ones! Seamlessly select and prioritize from a comprehensive list, with special recommendations tailored for students and faculty/staff members.', required: [{}], section: 1},
        // {id: 'widget__myCalendar', title: 'myCalendar', desc: 'some desc goes here in a somewhat long sentence like this one', required: [{}], section: 1},
        {id: 'widget__myCourses', title: 'myCourses', desc: 'Easily access all your enrolled courses or those you are teaching in one place! Quickly navigate to the course collaboration tools with convenient links provided alongside each course listing.', required: [{}], section: 1},
        {id: 'widget__myNews', title: 'myNews', desc: 'Stay informed with the latest headlines and dive deeper into stories that matter to you.', required: [{}], section: 1},
        {id: 'widget__announcements', title: 'Announcements', desc: 'Stay in the loop with our university announcements widget with tabs tailored for student or faculty/staff, ensuring you\'re always informed about the lastest University events.', required: [{}], section: 1},
        // {id: 'widget__myTasks', title: 'myTasks', desc: 'some desc goes here in a somewhat long sentence like this one', required: [{}], section: 1},
        {id: 'widget__courseTutoring', title: 'Course Tutoring', desc: 'Seamlessly integrating class search functionality with tutoring resources, this widget offers students a convenient tool to enhance their learning experience. Find tutoring spots with links to information and maps for easy navigation.', required: [{}], section: 1},
        {id: 'widget__mySchedule', title: 'mySchedule', desc: 'Stay organized with a glance at your next 5 schedule items, complete with locations or Zoom links, ensuring you never miss a class or appointment. ', required: [{}], section: 2},
        {id: 'widget__myRegistration', title: 'myRegistration', desc: 'Get a comprehensive view of any holds on your account and see your time ticket well in advance of the registration window, allowing ample time for planning and ensuring a smooth registration process for upcoming semesters.', required: [{}], section: 2},
        {id: 'widget__myBalances', title: 'myBalances', desc: 'Check your Monarch plus, laundry, and tuition balances all in one place, providing a comprehensive overview of your balances.', required: [{}], section: 2},
        {id: 'widget__myPrograms', title: 'myPrograms', desc: 'Track your academic progress by viewing the number of completed credits, current enrollments, next semester\'s courses, GPA, and declared major.', required: [{}], section: 2},
        // {id: 'widget__myHousing', title: 'myHousing', desc: 'some desc goes here in a somewhat long sentence like this one', required: [{}], section: 2},
        {id: 'widget__myAdvisingTeam', title: 'myAdvisingTeam', desc: 'Access contact information for your advisors effortlessly, facilitating communication and support throughout your academic journey.', required: [{}], section: 2},
        {id: 'widget__universityLibraries', title: 'University Libraries', desc: 'Explore information about ODU and EVMS libraries, including phone numbers and links to useful apps, empowering access to valuable academic resources', required: [{}], section: 2},
        {id: 'widget__placesAndHours', title: 'Places & Hours', desc: 'Access real-time information about campus facilities to check current operating statuses, view locations and maps, and explore detailed operating hours for each venue.', required: [{}], section: 2},
        {id: 'widget__systemStatus', title: 'System Status', desc: 'Stay informed about the operational status of essential systems like Outlook, LeoOnline, and Canvas.', required: [{}], section: 3},
        {id: 'widget__safety', title: 'Safety', desc: 'Get quick access to essential contact information for university, EVMS, and Tri-Cities centers, ensuring you can swiftly reach out for assistance in emergency situations or to address safety concerns.', required: [{}], section: 3},
        {id: 'widget__myBookmarks', title: 'myBookmarks', desc: 'Effortlessly save and edit bookmarks for quick access to frequently visited websites or resources, streamlining your digital workflow.', required: [{}], section: 3},
        {id: 'widget__myContacts', title: 'myContacts', desc: 'Search the university directory and save essential contacts for easy communication and networking within the academic community.', required: [{}], section: 3},
        {id: 'widget__myDining', title: 'myDining', desc: 'Monitor dining balances and access links to campus dining place menus, enabling you to convenient meal planning and access to food options.', required: [{}], section: 3},
        {id: 'widget__itsHelp', title: 'ITS Help', desc: 'Quickly obtain contact information for the Information Technology Help Desk, ensuring prompt assistance with any technical issues or queries.', required: [{}], section: 3},
        // {id: 'widget__universityPoll', title: 'University Poll', desc: 'some desc goes here in a somewhat long sentence like this one', required: [{}], section: 3},
        {id: 'widget__resources', title: 'Resources', desc: 'Access a curated list of helpful links, providing valuable resources and information to support your academic and personal needs.', required: [{}], section: 3},        
    ]
}

export const DefaultWidgetOrder = [
    ['widget__notifications', 'widget__myApps', 'widget__myCourses', 'widget__myNews', 'widget__announcements', 'widget__courseTutoring'],
    ['widget__mySchedule', 'widget__myRegistration', 'widget__myBalances', 'widget__myPrograms', 'widget__myAdvisingTeam', 'widget__universityLibraries', 'widget__placesAndHours'],
    ['widget__systemStatus', 'widget__safety', 'widget__myBookmarks', 'widget__myContacts', 'widget__myDining', 'widget__itsHelp', 'widget__resources']
]

export const WidgetVersionHistory = {
    1.0: {
        Added: ['widget__notifications', 'widget__myApps', 'widget__myCourses', 'widget__myNews', 'widget__announcements', 'widget__courseTutoring'
            , 'widget__mySchedule', 'widget__myRegistration', 'widget__myPrograms', 'widget__myAdvisingTeam', 'widget__universityLibraries', 'widget__placesAndHours'
            , 'widget__systemStatus', 'widget__safety', 'widget__myBookmarks', 'widget__myContacts', 'widget__myDining', 'widget__itsHelp', 'widget__resources'
        ],
        Deleted: []
    },
    1.1: {
        Added: ['widget__myBalances'],
        Deleted: []
    }
}

export const AppVersionHistory = {
    1.0: {
        Added: [],
        Deleted: []
    },
}