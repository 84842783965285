import React, {Component} from 'react';
import {
    List,
    ListItem,
    Stack,
    Box,
    IconButton,
    Tooltip,
    Typography
} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faFaceWorried,
    faFaceFrownSlight,
    faFaceMeh,
    faFaceSmile,
    faFaceSmileHearts
} from '@fortawesome/pro-light-svg-icons';
import PortalTooltip from './PortalTooltip';

class LikertFaceScale extends Component {
    state = {};

    getFaces = stepNum => {
        var faces = [
            <FontAwesomeIcon icon={faFaceWorried} fixedWidth size="xl" />,
            <FontAwesomeIcon icon={faFaceFrownSlight} fixedWidth size="xl" />,
            <FontAwesomeIcon icon={faFaceMeh} fixedWidth size="xl" />,
            <FontAwesomeIcon icon={faFaceSmile} fixedWidth size="xl" />,
            <FontAwesomeIcon icon={faFaceSmileHearts} fixedWidth size="xl" />
        ];
        return faces[stepNum];
    };

    getStepTitle = stepNum => {
        var title = [
            "It's awful.",
            "I don't like it.",
            "It's mid.",
            'I like it.',
            'I love it!'
        ];
        return title[stepNum];
    };

    onClick = stepNum => {
        this.props.onChange(stepNum);
    };

    makeStep = stepNum => {
        return (
            <>
                <Stack
                    justifyContent={'center'}
                    alignContent={'center'}
                    alignItems={'flex-start'}
                >
                    <div className="text-center" style={{width: '100%'}}>
                        <IconButton
                            className={`myOdu__likertFaceButton step_${stepNum} ${
                                this.props.rating === stepNum
                                    ? ' selected '
                                    : ''
                            }`}
                            onClick={() => this.onClick(stepNum)}
                        >
                            <Tooltip title={this.getStepTitle(stepNum)} arrow>
                                {this.getFaces(stepNum)}
                            </Tooltip>
                        </IconButton>
                    </div>
                    <Typography
                        variant="smallest"
                        className="text-center myOdu__likertFaceDescription"
                    >
                        {this.getStepTitle(stepNum)}
                    </Typography>
                </Stack>
            </>
        );
    };

    render() {
        return (
            <>
                <Stack
                    sx={{width: '100%', mb: 2}}
                    direction="row"
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                    className="text-center"
                >
                    {this.makeStep(0)}
                    {this.makeStep(1)}
                    {this.makeStep(2)}
                    {this.makeStep(3)}
                    {this.makeStep(4)}
                </Stack>
            </>
        );
    }
}

export default LikertFaceScale;
