import React, {Component} from 'react';
import {IconButton, Typography} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMessageSmile} from '@fortawesome/pro-light-svg-icons';
import PortalModal from './PortalModal';
import FeedbackForm from './FeedbackForm';

class FeedbackButton extends Component {
    state = {};

    toggleOpen = () => {
        this.setState({isExpand: !this.state.isExpand});
    };

    render() {
        return (
            <>
                <PortalModal
                    title={'New myODU Portal Feedback'}
                    maxWidth="sm"
                    isOpen={this.state.isExpand}
                    onClose={this.toggleOpen}
                    isCloseable
                    className="myOdu__feedbackForm"
                >
                    <FeedbackForm />
                </PortalModal>

                <IconButton
                    className="myOdu__feedbackButton"
                    onClick={this.toggleOpen}
                >
                    <span className="icon">
                        <FontAwesomeIcon icon={faMessageSmile} />
                    </span>
                    <Typography
                        component="span"
                        variant="smaller"
                        sx={{ml: 1, fontWeight: 500}}
                    >
                        Got Feedback?
                    </Typography>
                </IconButton>
            </>
        );
    }
}

export default FeedbackButton;
