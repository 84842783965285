/**
 * A wrapper component that just exists to immediately do Cognito auth.
 *
 */
import React, { Component } from 'react';
import { logIn } from '../../AWS';

class AuthRedirect extends Component {
    constructor(props) {
        super(props);
        logIn();
    }

    render() {
        <></>
    }
}

export default AuthRedirect