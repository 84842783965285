import React from "react";
import { Tooltip } from "@mui/material";


class PortalTooltip extends React.Component {
    state = {

    };

    render() {
        return <React.Fragment>
            <Tooltip id={this.props.id} title={this.props.title ?? 'Open in new window'} enterDelay={750} leaveDelay={0} enterNextDelay={750} arrow {...this.props}>
                {this.props.children}
            </Tooltip>
        </React.Fragment>
    }
}

export default PortalTooltip;