import React, { Component } from 'react';
import Widget from '../common/Widget.js';
import NewsTabs from './NewsTabs.js';
import UniversityNews from './UniversityNews.js';
import { getDataPublic } from '../../DataAccessLayer/index.js';
import { news } from '../../DataAccessLayer/services.js';
import { truncate } from 'lodash';

class News extends Component {
    state = {
        isExpand: true,
        widgetId: 'widget__myNews',
        isTitleStylized: true,
        title: ["my", "News"],
        //TODO: enable in v1.0+
        // menu:[
        //     {id: "myNews_editSubscriptions", title: "Edit Subscriptions"},
        //     {id: "myNews_hide", title: "Hide"},
        // ],
        selectedTab: 0,
        universityNews: []
    }

    componentDidMount(){
        this.loadNews()
    }

    loadNews = () => {
        getDataPublic(news).then(universityNews => {
            this.setState({
                universityNews,
            });
            
        }) 
    }

    render() {
        
        return <>
            <Widget data={{id: this.state.widgetId, isTitleStylized: this.state.isTitleStylized, title: this.state.title, menu: this.state.menu}} {...this.props}>
                {/* TODO: uncomment when there is more than 1 news source */}
                {/* <NewsTabs data={{widgetId: this.state.widgetId}} /> */}
                <div className="myOdu__myNews_wrapper">
                    <UniversityNews news={this.state.universityNews} data={{widgetId: this.state.widgetId}} />
                </div>
            </Widget>
        </>
    }
}
 
export default News;