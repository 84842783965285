import React, { Component } from 'react';
import { styled } from '@mui/material/styles';
import { Tooltip, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCaretLeft, faCaretRight } from '@fortawesome/pro-solid-svg-icons';

const MenuToggleIcon = styled(IconButton, { shouldForwardProp: (prop) => prop !== 'isSidebar' })((props) => {
    return {
        left: (props.isSidebar ? '-8px' : '65px'),
  }})

class MenuToggle extends Component {
    state = {  }

    render() { 
        return <React.Fragment>
            <Tooltip title={this.props.isSidebar ? "Collapse Menu" : "Expand Menu"} arrow>
                <MenuToggleIcon id="sideNav__button_menuToggle" className="myOdu__menuToggleButton" aria-label={this.props.isSidebar ? "Collapse Menu" : "Expand Menu"} isSidebar={this.props.isSidebar} onClick={this.props.onToggle} edge="start">
                    {!this.props.isSidebar && <FontAwesomeIcon icon={faCaretRight} size="xs"/>}
                    {this.props.isSidebar && <FontAwesomeIcon icon={faCaretLeft} size="xs" sx={{mr: 1}}/>}
                </MenuToggleIcon>
            </Tooltip>
        </React.Fragment>
    }
}
 
export default MenuToggle;


