import React, {Component} from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  Box
} from '@mui/material';
import PlaceCard from '../common/PlaceCard';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/pro-light-svg-icons';
import {connect} from 'react-redux';
import {toggleCategory} from './actions';

class PlaceCategories extends Component {
  state = {
    widgetId: 'widget__placesAndHours'
  };

  toggleExpand = () => {
    this.props.toggleCategory({
      ...this.props.expandedCategories,
      [this.props.data.category.id]:
        !this.props.expandedCategories[this.props.data.category.id]
    });
  };

  render() {
    const {
      data: {category},
      expandedCategories
    } = this.props;

    const isExpand = !!expandedCategories[category.id];
    const {widgetId} = this.state;

    return (
      <Accordion
        elevation={0}
        expanded={isExpand}
        id={`placesAndHoursAccordion__${category.id}`}
        className="myOdu__placesAndHoursCategories"
        sx={{width: '100%'}}
      >
        <AccordionSummary
          expandIcon={<FontAwesomeIcon icon={faChevronDown} size="lg" />}
          onClick={this.toggleExpand}
          id={`placesAndHoursAccordion__${category.id}_accordionSummary`}
          aria-controls={`placesAndHoursAccordion__${category.id}_accordionDetails`}
          sx={{my:1, flexDirection: 'row-reverse', flexGrow: 1, pl:0}}
        >
          <Typography variant="h5" component="h3" sx={{ml: 1}}>
            {category.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{pt:0, pb:0}} className="jen">
          <Box>
            <List sx={{p: 0}}>
              {category.places.map(place => (
                <ListItem
                  divider
                  sx={{ml: 0, p: 0}}
                  key={`placesAndHours_categoriesList_placeList_${place.id}`}
                >
                  <Box sx={{p: 0, width: '100%'}}>
                    <PlaceCard data={{location: place}} widgetId={widgetId} />
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  }
}

const mapStateToProps = state => ({
  expandedCategories: state.placesAndHoursReducer.expandedCategories
});

const mapDispatchToProps = {
  toggleCategory
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaceCategories);
