import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faCircle} from '@fortawesome/pro-light-svg-icons';
import {faCircleCheck} from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';
import {
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Stack,
    Box
} from '@mui/material';
import ItemButtons from './ItemButtons';
import {CHECKLIST_YES_INDICATOR} from '../common/constants';
import {getDateTime} from '../common/utils';

class Item extends Component {
    state = {
        isCompleted: false
    };

    componentDidMount() {
        if (this.props.item?.itemCompletedInd === CHECKLIST_YES_INDICATOR) {
            this.setState({isCompleted: true});
        }
    }

    render() {
        const code = this.props.item.itemTimeCode;
        return (
            <Accordion
                elevation={0}
                expanded={this.props.isExpand}
                id={code + '_accordion'}
                sx={{width: '100%'}}
            >
                <AccordionSummary
                    expandIcon={
                        <FontAwesomeIcon icon={faChevronDown} size="lg" style={{marginLeft:'0.5rem'}}/>
                    }
                    id={code + '_accordionSummary'}
                    aria-controls={code + '_accordionDetails'}
                    sx={{
                        mt: 0,
                        p: 0,
                        flexDirection: 'row-reverse',
                        flexGrow: 1
                    }}
                    onClick={() => {
                        this.props.toggleItemExpansionStatus(
                            this.props.index,
                            !this.props.isExpand
                        );
                    }}
                >
                    <Stack
                        direction={{
                            xs: 'column',
                            lg: 'row'
                        }}
                        justifyContent="space-between"
                        sx={{ml: 0.5, width: '100%'}}
                    >
                        <Box>
                            <Stack direction="row">
                                {!this.props.item.itemBoxHideInd && (
                                    <FontAwesomeIcon
                                        fixedWidth
                                        icon={
                                            this.state.isCompleted
                                                ? faCircleCheck
                                                : faCircle
                                        }
                                        className={
                                            this.state.isCompleted
                                                ? 'myOdu__good'
                                                : ''
                                        }
                                        style={{
                                            alignSelf: 'center',
                                            marginRight: '.25rem'
                                        }}
                                        size="xl"
                                    />
                                )}
                                <Typography variant="h4" component="h3">
                                    {this.props.item.title}
                                </Typography>
                            </Stack>
                        </Box>
                        {this.props.item.itemRequiredInd ===
                        CHECKLIST_YES_INDICATOR ? (
                            <Typography
                                className="myOdu__required"
                                variant="small"
                                sx={{mr: 1}}
                            >
                                Required
                            </Typography>
                        ) : (
                            <Typography
                                className="myOdu__optional"
                                variant="small"
                                sx={{mr: 1}}
                            >
                                Optional
                            </Typography>
                        )}
                    </Stack>
                </AccordionSummary>
                <AccordionDetails id={code + '_accordionDetails'} sx={{p: 1}}>
                    <Stack direction="column">
                        {this.state.isCompleted &&
                            this.props.item.itemCompleteDate && (
                                <Typography
                                    variant="small"
                                    sx={{pb: 1, fontStyle: 'italic'}}
                                >
                                    {`Item marked complete on ${getDateTime(this.props.item.itemCompleteDate)}`}
                                </Typography>
                            )}
                        <Typography
                            variant="small"
                            dangerouslySetInnerHTML={{
                                __html: this.props.item.description
                            }}
                        />
                        <ItemButtons item={this.props.item} />
                    </Stack>
                </AccordionDetails>
            </Accordion>
        );
    }
}

export default Item;
