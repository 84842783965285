import React, { Component } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Link, FormControl, FormGroup, Stack, FormControlLabel, Switch } from '@mui/material';
import Icon from '@mui/material/Icon';
import { connect } from 'react-redux';
import Apps from './config';
import PortalTooltip from '../../common/PortalTooltip';
// import {isMobile} from 'react-device-detect';
import { Col, Row } from 'reactstrap';

const accordionProps = {
    sx: {
      flexDirection: "row-reverse", 
    },
  };

class Applications extends Component {
    state = {
        isExpand: true,
        isViewByName: true,
        isMobile: true,     //dummy
        isAndroid: false    //dummy
    }

    componentDidUpdate(){
        if(this.props.text && !this.state.isExpand)
            this.toggleExpand(true)
    }

    toggleExpand = (forced) => {
        this.setState({isExpand: forced ?? !this.state.isExpand})
    }

    render() {
        const filtered = Apps.filter(app => app.name.toLocaleLowerCase().includes(this.props.text.toLocaleLowerCase())).reduce((groups, app) => {
            const key = app.name.charAt(0).toLocaleUpperCase()
            groups = {...groups, [key]: [...(groups[key] ?? []), app]}
            return groups
        }, {})
        return <React.Fragment>
            <Accordion expanded={this.state.isExpand} onChange={() => this.toggleExpand()} elevation={0}>
                <AccordionSummary expandIcon={<Icon baseClassName='fal' className='fa-chevron-down' />} {...accordionProps}>
                    <Typography component="h2" variant="h4" sx={{ml:2}}>All Applications</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{pr:5, pl:5}}>
                    {/* <Stack sx={{justifyContent: 'space-between', borderBottom: '1px solid #EEE', mb:2, pb:1}} direction="row">
                        <Typography component="h3" variant="h6">Listed By {this.state.isViewByName? 'Name': 'Category'}</Typography>
                        

                        <FormGroup>      
                            <Stack direction="row" spacing={1} alignItems="center">
                                <label className="myOdu__toggleSwitchLabel"><Typography variant="smaller" id="applicationsListViewByLabel">View By</Typography></label>
                                <Typography variant="smaller">Name</Typography>
                                <Switch size="small" color="primary" inputProps={{ 'aria-label': 'View By' }} />
                                <Typography variant="smaller">Category</Typography>
                            </Stack>
                        </FormGroup>
                    </Stack> */}
                    
                    
                   
                   {/* Alphabet list here */}
                    


                    <Box className="myOdu__applicationList">
                    {Object.entries(filtered).map(([group, apps]) => {
                        return <React.Fragment  key={group}>
                            <div className="groupBlock">
                                <a name={group}/>
                                <Typography component="h3" variant="h6" sx={{mt:3}} className="myOdu__applicationListHeader">{group}</Typography>
                                {/* TODO://update and clean up the mobile display part  */}
                                {apps.map(app =>  
                                    (!app.isEVMSonly) && ((app.link) ? 
                                        (<div key={app.name}>
                                            <PortalTooltip id={"landing_" + app.name + "_tooltip"} title={(this.state.isMobile && app.iOsLink || this.state.isMobile && app.androidLink )? "Opens Mobile App" : 'Open ' + app.name + ' in New Window'} >
                                                <Link href={(this.state.isMobile && app.iOsLink || this.state.isMobile && app.androidLink ) ? (this.state.isAndroid ? app.androidLink : app.iOsLink) : app.link} 
                                                    target='_blank' variant='small' className={(this.state.isMobile && app.iOsLink || this.state.isMobile && app.androidLink )? "myOdu__hasMobileApp" : ''} >
                                                        {app.name}
                                                </Link>
                                            </PortalTooltip>
                                        </div>)
                                        
                                        :
                                        (<div key={app.name}><Typography variant='small'>{app.name}</Typography></div>)
                                    )
                                )}
                            </div>
                        </React.Fragment>
                    })}
                    </Box>
                </AccordionDetails>
            </Accordion>
        </React.Fragment>
    }
}
 
const mapStateToProps = (state) => {
    return {
        text: state.landingReducer.text
    }
  }
  
const mapDispatchToProps = (dispatch) => ({
    saveLandingFilter: (text) => dispatch(saveLandingFilter(text))
});
  
export default connect(mapStateToProps, mapDispatchToProps)(Applications);