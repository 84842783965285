import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    TextField
} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {DateRangePicker} from '@mui/x-date-pickers-pro/DateRangePicker';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, {Component} from 'react';
import {Col, Row} from 'reactstrap';
import {connect} from 'react-redux';
import moment from 'moment';
import {postData} from '../../../../../DataAccessLayer';
import {cct} from '../../../../../DataAccessLayer/services';
import {toast} from 'react-toastify';
import {ZoomTabMapping} from '../config';
import {MeetingDateFormat, OptionsMapping} from './config';
import {zoomDevEmail} from '../../../../common/constants';

class ClassMeetings extends Component {
    state = {
        isLoading: false,
        isCustomDate: false,
        customStartDate: dayjs(this.props.course?.START_DATE),
        customEndDate: dayjs(this.props.course?.END_DATE),
        [OptionsMapping.isRecordMeeting]: false,
        [OptionsMapping.isPublishToCanvas]: false,
        [OptionsMapping.isJoinBeforeHost]: false,
        [OptionsMapping.isHideMeeting]: false
    };

    toggleIsLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading
        });
    };

    //Θ(1) sets start and end dates
    setCustomDates = value => {
        let customStartDate = value[0];
        let customEndDate = value[1];

        if (customStartDate && customEndDate) {
            this.setState({
                customStartDate,
                customEndDate
            });
        }
    };

    //Θ(1) updates values of respective checkboxes
    onCheckbox = event => {
        this.setState({
            [event.target.name]: event.target.checked
        });
    };

    //Θ(1) returns the topic
    getTopic = course => {
        let crns = course.CRNS.split(',');
        return (
            course.SSBSECT_TERM_CODE +
            '_' +
            course.SUBJECT_CODE +
            '_' +
            course.COURSE_NUMBER +
            '_' +
            crns[0]
        );
    };

    //Θ(1) Creates a meeting by hitting backend api
    onSchedule = () => {
        this.toggleIsLoading();
        let course = this.props.course;

        let topic = this.getTopic(course);
        let agenda = 'Meeting for ' + course.CRNS;
        let record = this.state.isRecordMeeting;
        let joinBeforeHost = this.state.isJoinBeforeHost;
        let hideMeetings = this.state[OptionsMapping.isHideMeeting];
        let preferredStartDate = moment(
            this.state.customStartDate['$d']
        ).format(MeetingDateFormat);
        let preferredEndDate = moment(this.state.customEndDate['$d']).format(
            MeetingDateFormat
        );
        let publishKaltura = this.state.isPublishToCanvas;

        let meetingDetails = {
            crns: course.CRNS,
            termCode: course.SSBSECT_TERM_CODE,
            topic,
            agenda,
            email: zoomDevEmail,
            publishKaltura,
            record,
            joinBeforeHost,
            hideMeetings,
            ...(this.state.isCustomDate && {
                preferredStartDate,
                preferredEndDate
            }) //Dates added only if Custom dates checkbox checked
        };

        postData(cct, meetingDetails)
            .then(result => {
                if (!result) toast.error('Unable to create meetings');
                else {
                    this.props.onTabChange('', ZoomTabMapping.currentMeeting);
                    toast.success('Meetings Created Successfully');
                }
                this.toggleIsLoading();
            })
            .catch(err => {
                this.toggleIsLoading();
                console.log(err);
            });
    };

    render() {
        let course = this.props.course;
        let topic = this.getTopic(course);
        return (
            <React.Fragment>
                <Row>
                    <Col>Schedule Class Meetings</Col>
                </Row>
                <Row>
                    <Col>Meeting Title</Col>
                    <Col>
                        <TextField
                            id="outlined-basic"
                            label="Meeting title"
                            variant="outlined"
                            disabled
                            value={topic}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>Date Range</Col>
                    <Col>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={this.onCheckbox}
                                        name="isCustomDate"
                                    />
                                }
                                label="Custom Date Range"
                            />
                        </FormGroup>
                        {this.state.isCustomDate && (
                            <Box sx={{display: 'flex'}}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DemoContainer
                                        components={['DateRangePicker']}
                                    >
                                        <DateRangePicker
                                            value={[
                                                this.state.customStartDate,
                                                this.state.customEndDate
                                            ]}
                                            onChange={this.setCustomDates}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Box>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col>Options</Col>
                    <Col>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={this.onCheckbox}
                                        name={OptionsMapping.isRecordMeeting}
                                    />
                                }
                                label="Record Meeting"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={this.onCheckbox}
                                        name={OptionsMapping.isPublishToCanvas}
                                    />
                                }
                                label="Automatically Pulish Recording to Canvas"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={this.onCheckbox}
                                        name={OptionsMapping.isJoinBeforeHost}
                                    />
                                }
                                label="Allow Participants to Join Before Host"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={this.onCheckbox}
                                        name={OptionsMapping.isHideMeeting}
                                    />
                                }
                                label="Hide Meeting from Students"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row style={{float: 'right'}}>
                    <Col xs={'auto'}>
                        <Button onClick={this.props.onToggle}>Back</Button>
                    </Col>
                    <Col>
                        <Button
                            onClick={this.onSchedule}
                            disabled={this.state.isLoading}
                        >
                            Schedule Class Meeting
                        </Button>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        course: state.CCTReducer.course
    };
};

export default connect(mapStateToProps)(ClassMeetings);
