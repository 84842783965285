import {Card, CardActionArea, Typography} from '@mui/material';
import React, {Component} from 'react';
import {Col, Row} from 'reactstrap';
import WithRouter from '../Layout/Router/WithRouter';

class AdminPanel extends Component {
    state = {};
    render() {
        return (
            <React.Fragment>
                <div className="myOdu__container_maxWidth" style={{marginTop: '2rem'}}>
                    <h4>Admin Panel</h4>
                    <Row>
                        <Col md={3}>
                            <Card>
                                <CardActionArea
                                    onClick={() =>
                                        this.props.navigate('/admin/tutoring')
                                    }
                                >
                                    <Typography>Tutoring</Typography>
                                </CardActionArea>
                            </Card>
                        </Col>

                        <Col md={3}>
                            <Card>
                                <CardActionArea
                                    onClick={() =>
                                        this.props.navigate('/admin/locations')
                                    }
                                >
                                    <Typography>Locations</Typography>
                                </CardActionArea>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default WithRouter(AdminPanel);
