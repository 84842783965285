import React, {Component} from 'react';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/pro-light-svg-icons';
import PlaceSummary from './PlaceSummary';
import PlaceDetails from './PlaceDetails';
import {connect} from 'react-redux';
import {toggleCategory} from '../PlacesAndHours/actions';

class PlaceCard extends Component {
  toggleExpand = () => {
    this.props.toggleCategory({
      ...this.props.expandedCategories,
      [this.props.data.location.id]:
        !this.props.expandedCategories[this.props.data.location.id]
    });
  };

  render() {
    const {data, widgetId, expandedCategories} = this.props;
    const isExpand = !!expandedCategories[data.location.id];
    const {location} = data;

    return (
      <Accordion
        sx={{border: 0, width: '100%'}}
        expanded={isExpand}
        elevation={0}
        className="myOdu__widget"
        id={`${data.id}_${widgetId}`}
      >
        <AccordionSummary
          expandIcon={<FontAwesomeIcon icon={faChevronDown} size="sm" />}
          id={`${data.id}_accordionSummary_${widgetId}`}
          aria-controls={`${data.id}_accordionDetails`}
          sx={{flexDirection: 'row-reverse', flexGrow: 1, pl: 1, pr: 0}}
          onClick={this.toggleExpand}
        >
          <PlaceSummary data={{location}} widgetId={widgetId} />
        </AccordionSummary>
        <AccordionDetails sx={{p: 0, mb:2}}>
          <PlaceDetails
            data={{location}}
            widgetId={widgetId}
          />
        </AccordionDetails>
      </Accordion>
    );
  }
}

const mapStateToProps = state => ({
  expandedCategories: state.placesAndHoursReducer.expandedCategories
});

export default connect(mapStateToProps, {toggleCategory})(PlaceCard);
