import * as React from 'react';
import { Card, Typography, Link, Box, IconButton, Grid} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import PortalTooltip from "../common/PortalTooltip";

export class ApplicationCard extends React.Component {
  state={
    tooltipIsOpen: false,
  }

  toggleTooltip = () => {
    this.state.tooltipIsOpen= !this.state.tooltipIsOpen;
  };


  getLinkTitle = () =>{
    return ((this.props.app?.linkTarget == "_blank") ? "Open " + this.props.app?.name + " in new window" : "Go to " + this.props.app?.name );
  };

    render(){
        const linkTitle = this.getLinkTitle();
        return (   
            <Card sx={{ p:1, height: '100%', width: '100%'}} className={'myOdu__appCard ' + (this.props.isActive ? "active " : "disabled ") + this.props.className } elevation={0}>
                <Grid container direction='column' style={{opacity: this.props.opacity}}>
                
                    <Grid item container direction='row' alignItems='flex-start'>
                        {this.props.showHeart &&
                            <div onClick={this.props.onClick} className="">
                                <PortalTooltip title={this.props.isActive? "Remove " + this.props.app?.name + " from favorite apps": "Add to favorite apps"}>
                                    <IconButton id={"applicationCard__button_favorite_" + this.props.app?.name.replace(" ", "")} size='small' className={"fa-heart float-start fa-lg " + (this.props.isActive ? "fa-solid" : "fa-light")} style={{color: this.props.isActive ? '#C23335' : 'black'}}></IconButton>
                                </PortalTooltip>
                            </div>
                        }
                    </Grid>

                    <Grid item>
                        <div className={this.props.className}>
                            {/* //With link */}
                            {this.props.showLinks && this.props.app?.link ? <>
                                <PortalTooltip id={this.props.app?.id + "_tooltip_box"} title={linkTitle}>
                                    <Link id={"applicationCard__button_" + this.props.app?.name.replace(" ", "")} href={this.props.app?.link} target="_blank" underline="none" className={"cursor-pointer"} >
                                        <Box sx={{height: '40px', width: '40px', margin: '0 auto'}}  className='myOdu__logoZoom'>
                                            <img src={this.props.data.icon} alt={"icon for " + this.props.app?.name} />
                                        </Box>
                                    </Link>
                                </PortalTooltip>
                                <PortalTooltip id={this.props.app?.id + "_tooltip"} title={linkTitle}>
                                    <Typography align="center" variant="smaller" sx={{ml:0, wordBreak: 'break-word'}}>
                                        <Link id={"applicationCard__link_" + this.props.app?.name.replace(" ", "")} href={this.props.app?.link} underline={"hover"} sx={{cursor: 'pointer'}} target={this.props.app.linkTarget ?? ""} className="myOdu__appCardTitleLink">
                                            {this.props.app?.name}
                                        </Link>
                                    </Typography>
                                </PortalTooltip>
                            </> : <>
                            {/* //Without link */}
                                <PortalTooltip title={this.props.data.tooltip}>
                                    <Box sx={{height: '40px', width: '40px', margin: '0 auto .5rem auto', cursor: 'auto'}}>
                                        <img src={this.props.data.icon} alt="" />
                                    </Box>
                                </PortalTooltip>
                                <Typography component="p" align="center" variant="smaller" sx={{ml:0}} >
                                    {this.props.app?.name}
                                </Typography>                    
                            </>}
                        </div>
                    </Grid>

                    <Grid item>
                        {this.props.showInfo && <>
                            <PortalTooltip title={this.props.app?.desc ?? this.props.app?.name}>
                                <IconButton id={"applicationCard__button_moreInfo_" + this.props.app?.name.replace(" ", "")} size='small' className="fa-light float-end fa-md cursor-pointer">
                                    <FontAwesomeIcon icon={faCircleInfo} />
                                </IconButton>
                            </PortalTooltip>
                        </>}
                    </Grid>                 
                </Grid>
            </Card>
        );
    }
}

export default ApplicationCard;

