import React, { Component } from 'react';
import Widget from '../common/Widget';
import { Container, Row, Col } from 'reactstrap';
import { Link, Stack, Typography, Divider, Accordion, AccordionSummary, AccordionDetails, Box, Grid, Tooltip } from '@mui/material';
import { ApplicationCard } from '../common/ApplicationCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';


class UniversityLibraries extends Component {
    state = {
        widgetId: 'widget__universityLibraries',
        iconPath: '/img/logos/',
        //TODO: enable in v1.1
        // menu: [
        //     {id: 'universityLibraries_hide', title: 'Hide'},
        // ],
        //TODO: dont do it this way
        isOduExpand: false,
        isEvmsExpand: false,
        locations:[
            { 
                title: "ODU Library Resources",
                isExpand: false,
                apps:[
                    { id: 'universityLibraries_odu_monarchOneSearch', name: 'Monarch OneSearch', icon: 'monarchOneSearch.svg', link: "https://odu-primo.hosted.exlibrisgroup.com/primo-explore/search?vid=01ODU_NUI&amp;lang=en_US", linkTarget: "_blank" },
                    { id: 'universityLibraries_odu_roomReservations', name: 'Room Reservations', icon: 'roomReservations.svg', link: "https://www.odu.edu/library/places-spaces/room-reservations", linkTarget: "_blank" },
                    { id: 'universityLibraries_odu_libraryGuides', name: 'Library Guides', icon: 'libraryGuides.svg', link: "https://guides.lib.odu.edu/", linkTarget: "_blank" },
                    { id: 'universityLibraries_odu_researchHelp', name: 'Research Help', icon: 'researchHelp.svg', link: "https://www.odu.edu/library/help", linkTarget: "_blank" },
                ],
                libraryLinks:[
                    { id: 'universityLibraries_odu_databases', title: 'Databases', link: 'https://guides.lib.odu.edu/az.php', linkTarget: '_blank'},
                    { id: 'universityLibraries_odu_calendar',title: 'Calendar', link: 'https://www.odu.edu/library/about/news-events-exhibits/calendar', linkTarget: '_blank'},
                    { id: 'universityLibraries_odu_hours',title: 'Hours', link: 'https://odu.libcal.com/hours/', linkTarget: '_blank'},
                    { id: 'universityLibraries_odu_interlibraryLoan',title: 'Interlibrary Loan', link: 'https://ww1.odu.edu/library/services/interlibrary-loan', linkTarget: '_blank'},
                ], 
            },
            {
                title: "Health Sciences Resources at Brickell Library",
                isExpand: true,
                apps:[
                    { id: 'universityLibraries_evms_monarchOneSearch', name: 'Worldcat Discovery Search', icon: 'worldcat.svg', link: "https://evms.on.worldcat.org/discovery", linkTarget: "_blank" },
                    { id: 'universityLibraries_evms_roomReservations', name: 'PubMed Search', icon: 'pubmed.svg', link: "https://www.odu.edu/library/places-spaces/room-reservations", linkTarget: "_blank" },
                    { id: 'universityLibraries_evms_libraryGuides', name: 'Ask a Librarian', icon: 'askALibrarian.svg', link: "https://guides.lib.odu.edu/", linkTarget: "_blank" },
                    { id: 'universityLibraries_evms_researchHelp', name: 'Quick Links', icon: 'quicklinks.svg', link: "https://www.odu.edu/library/help", linkTarget: "_blank" },
                ],
                libraryLinks:[
                    { id: 'universityLibraries_evms_databases', title: 'Databases', link: 'https://evms.libguides.com/az.php', linkTarget: '_blank'},
                    { id: 'universityLibraries_evms_researchGuides',title: 'Research Guides', link: 'https://evms.libguides.com/az.php', linkTarget: '_blank'},
                    { id: 'universityLibraries_evms_documentDelivery',title: 'Document Delivery/ILL', link: 'https://www.evms.edu/library/services/circ/ill/', linkTarget: '_blank'},
                    { id: 'universityLibraries_evms_services',title: 'Services', link: 'https://www.evms.edu/library/services', linkTarget: '_blank'},
                ]
            }
        ],
        
        
    }

    toggleExpand = (locationIdx) => {
       
    }
 
    render() {
        
        return <React.Fragment>
            <Widget data={{title: "University Libraries", menu: this.state.menu, id: this.state.widgetId}} {...this.props}>
            
                <Container fluid>
                    <Row>
                        <Col xxl={8} xl={12} lg={6} md={7} sm={6} xs={12} className="text-start px-0">
                            <Typography sx={{fontWeight: "600", color: 'black', display: 'inline'}}>
                                <Link id="universityLibraries__link_mainCampus" href="http://www.odu.edu/library" underline={'hover'} target="_blank" className="">University Libraries</Link>
                            </Typography>
                            <Typography sx={{display: 'inline'}}> (Main Campus)</Typography>
                            
                        </Col>

                        <Col xxl={4} xl={12} lg={6} md={5} sm={6} xs={12} className="text-xxl-end text-xl-start text-lg-end text-md-end text-sm-end text-start px-0 ">
                            <Link id="universityLibraries__link_phone_mainCampus" href="tel:757-683-4179" underline={'hover'} className="myOdu__phone">757-683-4179</Link>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xxl={8} xl={12} lg={6} md={7}  sm={6} xs={12} className="text-start px-0 ">
                            <Typography sx={{fontWeight: "600", color: 'black', display: 'inline'}}>
                                <Link id="universityLibraries__link_evms" href="https://www.evms.edu/library" underline={'hover'} target="_blank" className="">Brickell Medical Sciences Library </Link>
                            </Typography>
                            <Typography sx={{display: 'inline'}}>(Eastern Virginia Health Sciences Center)</Typography>
                        </Col>

                        <Col xxl={4} xl={12} lg={6} md={5} sm={6} xs={12} className="text-xxl-end text-xl-start text-lg-end text-md-end text-sm-end text-start px-0 ">
                            <Link id="universityLibraries__link_phone_evms" href="tel:757-446-5851" underline={'hover'} className="myOdu__phone">757-446-5851</Link>
                        </Col>
                    </Row>
                </Container>

                {/* <hr className="myOdu__border"/> */}
                {this.state.locations.map((location, index) =>
                <Accordion key={"libraryAccordion_" + index} expanded={this.toggleExpand(index)} elevation={0} id={this.state.widgetId + "_odu_accordion"} className="myOdu__accordion myOdu__border">
                   <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown}  onClick={() => this.toggleExpand(index)} fixedWidth/>} id={this.state.widgetId + "_odu_accordionSummary"} aria-controls={this.state.widgetId + "_odu_accordionDetails"} sx={{flexDirection: "row-reverse", flexGrow: 1}}>
                       <Typography component="h3" variant="h6" onClick={() => this.toggleExpand(index)} className="myOdu__widget_title">
                           {location.title}
                       </Typography>
                   </AccordionSummary>
                   <AccordionDetails id={this.state.widgetId + "_odu_accordionDetails"} sx={{p:0}}>
                       <Grid container spacing={2}>
                           {location.apps.map(app=>
                               <Grid item xs={3} md={6} lg={3} xl={6} xxl={3} key={"universityLibraries_" + location.title + "_app_" + app.id}>
                                   <ApplicationCard app={app} data={{icon: this.state.iconPath + app.icon}} isActive={true} showHeart={false} showInfo={false} showLinks={true} key={app.id} className="myOdu__notMyApps p-0 border-0" />
                               </Grid>
                           )}
                       </Grid> 

                       <Box className="myOdu__box" sx={{borderBottom: 0, borderRight: 0, borderLeft: 0, p:0, mt:2}}/>

                       <Stack direction={{ md: "column", sm: "row", lg: "row", xl: "column", xxl: "col", xxxl: "row"}} sx={{width: '100%'}} divider={<Divider orientation="vertical" flexItem sx={{borderColor: 'black'}} role="presentation" />} spacing={0} justifyContent="space-around" className="text-center">
                           {location.libraryLinks.map(item=>
                                <Tooltip title="Opens in new window" enterDelay={850} leaveDelay={0} enterNextDelay={850} arrow key={"universityLibraries_" + item.id + "_tooltip"}>
                                    <Link id={"universityLibraries__link_" + item.id}href={item.link} underline='hover' style={{marginLeft: 0, textAlign: 'center'}} key={item.id} className="myOdu__universityLibraries_link">{item.title}</Link>
                                </Tooltip>
                           )}
                       </Stack>
                   </AccordionDetails>
               </Accordion>  
                )}
                
            
            </Widget>
        </React.Fragment>
    }
}
 
export default UniversityLibraries;


