import React, {Component} from 'react';
import Widget from '../common/Widget';

import {
    Link,
    List,
    ListItem,
    Box,
    Typography,
    Alert,
    Tooltip,
    Button,
    Skeleton,
    Grid
} from '@mui/material';

import {getData} from '../../DataAccessLayer/index.js';
import {mySchedule} from '../../DataAccessLayer/services.js';
import {makeTitle, getClickableIcon} from './utils.js';
import MyScheduleItem from './MyScheduleItem.js';

class MySchedule extends Component {
    state = {
        isExpand: true,
        widgetId: "widget__mySchedule",
        isTitleStylized: true,
        title: ["my", "Schedule"],
        data: [],
        itemsToShow: 5, // Initially show 5 items
        isLoading: false,
        //TODO: enable in v1.1
        // menu:[
        //     {id: "mySchedule_hide", title: "Hide"},
        // ],
    };

    componentDidMount() {
        this.loadSchedule();
    }

    loadSchedule = async () => {
        try {
            this.setState({isLoading: true});
            const data = await getData(mySchedule, true);
            this.setState({data});
        } catch (err) {
            console.log(err);
        } finally {
            this.setState({isLoading: false});
        }
    };

    toggleExpand = () => {
        this.setState({isExpand: !this.state.isExpand});
    };

    showMore = () => {
        this.setState({
            itemsToShow: this.state.itemsToShow + 5
        });
    };

    render() {
        const {data, itemsToShow, isLoading} = this.state;
        const itemsToDisplay = data.slice(0, itemsToShow);

        return (
            <>
                <Widget data={{id: this.state.widgetId, isTitleStylized: this.state.isTitleStylized, title: this.state.title, menu: this.state.menu}} className="myOdu__mySchedule">
                <div className="myOdu__mySchedule_wrapper">
                    <Grid container className="scheduleList" wrap='nowrap' direction='row' rowSpacing={2}>
                        {isLoading ? (
                            Array.from({length: 1}, (_, index) => (
                                <Grid item
                                    sx={{width: '100%', py:1, px:0}}
                                    key={index}
                                >
                                    <Box sx={{width: '100%'}}>
                                        <Skeleton
                                            variant="rectangular"
                                            height={80}
                                        />
                                    </Box>
                                </Grid>
                            ))
                        ) : itemsToDisplay.length === 0 && !isLoading ? (
                            <Typography component="p" variant="small">
                                No schedule found.
                            </Typography>
                        ) : (
                            itemsToDisplay.map((item, index) => {
                                item.id = index;
                                return (
                                    <MyScheduleItem item={item} index={index} />
                                );
                            })
                        )}
                        {
                            itemsToDisplay.length < data.length && (
                                <Grid item 
                                    sx={{minWidth: 'fit-content'}} 
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{paddingTop: 0}}
                                >
                                    <Button
                                        id="mySchedule__button_showMore"
                                        className="myOdu__button secondary"
                                        variant="outlined"
                                        onClick={this.showMore}
                                    >
                                        Show more
                                    </Button>
                                </Grid>
                            )
                        }
                    </Grid>
                </div>
                </Widget>
            </>
        );
    }
}

export default MySchedule;
