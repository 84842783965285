import {createStore, combineReducers} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

//Custom imports
import {headerReducer} from './components/Layout/HeaderToolbar/reducers';
import {AWSReducer} from './AWS/reducers';
import {landingReducer} from './components/Landing/reducers';
import {preferencesReducer} from './components/SetUp/reducers';
import {setupReducer} from './components/SetUp/reducers';
import {impersonationReducer} from './components/Impersonation/reducers';
import {myCoursesReducer} from './components/MyCourses/reducers';
import {myStatusAlertsReducer} from './components/StatusAlerts/reducers';
import {placesAndHoursReducer} from './components/PlacesAndHours/reducers';
import {CCTReducer} from './components/MyCourses/CCT/reducers';
import {myContactsReducer} from './components/MyContacts/reducers';
import {myChecklistsReducer} from './components/Checklists/reducers';
import {myDiningReducer} from './components/MyDining/reducers';
import {termsReducer} from './components/MyCourses/Terms/reducers';
import {isProduction} from './components/Layout/utils';

const reducers = {
    headerReducer,
    AWSReducer,
    landingReducer,
    preferencesReducer,
    setupReducer,
    impersonationReducer,
    myCoursesReducer,
    myStatusAlertsReducer,
    placesAndHoursReducer,
    CCTReducer,
    myContactsReducer,
    myChecklistsReducer,
    myDiningReducer,
    termsReducer
};

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
        /**
         * Important!
         * Justify the reason to persist a reducer state
         */

        //to persist sidenav collapse state on reload
        'headerReducer',

        //to persist logged in user on reload
        'AWSReducer',

        //to persist user preferences on reload
        'preferencesReducer',

        //to persist impersonation on reload
        'impersonationReducer',

        //save accordion open/close status per user
        'placesAndHoursReducer',

        //save the term details
        'termsReducer'
    ]
};

export const store = createStore(
    persistReducer(persistConfig, combineReducers(reducers)),
    !isProduction() &&
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__({trace: true})
);

export const persistor = persistStore(store);
