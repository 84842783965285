import { Box, Button, Tab, Tabs } from '@mui/material';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import ScheduleMeeting from './ScheduleMeeting/index.js';
import { connect } from 'react-redux';
import { ZoomTabMapping } from './config.js';
import { getData } from '../../../../DataAccessLayer/index.js';
import { cct } from '../../../../DataAccessLayer/services.js';
import moment from 'moment';
import Meetings from './Meetings/index.js';

class Zoom extends Component {
    state = {
        activeTab: 1,
        isLoading: false,
        currentMeetings: [],
        pastMeetings: [],
    } 

    //Θ(1) Sets active tab
    onTabChange = (e, activeTab) => {
        this.setState({activeTab})
    }

    toggleIsLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading
        })
    }

    //Θ(N) where N is length of meetings
    //Loads all the meetings from backend and segragates past and current meetings
    loadMeetings = () => {
        this.toggleIsLoading();
        let crns = this.props.course.CRNS.split(',')
        getData(cct + '/' + crns[0] + '/' + this.props.course.SSBSECT_TERM_CODE)
        .then(result => {
            
            let meetings = {
                currentMeetings: [],
                pastMeetings: []
            }

            meetings = result?.meetings?.reduce((meetingsObject, meeting) => {
                if(moment(meeting.start_time).add(meeting.duration, 'minutes').diff(moment(), 'minutes') < 0)
                    meetingsObject.pastMeetings.push(meeting);
                else
                    meetingsObject.currentMeetings.push(meeting);
                return meetingsObject;
            }, meetings)

            this.setState({
                ...meetings
            }, () => this.toggleIsLoading())
        })
        .catch(err => {
            console.log(err)
            this.toggleIsLoading();
        })
    }

    render() { 
        return <React.Fragment>
            <Row><Col><h4>Zoom Meetings</h4></Col></Row>
            <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Tabs
                        value={this.state.activeTab}
                        onChange={this.onTabChange}
                        aria-label="secondary tabs example"
                    >
                        <Tab value={ZoomTabMapping.currentMeeting} label="Current/Upcoming" />
                        <Tab value={ZoomTabMapping.pastMeeting} label="Past Meetings" />
                        <Tab value={ZoomTabMapping.scheduleMeeting} onClick={() => this.onTabChange('', ZoomTabMapping.scheduleMeeting)} label="Schedule Meeting" />
                    </Tabs>
                    {/* <Button variant="contained" onClick={() => this.onTabChange('', ZoomTabMapping.scheduleMeeting)}>Schedule Meeting</Button> */}
                </div>
            </Box>
            {this.state.activeTab == ZoomTabMapping.currentMeeting && <Meetings meetings = {this.state.currentMeetings} loadMeetings = {this.loadMeetings} isLoading = {this.state.isLoading} activeTab = {this.state.activeTab}/>}
            {this.state.activeTab == ZoomTabMapping.pastMeeting && <Meetings meetings = {this.state.pastMeetings} loadMeetings = {this.loadMeetings} isLoading = {this.state.isLoading} activeTab = {this.state.activeTab}/>}
            {this.state.activeTab == ZoomTabMapping.scheduleMeeting && <ScheduleMeeting onTabChange = {this.onTabChange} />}
        </React.Fragment>
    }
}

const mapStateToProps = (state) => {
    return {
        course: state.CCTReducer.course
    }
}
 
export default connect(mapStateToProps)(Zoom);