import React from "react";
import { useRouteError } from "react-router-dom";
import GenericError from "./GenericError";
import InvalidRoute from "./InvalidRoute";

const getError = (statusCode) => {

    //401 will never be caught here as it's being handled in AdminPanelRoute
    switch (statusCode) {
        case 404:
            return <InvalidRoute />
        default:
            return <GenericError />
    }
}

const Error = () => {
    let error = useRouteError();
    return <React.Fragment>
        {getError(error.status)}
    </React.Fragment>
}

export default Error