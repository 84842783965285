import axios from 'axios';
import {getImpersonationHeader} from './util';
import {authorize} from '../AWS';

// API gateway base URL from environment variable
// This value is from .env which is written during CodeBuild step
// CodeBuild gets it from CodePipeline which gets it from backend build step 
export const baseURL = process.env.REACT_APP_API_GATEWAY_BASE_URL + 'portal/';
export const baseURLPublic = process.env.REACT_APP_API_GATEWAY_BASE_URL + 'public/';

//Async method to get data from server
export const getData = async (service, isReturnError = false) => {
    return new Promise(async (resolve, reject) => {
        const Authorization = await authorize();
        if (Authorization) {
            return axios
                .get(baseURL + service, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: Authorization,
                        ...getImpersonationHeader()
                    }
                })
                .then(response => resolve(response.data))
                .catch(err => {
                    //only reject if the rejection is expected, else the app renders errors on the main page
                    if (isReturnError) reject(err.response.data);
                });
        } else reject('Login again');
    });
};

//Async method to get data from server for Public routes
export const getDataPublic = async (service, isReturnError = false) => {
    return new Promise(async (resolve, reject) => {
        return axios
            .get(baseURLPublic + service, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => resolve(response.data))
            .catch(err => {
                //only reject if the rejection is expected, else the app renders errors on the main page
                if (isReturnError) reject(err.response.data);
            });
    });
};

//Async method to post data to server
export const postData = async (service, body, isReturnError = false) => {
  const Authorization = await authorize();
  if (Authorization) {
    const response = await fetch(baseURL + service, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization,
        ...getImpersonationHeader()
      }
    }).then(data => {
      if (data.ok) return data.json();
      
      //only reject if the rejection is expected, else the app renders errors on the main page
      else if (isReturnError)
        return data.text().then(stream => JSON.parse(stream));
      
      else return false;
    });
    return response;
  }
};

//Async method to put data to server
export const putData = async (service, body, isReturnError = false) => {
  const Authorization = await authorize();
  if (Authorization) {
    const response = await fetch(baseURL + service, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization,
        ...getImpersonationHeader()
      }
    })
      .then(data => {
        if (data.ok) return data.json();
        
        //only reject if the rejection is expected, else the app renders errors on the main page
        else if (isReturnError)
          return data.text().then(stream => JSON.parse(stream));

        else return false;
      })
      .catch(e => {
        return false;
      });

        return response;
    }
};

//Async method to patch data to server
export const patchData = async (service, body, isReturnError = false) => {
  const Authorization = await authorize();
  if (Authorization) {
    const response = await fetch(baseURL + service, {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization,
        ...getImpersonationHeader()
      }
    })
      .then(data => {
        if (data.ok) return data.json();

        //only reject if the rejection is expected, else the app renders errors on the main page
        else if (isReturnError)
          return data.text().then(stream => JSON.parse(stream));

        else return false;
      })
      .catch(e => {
        return false;
      });

        return response;
    }
};

//Async method to delete data from server
export const deleteData = async (service, isReturnError = false) => {
  const Authorization = await authorize();
  if (Authorization) {
    const response = await fetch(baseURL + service, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization,
        ...getImpersonationHeader()
      }
    }).then(data => {
      if (data.ok) return data.json();
      
      //only reject if the rejection is expected, else the app renders errors on the main page
      else if (isReturnError)
        return data.text().then(stream => JSON.parse(stream));
      
      else return false;
    });
    return response;
  }
};
