import React, { Component } from 'react';
import { Box, Tooltip, Stack, Typography, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationCheck, faCircleCheck, faCircleXmark, faLocationXmark, faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { faCircleDot, faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { UncontrolledTooltip } from 'reactstrap';

class PlaceSummary extends Component {
    state = {
        isExpand: true,
        active: 0,
    }

    getIcon = () => {
        let icon = "";
        switch(this.props.data.location.operatingStatus) {
            case ("open"):
                icon = (this.props.data.location?.mapUrl) ? faLocationCheck : faCircleCheck;
                break;
            case ("closed"):
                icon = (this.props.data.location?.mapUrl) ? faLocationXmark : faCircleXmark;
                break;
            default: 
                icon = (this.props.data.location?.mapUrl) ? faLocationDot : faCircleDot;
            }
        return icon;
    }

    getCssClass = () => {
        let cssClass = (this.props.data.location.operatingStatus == "open"? "myOdu__operatingStatus open" : (this.props.data.location.operatingStatus == "closed" ?  "myOdu__operatingStatus closed" : "myOdu__operatingStatus unknown"));;
        cssClass += (this.props.data.location.mapUrl)? "" : " myOdu__operatingStatus locationDot";
        return cssClass;
    }

    getOperatingStatusMessage = () => {
        let greeting = "";
        switch(this.props.data.location.operatingStatus) {
            case ("open"):
                greeting = this.props.data.location.title + ' is open.';
                greeting += (this.props.data.location?.closesAt)? " It closes at " + this.props.location.closesAt + "." : "";
                break;
            case ("closed"):
                greeting = this.props.data.location.title + ' is closed.';
                break;
            default: 
                greeting = this.props.data.location.title + ' operating status is unknown. ';
            }

        greeting += (this.props.data.location.mapUrl)? " Open map in new window." : "";

        return greeting;
    }

    getOperatingStatus = () =>{
        return (this.props.data.location.operatingStatus == "open"? <Typography className="myOdu__operatingStatus open" variant="smallest">Open</Typography> : (this.props.data.location.operatingStatus == "closed" ?  <Typography className="myOdu__operatingStatus closed" variant="smallest">Closed</Typography> : ""));
    }

    toggleExpand = () => {
        this.setState({isExpand: !this.state.isExpand})
    }

    onUnwantedClick = e => {
        e.stopPropagation(); 
    }

    render (){
        return  <>
            <Typography component="h3" variant="p" sx={{color:'#003057', ml:1}} onClick={() => this.toggleExpand()}>
                <Stack>
                    {this.props.data.location.title}
                    <div>{this.getOperatingStatus()}</div>
                </Stack>
            </Typography>
            <Box sx={{ flexGrow: 1 }} onClick={() => this.toggleExpand()} />
           
            <Box className="" onClick={this.onUnwantedClick}>
                {/* mapUrl is to the google page, the mapIframeEmbedUrl is for the embedded map */}
                {this.props.data.location.mapUrl ? <>
                    <Tooltip title={this.getOperatingStatusMessage()} enterDelay={750} leaveDelay={0} enterNextDelay={750} arrow> 
                        <IconButton href={this.props.data.location.mapUrl} target="_blank" sx={{p:'.25rem', borderRadius: 0}} id={this.props.data.location.id + "_" + this.props.widgetId + "_locationDotButton"}>
                            <FontAwesomeIcon fixedWidth style={{marginRight: '0.25rem', marginLeft: '0.25rem'}} size="sm" id={this.props.data.location.id + "_" + this.props.widgetId + "_locationDot"} icon={this.getIcon()} className={this.getCssClass()} />
                            <div className="sr-only visuallyHidden">Open Google Map Location</div>
                        </IconButton>
                    </Tooltip>             
                    </>: <>
                        <Tooltip title={this.getOperatingStatusMessage()} enterDelay={750} leaveDelay={0} enterNextDelay={750} arrow>
                            <FontAwesomeIcon fixedWidth style={{marginRight: '0.5rem', marginLeft: '0.25rem', cursor: 'default'}} size="lg" id={this.props.data.location.id + "_" + this.props.widgetId + "_locationDot"} icon={this.getIcon()} className={this.getCssClass()} />
                        </Tooltip>
                    </>
                }
                </Box>
        </>
    }
}
export default PlaceSummary;

{/* delay={{show: '250', hide: '0'}} */}