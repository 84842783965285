import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { Stack, Grid, Box, Button, InputAdornment, Typography, Autocomplete, TextField, Card, CardActionArea} from '@mui/material';
import { connect } from 'react-redux';
import { saveLandingFilter } from '../actions';
import { logIn } from '../../../AWS';
import Apps from '../Applications/config';
import Featured from '../Featured';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/pro-light-svg-icons';

const ariaLabel = { 'aria-label': 'description' };
const textFieldCss = [{
    "& .MuiOutlinedInput-root": {
        backgroundColor: 'white',
        border: '1px solid #CECECE'
    },
    "& .MuiInputLabel-shrink": {
        display: 'none'
    },
    "& .MuiOutlinedInput-notchedOutline":{
        display: 'none'
    }
}];

class Head extends Component {
    state = {
        searchText: '',
        imgPath: '/img/logos/'
    }

    onTextChange = (e, text) => {

        this.setState({searchText: text});
    }

    onAppChange = (e, app) => {
        this.props.saveLandingFilter(app ?? '')
    }

    getGreeting = () => {
        const hours = new Date().getHours();
        let greeting = "";
        switch(true) {
            case (hours > 5 && hours < 12):
                greeting = 'Good morning!';
                break;
            case (hours >= 12 && hours < 18):
                greeting = "Good afternoon!"
                break;
            case (hours >= 18):
                greeting = "Good evening!";
                break;
                
            // midnight to 4AM is an inbetween time
            default: 
                greeting = "Hello!"; 
            }

        return greeting;
    }

    render() {
        const options = Apps.map(app => ({...app, label: app.name}))
        return <React.Fragment>
            
            <Row style={{backgroundColor: '#003057', color: 'white'}} >
               
                <Col>
                    <Typography component="h1" variant="h3" className="myOdu__stylizedText" sx={{color: 'secondary.contrastText', pl:2, pt:1}}>    
                        <span>My</span><span>ODU</span>
                    </Typography>
                </Col>
                <Col style={{textAlign: 'right'}}>
                    <Button variant='outlined' sx={{m:1}} className="myOdu__button landingSignIn" onClick={logIn}>
                        <FontAwesomeIcon icon={faCircleUser} size="lg"/>
                        <Typography sx={{ml:1}} variant="smaller">Sign In</Typography>
                    </Button>
                </Col>
            </Row>

            <div className="myOdu__decorativeLine landing" style={{width: '100%'}}>
                &nbsp;
            </div>

            <Row className="myOdu__crownBkg pb-3 pt-5 justify-content-center" style={{backgroundColor: "#003057"}}>
                <Col xl={11}>
                    <Row className="px-2"><Col><Typography component="h2" variant="h5" sx={{color: 'secondary.contrastText'}}> {this.getGreeting()} How can we help?</Typography></Col></Row>
                    <Row className='pb-3 px-2'><Col>
                        <Autocomplete options={options} inputValue={this.props.searchText} onInputChange={this.onTextChange} value={this.props.text} onChange={this.onAppChange} renderInput={(params) => <TextField {...params} fullWidth size='small' label='Search by keyword' sx={textFieldCss} placeholder='Search by keyword' endAdornment={<InputAdornment position="end"><i className='fal fa-magnifying-glass />' /></InputAdornment>}/>} renderOption={(props, option, state) => 
                            <Card sx={{p:1, borderRadius: '0'}} alignItems="center">
                                <CardActionArea href={option.link}>
                                    {/* <Grid alignItems="center">
                                        <Grid item> */}
                                            <Box sx={{float:'left', mr: 1, backgroundColor: 'white'}}>
                                                <Box sx={{height: '40px', width: '40px', margin: '0 auto', backgroundColor: 'white'}}>
                                                    <img src={this.state.imgPath + option.image} alt={option.name} />
                                                </Box>
                                            </Box>
                                            {option.name}
                                        {/* </Grid>
                                    </Grid> */}
                                </CardActionArea>
                            </Card>} />                        
                    </Col></Row>
                    <Row><Col><Featured/></Col></Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="myOdu__trapezoid" style={{backgroundColor: "#003057"}}>
                        <span className="sr-only visally-hidden"></span>
                    </div>
                </Col>
            </Row>

        </React.Fragment>
    }
}
 
const mapStateToProps = (state) => {
    return {
        text: state.landingReducer.text
    }
  }
  
const mapDispatchToProps = (dispatch) => ({
    saveLandingFilter: (text) => dispatch(saveLandingFilter(text))
});
  
export default connect(mapStateToProps, mapDispatchToProps)(Head);