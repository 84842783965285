import React, {Component} from 'react';
import {
  Badge,
  Box,
  Link,
  List,
  ListItem,
  Stack,
  Typography,
  Skeleton
} from '@mui/material';
import Alert from './Alert';
import {ALERT_KEY_FINANCIAL_AID} from '../common/constants';

class AlertCategory extends Component {
  state = {};

  render() {
    return (
      <>
        <Box sx={{mb: 2}}>
          <Stack direction="row" sx={{mb: 0}} alignItems="center">
            <Typography
              sx={{color: 'primary.main', mb: 0}}
              variant="h5"
              component="h3"
            >
              {this.props.title}
            </Typography>

            {this.props.data.length > 0 && (
              <Badge
                badgeContent={this.props.data.length}
                max={10}
                className="myOdu__badge alertBadge"
                sx={{ml: 1, mr: 2}}
                style={{marginTop: '-1rem'}}
              />
            )}
            {this.props.alertKey === ALERT_KEY_FINANCIAL_AID &&
              this.props.hiddenFinancialAidLength > 0 && (
                <Link
                  variant="smallest"
                  component="button"
                  onClick={this.props.toggleIsShowHiddenAlerts}
                  underline="always"
                  color="inherit"
                  sx={{ml: 1, cursor: 'pointer'}}
                >
                  {this.props.hiddenFinancialAidLength +
                    ' Alert' +
                    (this.props.hiddenFinancialAidLength > 1 ? 's' : '') +
                    ' Hidden'}
                </Link>
              )}
          </Stack>

          <Box className="myOdu__box" sx={{m: 0, pt:1, pb:1}}>
            <List sx={{p: 0}}>
              {this.props.isLoading && <Skeleton variant="rectangular" />}
              {!this.props.isLoading &&
                this.props.data.length > 0 &&
                this.props.data.map((item, index) => {
                  return (
                    <ListItem sx={{pr: 0, pl: 0}} key={index} divider className="myOdu__statusAlert">
                      <Alert
                        item={item}
                        index={index}
                        alertKey={this.props.alertKey}
                        action={this.props.action}
                        toggleAlertVisibility={this.props.toggleAlertVisibility}
                      />
                    </ListItem>
                  );
                })}
            </List>
            {!this.props.isLoading && this.props.data.length === 0 && (
              <Typography component="p">
                {'No ' + this.props.title + '.'}
              </Typography>
            )}
          </Box>
        </Box>
      </>
    );
  }
}

export default AlertCategory;
