import React, { Component } from 'react';
import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';

class Menu extends Component {
    state = {  } 
    render() { 
        return <React.Fragment>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
                <Card sx={{ maxWidth: 345 }}>
                    <CardActionArea onClick={this.props.onToggleClassMeetings}>
                        <CardMedia
                            component="img"
                            height="140"
                            image="/img/logos/zoom.svg"
                            alt="Class Meeting"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Class Meeting
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                --placeholder text-- Create meeting(s) for the class according to class schedule for the entire semester 
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
                <Card sx={{ maxWidth: 345 }}>
                    <CardActionArea onClick={this.props.onToggleAdhoc}>
                        <CardMedia
                            component="img"
                            height="140"
                            image="/img/logos/gmail.svg"
                            alt="Adhoc Meeting"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Adhoc Meeting(s)
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                --placeholder text-- Create custom meeting(s) which can also repeat daily, weekly or monthly
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Box>
        </React.Fragment>
    }
}
 
export default Menu;