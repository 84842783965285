import React, { Component } from 'react';
import { Grid, List, ListItem, Box, Link, Stack, Typography, Tooltip, Card, CardContent, IconButton} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationCheck, faLocationXmark } from '@fortawesome/pro-solid-svg-icons';
import { faCircleInfo, faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { UncontrolledTooltip } from 'reactstrap';
import PlaceMap from './PlaceMap';

class PlaceDetails extends Component {
    render (){
        return <Box className="myOdu__box" sx={{p:0, m:0}}>
            <Stack>
                {this.props.data.location.mapIframeEmbedUrl &&
                    <PlaceMap mapIframeEmbedUrl={this.props.data.location.mapIframeEmbedUrl}/>
                }
                                        
                <Stack direction="row" justifyContent="space-between" sx={{mt:1, px:1}}>
                    {this.props.data.location.mapUrl ? 
                        <Link variant="smallest" href={this.props.data.location.mapUrl} sx={{fontStyle: 'italic'}} underline="hover" title="Opens in new window" target="_blank">{this.props.data.location.address}</Link>
                    :
                        <Typography variant="smallest" sx={{fontStyle: 'italic}'}}>{this.props.data.location.address}</Typography>
                    }
                    {this.props.data.location.url && 
                    
                        <Tooltip title={"Open " + this.props.data.location.title + " webpage in new window"}  enterDelay={750} leaveDelay={0} enterNextDelay={750} arrow>
                            <IconButton component={Link} href={this.props.data.location.url} target="_blank" size="small" id={this.props.widgetId + "_" + this.props.data.location.id + "_moreInfoButton"}>
                                <FontAwesomeIcon fixedWidth icon={faCircleInfo} size="sm"/>
                            </IconButton>
                        </Tooltip>
                    }
                </Stack>

                {this.props.data.location.hours && <List sx={{p:1, borderTop: '1px solid #CECECE'}}>
                    {this.props.data.location.hours.map((item, index) =>
                        <ListItem sx={{p:0}} key={"placeDetails_hoursList_" + this.props.data.location.id + "_" + index}>
                            <Stack direction="row" justifyContent={'space-between'} spacing={2} className="myOdu__hover" sx={{width: '100%'}}>
                                <Typography variant="smallest">
                                    {item.title}
                                    {item.isOnline && <Typography sx={{fontStyle: "italic"}} variant="smallest"> (Online) </Typography>}
                                </Typography>
                                <Typography variant="smallest">{item.times}</Typography>
                            </Stack>
                        </ListItem>
                        )}
                </List>}
            </Stack>

        </Box>
    }
}
export default PlaceDetails;