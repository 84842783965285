import React, { Component } from 'react';
import Widget from '../common/Widget';
import { Grid } from '@mui/material';
import BalanceBox from "./BalanceBox";
import { connect } from 'react-redux';
import { faIdCardClip, faWashingMachine } from '@fortawesome/pro-light-svg-icons';
import { cardCenterMonarch } from '../common/constants';

class MyBalances extends Component {
    state = {
        isExpand: true,
        widgetId: 'widget__myBalances',
        isDisplayLaundryBalance: false,
        isDisplayMonarchBalance: false
    }

    toggleIsDisplayLaundryBalance = () => {
        this.setState({
            isDisplayLaundryBalance: !this.state.isDisplayLaundryBalance
        })
    }

    toggleIsDisplayMonarchBalance = () => {
        this.setState({
            isDisplayMonarchBalance: !this.state.isDisplayMonarchBalance
        })
    }

    render() {
        return <>
            <Widget data={{ title: "myBalances", menu: this.state.menu, id: this.state.widgetId }} className="myOdu__myBalances" {...this.props}>
                <div className="myOdu__myBalances_wrapper pb-3">
                    <Grid sx={{width: '100%'}} container className="" wrap='nowrap' direction='row' rowSpacing={2}>
                      <Grid item className="balanceGridItem">
                              <BalanceBox 
                                isDisplayBalance={this.state.isDisplayLaundryBalance} 
                                toggleIsDisplayBalance={this.toggleIsDisplayLaundryBalance} 
                                depositLink={cardCenterMonarch} 
                                balance={this.props.laundryAccount?.amount ?? "NA"} 
                                icon="/img/logos/laundry.svg" 
                                alt="Laundry"
                                title="Laundry"
                            />
                        </Grid>
                        <Grid item className="balanceGridItem">
                            <BalanceBox 
                                isDisplayBalance={this.state.isDisplayMonarchBalance} 
                                toggleIsDisplayBalance={this.toggleIsDisplayMonarchBalance} 
                                depositLink={cardCenterMonarch} 
                                balance={this.props.monarchPlus?.amount ?? "NA"} 
                                icon="/img/monarchCard.svg" 
                                alt="Monarch Card" 
                                title="Monarch Plus"
                            />
                        </Grid>
                    </Grid>
                </div>
            </Widget>
        </>
    }
}

const mapStateToProps = (state) => {
    return {
        laundryAccount: state.myDiningReducer.laundryAccount,
        monarchPlus: state.myDiningReducer.monarchPlus
    }
}

export default connect(mapStateToProps)(MyBalances);