import { SAVE_LANDING_FILTER } from "./actions";

const initialStateLanding = {
    text: '',
}

//needs to persist across pages
export const landingReducer = (state = initialStateLanding, action) => {
    const { type, payload } = action;
    switch (type) {
        case SAVE_LANDING_FILTER: {
            return {...state, text: payload.text}
        }
        default:
            return state;
    }
}