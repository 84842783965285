import React, {Component} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {getData, postData} from '../../DataAccessLayer';
import {myCourses, terms} from '../../DataAccessLayer/services';
import Widget from '../common/Widget';
import {getTakingColumns, getTeachingColumns} from './columns';
import moment from 'moment';
import {
    Tab,
    Tabs,
    Box,
    Link,
    Tooltip,
    FormGroup,
    Switch,
    Stack,
    Typography,
    FormControl,
    Divider,
    InputLabel,
    Select,
    MenuItem,
    LinearProgress,
    Skeleton
} from '@mui/material/';
import TabPanel from '../common/TabPanel';
import CCT from './CCT';
import {connect} from 'react-redux';
import {toggleCCT} from './CCT/actions';
import {saveMyCoursesTaking, saveMyCoursesTeaching} from './actions';
import CctComingSoonModal from './CctComingSoonModal';
import {CURRENT_TERM} from '../common/constants';
import {saveTerms} from './Terms/actions';
import PortalTooltip from '../common/PortalTooltip';
import { columnsToHideForMobile } from './config';
import { WithSmallScreen } from '../common/WithSmallScreen';

// function TabPanel(props) {
//     const { children, value, index, ...other } = props;

//     return (
//       <Box
//         component="div"
//         role="tabpanel"
//         hidden={value !== index}
//         id={`scrollable-auto-tabpanel-${index}`}
//         aria-labelledby={`scrollable-auto-tab-${index}`}
//         {...other}
//       >
//         <Box p={0} pt={1}>{children}</Box>
//       </Box>
//     );
//   }

//   TabPanel.propTypes = {
//     children: PropTypes.node,
//     index: PropTypes.any.isRequired,
//     value: PropTypes.any.isRequired
//   };

class MyCourses extends Component {
    state = {
        widgetId: 'widget__myCourses',
        isTitleStylized: true,
        title: ['my', 'Courses'],
        isExpand: true,
        isLoading: false,
        teachingCourses: {},
        takingCourses: {},
        isTeachingSelected: true,
        selectedTab: 0,
        isCctModalOpen: false,
        terms: [],
        innerWidth: window.innerWidth
        //TODO: enable in V1.1
        // menu: [
        //     {id: 'myCourses__hide', title: 'Hide'}
        // ]
    };

    toggleIsLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading
        });
    };

    toggleCctIsModalOpen = () => {
        this.setState({isCctModalOpen: !this.state.isCctModalOpen});
    };

    componentDidMount() {
        this.loadTermCourseDetails();
    }

    setActiveTab = terms => {
        const currentTermIndex = terms?.findIndex(
            term => term.relativeTerm === CURRENT_TERM
        );
        this.setState({
            selectedTab: currentTermIndex === -1 ? 0 : currentTermIndex
        });
    };

    //Θ(1) Loads courses from backend
    loadTermCourseDetails = () => {
        this.toggleIsLoading();
        if (
            !this.props.termsUpdatedAt ||
            moment(this.props.termsUpdatedAt).format('YYYY-MM-DD') !==
                moment().format('YYYY-MM-DD')
        ) {
            getData(terms)
                .then(result => {
                    const terms = result?.terms;
                    this.setState({terms});
                    this.props.saveTerms(terms);
                    this.setActiveTab(terms);
                    this.loadCourses(terms)
                        .then(result => result)
                        .catch(err => {
                            console.log(err);
                        });
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.toggleIsLoading();
                });
        } else {
            this.setState({terms: this.props.terms});
            this.setActiveTab(this.props.terms);
            this.loadCourses(this.props.terms)
                .then(result => result)
                .catch(err => {
                    console.log(err);
                })
                .finally(() => this.toggleIsLoading());
        }
    };

    //Θ(1) Loads courses from backend
    loadCourses = terms => {
        return new Promise((resolve, reject) => {
            postData(
                myCourses,
                terms?.map(term => term.code)
            )
                .then(courses => {
                    this.setState({
                        courses,
                        isTeachingSelected: !!courses.teaching?.length
                    });
                    this.props.saveMyCoursesTaking(courses.taking);
                    this.props.saveMyCoursesTeaching(courses.teaching);
                    const finalCourses = terms.reduce(
                        (prev, term) => {
                            const teachingCourses = courses.teaching.filter(
                                course => course.SSBSECT_TERM_CODE === term.code
                            );
                            const takingCourses = courses.taking.filter(
                                course => course.ACADEMIC_PERIOD_5 === term.code
                            );
                            prev.teachingCourses[term.code] = teachingCourses;
                            prev.takingCourses[term.code] = takingCourses;
                            return prev;
                        },
                        {teachingCourses: {}, takingCourses: {}}
                    );
                    this.setState({
                        takingCourses: finalCourses.takingCourses,
                        teachingCourses: finalCourses.teachingCourses
                    });
                    resolve();
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    toggleIsTeaching = (event, isTeachingSelected) => {
        this.setState({isTeachingSelected: !isTeachingSelected});
    };

    setSelectedTab = (event, newValue) => {
        this.setState({selectedTab: newValue});
    };

    render() {
        return (
            <React.Fragment>
                {/* TODO: Hiding CCT component for Portal Beta version
                <CCT /> 
            */}
                <CctComingSoonModal
                    isOpen={this.state.isCctModalOpen}
                    onClose={this.toggleCctIsModalOpen}
                />
                <Widget
                    data={{
                        id: this.state.widgetId,
                        isTitleStylized: this.state.isTitleStylized,
                        title: this.state.title,
                        menu: this.state.menu
                    }}
                    {...this.props}
                >
                    {this.state.isLoading && (
                        <Skeleton variant="rectangular" height={98} />
                    )}
                    {!this.state.isLoading && (
                        <div>
                            {this.state.courses?.teaching?.length > 0 &&
                                this.state.courses?.taking?.length > 0 && (
                                    <>
                                        <Stack
                                            direction="row"
                                            justifyContent={'flex-start'}
                                            sx={{
                                                width: '100%',
                                                backgroundColor: '#FAFAFA',
                                                pl: 1
                                            }}
                                        >
                                            <FormGroup>
                                                <Stack
                                                    direction="row"
                                                    spacing={1}
                                                    alignItems="center"
                                                >
                                                    <label className="myOdu__toggleSwitchLabel">
                                                        <Typography
                                                            variant="smaller"
                                                            id="coursesViewByLabel"
                                                        >
                                                            Courses
                                                        </Typography>
                                                    </label>
                                                    <Typography variant="smaller">
                                                        Teaching
                                                    </Typography>
                                                    <Switch
                                                        id="myCourses__switch_toggleTeachingTaking"
                                                        size="small"
                                                        color="primary"
                                                        inputProps={{
                                                            'aria-label':
                                                                'Courses'
                                                        }}
                                                        value={
                                                            this.state
                                                                .isTeachingSelected
                                                        }
                                                        onChange={
                                                            this
                                                                .toggleIsTeaching
                                                        }
                                                    />
                                                    <Typography variant="smaller">
                                                        Taking
                                                    </Typography>
                                                </Stack>
                                            </FormGroup>
                                        </Stack>
                                    </>
                                )}
                            <Divider />
                            <div>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: 'divider'
                                    }}
                                >
                                    <Tabs
                                        className="myOdu__tabs"
                                        value={this.state.selectedTab}
                                        onChange={this.setSelectedTab}
                                        aria-label={'myCourses_tabs'}
                                    >
                                        {this.state.terms.map((term, index) => (
                                            <PortalTooltip
                                                key={
                                                    'myCourses__tab_tooltip_' +
                                                    index
                                                }
                                                title={`Academic Year ${term.code.substring(
                                                    0,
                                                    4
                                                )}`}
                                            >
                                                <Tab
                                                    key={
                                                        'myCourses__tab_' +
                                                        index
                                                    }
                                                    label={term.title}
                                                    id={
                                                        'myCourses__tab_' +
                                                        term.code
                                                    }
                                                    aria-controls={
                                                        'myCourses__tabpanel_' +
                                                        term.code
                                                    }
                                                />
                                            </PortalTooltip>
                                        ))}
                                    </Tabs>
                                </Box>

                                {this.state.terms.map((term, index) => {
                                    return (
                                        <TabPanel
                                            key={'myCourses__tabPanel_' + index}
                                            value={this.state.selectedTab}
                                            index={index}
                                            sx={{pt: 2, px: 0}}
                                            className="myOdu__tabPanel"
                                        >
                                            <Box
                                                sx={{
                                                    overflow: 'hidden',
                                                    flexDirection: 'column',
                                                    pt: 2
                                                }}
                                            >
                                                <DataGrid
                                                    rows={
                                                        (this.state
                                                            .isTeachingSelected
                                                            ? this.state
                                                                  .teachingCourses[
                                                                  term.code
                                                              ]
                                                            : this.state
                                                                  .takingCourses[
                                                                  term.code
                                                              ]) ?? []
                                                    }
                                                    columns={(this.state
                                                        .isTeachingSelected
                                                        ? getTeachingColumns(
                                                              term.relativeTerm
                                                          )
                                                        : getTakingColumns(
                                                              term.relativeTerm
                                                          )
                                                    ).map(col => {
                                                        // TODO: Commenting the CCT part for portal beta version
                                                        // if(col.isClickable){
                                                        //     col = {...col, onClick: (course) => this.props.toggleCCT(course)}
                                                        // }
                                                        if (col.isClickable) {
                                                            col = {
                                                                ...col,
                                                                onClick: () =>
                                                                    this.toggleCctIsModalOpen()
                                                            };
                                                        }
                                                        return col;
                                                    })}
                                                    hideFooter
                                                    density="compact"
                                                    getRowId={row =>
                                                        this.state
                                                            .isTeachingSelected
                                                            ? row.CRNS
                                                            : row.COURSE_REFERENCE_NUMBER_5
                                                    }
                                                    sx={{
                                                        m: 0,
                                                        overflow: 'hidden',
                                                        "& .MuiDataGrid-row--lastVisible .MuiDataGrid-cell": {
                                                            borderBottomColor: 'transparent'
                                                        }
                                                    }}
                                                    // TODO: Commenting the CCT part for portal beta version
                                                    // onClick={(course) => this.props.toggleCCT(course)}
                                                    onClick={() =>
                                                        this.toggleCctIsModalOpen()
                                                    }
                                                    loading={
                                                        this.state.isLoading
                                                    }
                                                    slots={{
                                                        loadingOverlay:
                                                            LinearProgress
                                                    }}
                                                    columnVisibilityModel = {this.props.isSmall ? columnsToHideForMobile : {}}
                                                />
                                            </Box>
                                        </TabPanel>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </Widget>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        terms: state.termsReducer.terms,
        termsUpdatedAt: state.termsReducer.updatedAt
    };
};

const mapDispatchToProps = dispatch => ({
    saveMyCoursesTaking: coursesTaking =>
        dispatch(saveMyCoursesTaking(coursesTaking)),
    saveMyCoursesTeaching: coursesTeaching =>
        dispatch(saveMyCoursesTeaching(coursesTeaching)),
    saveTerms: terms => dispatch(saveTerms(terms)),
    toggleCCT: course => dispatch(toggleCCT(course))
});

export default connect(mapStateToProps, mapDispatchToProps)(WithSmallScreen(MyCourses));
