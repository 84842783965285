import React, { Component } from 'react';

import { savePreferences, saveSetup } from './actions';
import { connect } from 'react-redux';
import { Container, Col, Row } from 'reactstrap';
import { DialogTitle, DialogContent, Dialog, DialogActions, Typography, Button, Box, Stack } from '@mui/material';
import Questions from './Questions';
import Apps from './Apps';
import { AppConfigs, DefaultWidgetOrder, WidgetConfigs } from './config';
import { putData } from '../../DataAccessLayer';
import { myPreferences } from '../../DataAccessLayer/services';
import { CAMPUS, HOUSING } from '../common/constants';

class SetUp extends Component {
    state = {

    }

    componentDidMount() {
        this.loadAppsToDisplay()
    }

    //Θ(N) where N is the length of apps to be displayed
    //Updates the answers of the questions of setup
    onAnswersChanged = (questionChanged) => {
        let setup = {...this.props.setup}
        setup?.appsToDisplay?.forEach(app => {

            switch(questionChanged) {
                case CAMPUS:
                    if(app?.preSelected?.campus) {
                        if(app?.preSelected?.campus?.some((item) => (setup.campus && item in setup.campus))) {
                            setup['Apps'] = {...(setup?.Apps ?? {}), [app.id]: true}
                        } else if(setup?.Apps && app.id in setup?.Apps){
                            delete setup.Apps[app.id]
                        }
                    }
                    break;
                case HOUSING:
                    if(app?.preSelected?.isHousing) {
                        if(setup?.isHousing) {
                            setup['Apps'] = {...(setup?.Apps ?? {}), [app.id]: true}
                        }
                        else if(!setup?.isHousing && setup?.Apps && app.id in setup?.Apps) {
                            delete setup?.Apps[app.id];
                        }
                    }
                    break;
                default:
                    break;
            }
        });
        this.props.saveSetup(setup);
    }
    
    //Θ(1) Creates an array of application to be displayed based on campus and user role
    loadAppsToDisplay = () => {
        let setup = {...this.props.setup}
        
        setup['appsToDisplay'] = AppConfigs.Apps;
        setup['Version'] = AppConfigs.Version
        setup = this.selectRoleBasedInitialApps(setup)

        this.props.saveSetup(setup);
    }

    //Θ(N) where N is length of apps to be displayed
    //Pre-selects the applications based on configs and user role
    selectRoleBasedInitialApps = (setup) => {
        setup?.appsToDisplay
        ?.forEach(app => {
            if(!(app.id in (setup?.Apps ?? {}))) {
                if(app.isDefaultSelected || app?.preSelected?.role?.some((item) => item in (this.props.isImpersonating ? this.props.impersonation?.roles ?? {} : this.props.user?.roles ?? {}))) {
                    setup['Apps'] = {...(setup?.Apps ?? {}), [app.id]: true}
                    return;
                }
            }
        })
        return setup;
    }

    //Θ(1) Saves prefereneces
    onSave = () => {
        let preferences = {
            Apps: {
            ...this.props.setup,
            },
            Widgets: {
                Version: WidgetConfigs.Version, WidgetsOrder: DefaultWidgetOrder
            }
        };
        
        storePreferences(preferences, this.props)
    }

    //Θ(1) Skips the preferences selection
    onSkipOrCancel = () => {

        //onCancel
        if(this.props.setup.reset) {
            //Reset is a copy of preferences created during reset. On cancellation of reset the preferences are restored via this copy
            this.props.savePreferences({...this.props.setup.reset})
            this.props.saveSetup({})
            return;
        }

        //Skip and Customize later
        let preferences = {
            Apps: {
                Apps: {},
                Version: AppConfigs.Version
            },
            Widgets: {
                Version: WidgetConfigs.Version, WidgetsOrder: DefaultWidgetOrder
            }
        }
        
        storePreferences(preferences, this.props)
    }

    //Θ(1) Saves the preferences
    savePreferences = (preferences) => {
        delete preferences?.Apps?.appsToDisplay;
        delete preferences?.Apps?.reset

        putData(myPreferences, {preferences, midas: this.props.isImpersonating ? this.props.impersonation.midas : this.props.user.midas})
        .then(_ => {
            this.props.savePreferences(preferences);
            this.props.saveSetup({});
        })
        .catch(err => console.log(err)) 
    }
    
    render() {
        return <React.Fragment>
                <Dialog open fullWidth maxWidth='xl' className="myOdu__wizard">
                    <DialogTitle sx={{backgroundColor: '#003057', color: 'white', borderBottom: "3px solid #2188A2"}}>
                        <Typography variant="h4" component="h2">{'Welcome ' + (this.props.isImpersonating ? this.props.impersonation.firstName : this.props.user?.firstName)+ '!'}</Typography>
                        <Typography component="p" variant="small">Customize the apps you see on myODU! We've picked some for you to start.</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Row className="pt-2">
                            <Col md={4} sm={5} className="">
                                <Questions onAnswersChanged = {this.onAnswersChanged}/>
                            </Col>
                            <Col md={8} sm={7} className="">
                                <Apps/>
                            </Col>
                        </Row>
                    </DialogContent>
                    <DialogActions>
                        <Stack sx={{width: '100%'}} direction={{xs:'col', sm:'row'}} alignItems={'center'} justifyContent={"flex-end"}>
                            <Button variant='outlined' className="myOdu__button primary setUpButton" sx={{mr:1, mt:1}} onClick={this.onSave} disabled={!Object.keys(this.props.setup?.Apps ?? {}).length}>Next</Button>
                            <Button variant='outlined' className="myOdu__button secondary setUpButton" onClick={this.onSkipOrCancel}>{ this.props.setup?.reset ? 'Cancel' : 'Skip and Customize Later'}</Button>
                        </Stack>
                    </DialogActions>
                </Dialog>
        </React.Fragment>
    }
}

export const storePreferences = (preferences, props) => {
    delete preferences?.Apps?.appsToDisplay;
    delete preferences?.Apps?.reset
    putData(myPreferences, {preferences, midas: props.isImpersonating ? props.impersonation.midas : props.user.midas})
    .then(_ => {
        props.savePreferences(preferences);
        props.saveSetup({});
    })
    .catch(err => console.log(err)) 
}
 
const mapStateToProps = (state) => {
    return {
        user: state.AWSReducer.user,
        setup: state.setupReducer.setup,
        isImpersonating: state.impersonationReducer.impersonation?.isImpersonating ?? false,
        impersonation: state.impersonationReducer.impersonation
    }
  }
  
const mapDispatchToProps = (dispatch) => ({
    savePreferences: (preferences) => dispatch(savePreferences(preferences)),
    saveSetup: (setup) => dispatch(saveSetup(setup))
});
  
export default connect(mapStateToProps, mapDispatchToProps)(SetUp);