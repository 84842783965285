import {SAVE_CHECKLISTS} from './actions';

const initialStateChecklists = {
    checklists: null
};

// Persists checklists across pages
export const myChecklistsReducer = (state = initialStateChecklists, action) => {
    const {type, payload} = action;
    switch (type) {
        case SAVE_CHECKLISTS: {
            const checklists = payload.checklists;
            return {...state, checklists: checklists};
        }
        default:
            return state;
    }
};
