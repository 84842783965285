import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Badge,
    Typography,
    Breadcrumbs,
    Link,
    List,
    ListItem,
    Skeleton,
    Stack
} from '@mui/material';
import {Container} from 'reactstrap';
import PortalTooltip from '../common/PortalTooltip';

class Checklists extends Component {
    state = {
        isLoading: false
    };

    componentDidMount() {
        if (!this.props.checklists) {
            this.toggleIsLoading(true);
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.checklists !== this.props.checklists &&
            this.props.checklists
        ) {
            this.toggleIsLoading(false);
        }
    }

    toggleIsLoading = isLoading => {
        this.setState({isLoading});
    };

    render() {
        return (
            <>
                <Container className="myOdu__container_maxWidth" style={{marginTop: '2rem'}}>
                    <Typography
                        sx={{color: 'primary.main'}}
                        component="h2"
                        variant="h3"
                    >
                        My Checklists
                    </Typography>

                    <Breadcrumbs aria-label="breadcrumb" sx={{mb: 2}}>
                        <Link
                            underline="hover"
                            color="inherit"
                            href="/"
                            sx={{cursor: 'pointer'}}
                        >
                            Dashboard
                        </Link>
                        <Typography color="text.primary">Checklists</Typography>
                    </Breadcrumbs>
                    {this.state.isLoading && <Skeleton variant="rectangular" />}
                    {!this.state.isLoading &&
                        this.props.checklists?.length > 0 && (
                            <List sx={{pt: 0, pl: 2, listStyleType: 'disc'}}>
                                {this.props.checklists.map(checklist => {
                                    const code = checklist.checklist_time_code;
                                    return (
                                        <ListItem
                                            key={code + '_listitem'}
                                            sx={{
                                                p: 0,
                                                display: 'list-item'
                                            }}
                                        >
                                            <Stack direction="row">
                                                <Link
                                                    variant="small"
                                                    href={'/checklists/' + code}
                                                    underline="always"
                                                    color="inherit"
                                                    id={code}
                                                    sx={{
                                                        mr: 1,
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    {checklist.checklist_desc}
                                                </Link>
                                                <PortalTooltip
                                                    title={`${checklist.checklist_items_completed} items checked`}
                                                >
                                                    <Badge
                                                        badgeContent={
                                                            checklist.checklist_items_completed
                                                        }
                                                        max={10}
                                                        sx={{cursor: 'pointer'}}
                                                        className="myOdu__badge checklistBadge"
                                                    />
                                                </PortalTooltip>
                                            </Stack>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        )}
                    {!this.state.isLoading &&
                        this.props.checklists?.length === 0 && (
                            <Typography component="p">
                                No checklist found.
                            </Typography>
                        )}
                </Container>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        checklists: state.myChecklistsReducer.checklists
    };
};

export default connect(mapStateToProps)(Checklists);
