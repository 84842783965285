import React, {Component} from 'react';
import {
  DialogTitle,
  DialogContent,
  Dialog,
  Typography,
  List,
  ListItem,
  IconButton,
  Skeleton,
  Box
} from '@mui/material';
import Alert from './Alert';
import {Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faX} from '@fortawesome/pro-light-svg-icons';
import {ACTION_UNHIDE, ALERT_KEY_FINANCIAL_AID} from '../common/constants';

class HiddenAlerts extends Component {
  state = {};

  componentDidMount() {
    if (this.props.hiddenAlerts?.length === 0) {
      this.props.toggleIsShowHiddenAlerts();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.hiddenAlerts != this.props.hiddenAlerts &&
      this.props.hiddenAlerts?.length === 0
    ) {
      this.props.toggleIsShowHiddenAlerts();
    }
  }

  render() {
    return (
      <Dialog
        open
        fullWidth
        maxWidth="xl"
        className="myOdu__modal"
        onClose={this.props.toggleIsShowHiddenAlerts}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#003057',
            color: 'white',
            borderBottom: '3px solid #2188A2'
          }}
        >
          <Row>
            <Col>
              <Typography variant="h4" component="h3">
                Hidden Alerts
              </Typography>
            </Col>
            <Col xs={1} style={{display: 'flex', justifyContent: 'right'}}>
              <IconButton
                size="small"
                onClick={this.props.toggleIsShowHiddenAlerts}
              >
                <FontAwesomeIcon icon={faX} style={{color: '#ffffff'}} />
              </IconButton>
            </Col>
          </Row>
        </DialogTitle>
        <DialogContent>
          <List sx={{p: 0}}>
            {this.props.hiddenAlerts.length > 0 &&
              this.props.hiddenAlerts.map((item, index) => {
                return (
                  <ListItem sx={{pr: 0, pl: 0}} key={index} divider>
                    {!this.props.isLoading ? (
                      <Alert
                        item={item}
                        index={index}
                        alertKey={ALERT_KEY_FINANCIAL_AID}
                        action={ACTION_UNHIDE}
                        expand={true}
                        toggleAlertVisibility={this.props.toggleAlertVisibility}
                      />
                    ) : (
                      <Box sx={{width: '100%'}}>
                        <Skeleton variant="rectangular" height={20} />
                      </Box>
                    )}
                  </ListItem>
                );
              })}
          </List>
        </DialogContent>
      </Dialog>
    );
  }
}

export default HiddenAlerts;
