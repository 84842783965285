import React, {Component} from 'react';
import {Grid, Typography} from '@mui/material';
import Widget from '../common/Widget';
import ContactCard from '../common/ContactCard';
import {getData} from '../../DataAccessLayer';
import {myAdvisingTeam} from '../../DataAccessLayer/services';

class MyAdvisingTeam extends Component {
    state = {
        advisors: [],
        widgetId: 'widget__myAdvisingTeam',
        isExpand: true,
        menu: null
        // [{id: 'myAdvisingTeam_hide', title: 'Hide'}]
    };

    componentDidMount() {
        this.loadAdvisingTeam();
    }

    loadAdvisingTeam = async () => {
        try {
            const data = await getData(myAdvisingTeam);
            this.setState({
                advisors: data
            });
        } catch (err) {
            this.setState({advisors: []});
        }
    };

    reorderName = name => {
        const [firstName, lastName] = name.split(' ');

        return `${lastName}, ${firstName}`;
    };

    render() {
        const {widgetId, menu, advisors} = this.state;
        return (
            <>
                <Widget
                    data={{
                        title: 'myAdvisingTeam',
                        id: widgetId,
                        menu: menu
                    }}
                    {...this.props}
                >
                    <Grid
                        container
                        alignItems={'stretch'}
                        sx={{p: 0}}
                        spacing={1}
                    >
                        <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={6}
                            xs={12}
                            className="myOdu__advisingTeam_list_wrapper"
                        >
                            {advisors.length > 0 ? (
                                <ul style={{listStyleType: 'none', padding: 0}}>
                                    {advisors.map(advisor => {
                                        const contact = {
                                            id: advisor.MIDAS_ID,
                                            firstName: advisor.firstName
                                                ? advisor.firstName
                                                : advisor.NAME.split(' ')[0],
                                            lastName: advisor.lastName
                                                ? advisor.lastName
                                                : advisor.NAME.split(' ')[1],
                                            email: advisor.EMAIL_ID,
                                            department: advisor.department,
                                            phoneNumber: advisor.phoneNumber,
                                            img: advisor.img,
                                            directoryLink: advisor.directoryLink
                                        };

                                        return (
                                            <li
                                                key={contact.id}
                                                style={{marginBottom: 10}}
                                            >
                                                <ContactCard data={contact} />
                                            </li>
                                        );
                                    })}
                                </ul>
                            ) : (
                                <Typography component="p" variant="small">
                                    No advisors found.
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Widget>
            </>
        );
    }
}

export default MyAdvisingTeam;
