module.exports = {
    CRITICAL: 'Critical',
    ACTION_HIDE: 'hide',
    ACTION_UNHIDE: 'unhide',
    ALERT_KEY_HOLDS: 'holds',
    ALERT_KEY_UPCOMING_HOLDS: 'upcomingHolds',
    ALERT_KEY_BALANCE: 'balance',
    ALERT_KEY_FINANCIAL_AID: 'financialAid',
    CAMPUS: 'campus',
    HOUSING: 'isHousing',
    ICON_PATH: '/img/logos/',
    MOVE_WIDGET_UP: 'UP',
    MOVE_WIDGET_DOWN: 'DOWN',
    DayMapping: {
        M: 'Monday',
        T: 'Tuesday',
        W: 'Wednesday',
        R: 'Thursday',
        F: 'Friday',
        S: 'Saturday',
        U: 'Sunday'
    },
    zoomDevEmail: 'knavulur@365dev.odu.edu',
    CHECKLIST_YES_INDICATOR: 'Y',
    SYSTEM_STATUS_IDENTIFIED: 'identified',
    SYSTEM_STATUS_FIXED: 'fixed',
    adminGroupName: 'ITS Student360Admins',
    powerUserGroupName: 'ITS Student360Admins',
    GOOGLE_ANALYTICS_KEY: 'GTM-5HPKDTRG',
    mealsThreshold: '10',
    flexPointsThreshold: '50',
    MAIN_BRANCH: 'main',
    CURRENT_TERM: 'CURRENT_TERM',
    cardCenterMonarch: "https://cardcenter.odu.edu/monarch",
};
