import React, {Component} from 'react';
import Widget from '../common/Widget';

import {Link, Box, Typography, Alert, Grid} from '@mui/material';
import {makeTitle, getClickableIcon} from './utils';
import PortalTooltip from '../common/PortalTooltip';

class ScheduleItem extends Component {
    render() {
        return (
            <>
                <Grid
                    key={'mySchedule_item_' + this.props.index}
                    alignItems={'stretch'}
                >
                    {/* <Box sx={{width: '100%', height:'100%'}}> */}
                    <Alert
                        className="itemBox"
                        variant="outlined"
                        severity="info"
                        sx={{
                            border: '1px solid #EAEBEB',
                            height: '100%',
                            mr: 2
                        }}
                        icon={getClickableIcon({
                            id: this.props.item.id,
                            isSoon: this.props.item.isSoon,
                            isOnline: this.props.item.isOnline,
                            locationUrl: this.props.item.locationUrl
                        })}
                    >
                        <Typography component="p" variant="h6">
                            <>{this.props.item.formattedDateTime}</>
                        </Typography>

                        <PortalTooltip
                            title={makeTitle(
                                this.props.item.courseSubjectNumber,
                                this.props.item.courseTitle
                            )}
                        >
                            {makeTitle(
                                this.props.item.courseSubjectNumber,
                                this.props.item.courseTitle
                            )}
                        </PortalTooltip>

                        <Typography component="p" variant="smaller">
                            {this.props.item.locationUrl ? (
                                <PortalTooltip
                                    id={
                                        'mySchedule_' +
                                        this.props.item.id +
                                        '_tooltip'
                                    }
                                    title={
                                        this.props.item.isOnline
                                            ? 'Launch Zoom'
                                            : 'Open in new window'
                                    }
                                >
                                    <Link
                                        id={"mySchedule__item_" + id}
                                        href={this.props.item.locationUrl}
                                        target="_blank"
                                        underline="always"
                                    >
                                        {this.props.item.location}
                                    </Link>
                                </PortalTooltip>
                            ) : !this.props.item.isOnline ? (
                                this.props.item.location
                                    .split('\n')
                                    .map(location => <> {location} </>)
                            ) : (
                                <>Online</>
                            )}
                        </Typography>
                    </Alert>
                    {/* </Box> */}
                </Grid>
            </>
        );
    }
}

export default ScheduleItem;
