import React, {Component} from 'react';
import {connect} from 'react-redux';
// import {
    // RouterProvider,
// } from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {Box, Toolbar} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import SideNav from './SideNav';
import MenuToggle from './SideNav/MenuToggle';
import Impersonation from '../Impersonation';
import DevButton from '../DevButton';
import UserProfileButton from '../UserProfileButton';
import PortalToast from '../common/PortalToast';
import PortalTooltip from '../common/PortalTooltip';
import SwapBanner from '../common/SwapBanner';
// import router from './Router';
import { isUserAdmin } from './utils';
import ImpersonationBanner from '../common/ImpersonationBanner';
import WithRouter from './Router/WithRouter';

const drawerWidth = 240;

const openedMixin = theme => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
});

const closedMixin = theme => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`
    }
});

const Main = styled('main', {shouldForwardProp: prop => prop !== 'open'})(
    ({theme, open}) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        // marginLeft: `calc(-${drawerWidth} + 40px)`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen
            }),
            marginLeft: '0'
        }),
        overflowX: 'hidden'
    })
);

const AppBar = styled(MuiAppBar, {shouldForwardProp: prop => prop !== 'open'})(
    ({theme, open}) => ({
        zIndex: theme.zIndex.drawer + 1, //
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        ...(open && {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: `${drawerWidth}px`,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen
            })
        })
    })
);

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    //   justifyContent: 'flex-end',
    marginTop: open ? '0' : '3rem'
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: prop => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme)
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme)
        })
    })
);

class Layout extends Component {
    state = {
        isSidebar: false,
        anchor: false
    };

    toggleSidebar = () => {
        this.setState({isSidebar: !this.state.isSidebar});
    };

    render() {
        const isAdmin = isUserAdmin(this.props.user)
        return (
            <>
                {/* render above the page contents */}
                {this.props.isImpersonating && <ImpersonationBanner />}

                {/* temporary banner to switch back to old portal from the beta release */}
                <SwapBanner />
                
                {/* impersonation modal */}
                <Impersonation />

                <Box
                    sx={{display: 'flex'}}
                    className="myOdu__appContentWrapper"
                >
                    <AppBar
                        position="fixed"
                        open={this.state.isSidebar}
                        sx={{
                            backgroundColor: 'white',
                            color: 'black',
                            pl: 0,
                            top: '-4px'
                        }}
                        elevation={0}
                    >
                        <div
                            className="myOdu__decorativeLine navbarArea"
                            style={{width: '100%'}}
                        >
                            &nbsp;
                        </div>

                        <Toolbar>
                            <MenuToggle
                                isSidebar={this.state.isSidebar}
                                onToggle={this.toggleSidebar}
                            />

                            {!this.state.isSidebar && (
                                <>
                                    <Box
                                        sx={{
                                            height: '65px',
                                            width: '65px',
                                            p: 2,
                                            backgroundColor: '#003057'
                                        }}
                                    >
                                        <PortalTooltip title='Home'>
                                            <img
                                                id="topNav__icon_crown"
                                                src="/img/oduCrown_white.svg"
                                                alt="ODU Crown"
                                                onClick={() => this.props.navigate('/')}
                                                style={{cursor:'pointer'}}
                                            />
                                        </PortalTooltip>
                                    </Box>
                                    <h1
                                        className="myOdu__stylizedText"
                                        style={{marginLeft: '1rem'}}
                                    >
                                        <span>My</span>
                                        <span>ODU</span>
                                    </h1>
                                </>
                            )}

                            <Box sx={{flexGrow: 1}} />

                            {isAdmin && <DevButton />}
                            <UserProfileButton />
                        </Toolbar>
                    </AppBar>

                    <Drawer
                        sx={{
                            '& .MuiDrawer-paper': {
                                backgroundColor: '#003057',
                                color: 'white'
                            }
                        }}
                        variant="permanent"
                        open={this.state.isSidebar}
                    >
                        <DrawerHeader>
                            {/* sx={{mt: open ? '0px':'40px'}} */}

                            <Box
                                sx={{
                                    height: '40px',
                                    width: '40px',
                                    marginLeft: '.5rem'
                                }}
                            >
                                <PortalTooltip title='Home'>
                                    <img
                                        id="sideNav__img_crown"
                                        src="/img/oduCrown_white.svg"
                                        alt="ODU Crown"
                                        onClick={() => this.props.navigate('/')}
                                        style={{cursor:'pointer'}}
                                    />
                                </PortalTooltip>
                            </Box>
                            <Box sx={{flexGrow: 1}} />

                            {this.state.isSidebar && (
                                <h1
                                    className="myOdu__stylizedText menu"
                                    style={{paddingRight: '.5rem'}}
                                >
                                    <span>My</span>
                                    <span>ODU</span>
                                </h1>
                            )}

                            {/* <MenuToggle handleCallback={CallBack} data={{open: open}}/> */}
                        </DrawerHeader>
                        <SideNav data={{isOpen: this.state.isSidebar}} />
                    </Drawer>

                    <Main open={this.state.isSidebar}>
                        <PortalToast />
                        <DrawerHeader />
                        {this.props.children}
                        {/* <RouterProvider router={router} /> */}
                    </Main>
                </Box>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.AWSReducer.user,
        isImpersonating:state.impersonationReducer.impersonation?.isImpersonating ?? false,
    };
};

const mapDispatchToProps = dispatch => ({
    toggleImpersonation: () => dispatch(toggleImpersonation())
});

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(Layout));
