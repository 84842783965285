import MeetingActions from "./MeetingActions";
import moment from 'moment';

//Θ(1) returns the columns for meetings
export const getColumns = (props) => {
    return [
        {field: 'topic', headerName: 'Title', flex: 1},
        {field: 'start_time', headerName: 'Date/Time', flex: 2,
            valueGetter: (params) => {
                const date = new Date(params.value)
                const today = new Date();
                
                if(date.getDate() === today.getDate()) {
                    //Format: Today, 10:00 AM - 11:00 AM
                    return 'Today, ' + moment(params.value).format('hh:mm A') + ' - ' + moment(params.value).add(params.row.duration, 'minutes').format('hh:mm A')
                }
                else if(date.getMonth() === today.getMonth()) {
                    //Format: Monday 01 Jan, 10:00 AM - 11:00 AM
                    // return date.toLocaleDateString('en-us', {weekday: 'long', hour: '2-digit', minute: "2-digit"})
                    return moment(params.value).format('dddd DD MMM, hh:mm A') + ' - ' + moment(params.value).add(params.row.duration, 'minutes').format('hh:mm A')
                }
                // return new Date(params.value).toLocaleDateString('en-us', {month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit'})
                //Format: January 01, 10:00 AM - 11:00 AM
                return moment(params.value).format('MMMM DD, hh:mm A') + ' - ' + moment(params.value).add(params.row.duration, 'minutes').format('hh:mm A')
            }
        },
        {
            field: 'meeting_details', headerName: 'Meeting ID', flex: 1,
            valueGetter: (params) => {
                return params.row?.queued ? 'Being Processed' : params.row.id
            } 
        },
        {
            field: 'meeting_password', headerName: 'Password', flex: 1,
            valueGetter: (params) => {
                return params.row?.queued ? '' : params.row.password
            } 
        },
        {field: 'actions', headerName: 'Actions', flex: 1,
            renderCell: (params) => {
                let {row} = params;
                return <MeetingActions startUrl = {row.start_url} activeTab = {props.activeTab}/>
            }
        }
    ]
}