import React, { Component } from 'react';
import { connect } from 'react-redux';
import { saveSetup } from './actions';
import ApplicationCard from '../common/ApplicationCard';

class App extends Component {
    state = {
        iconPath: '/img/logos/'
    }

    //Θ(1) Stores/Delete the selected app from preferences
    onCheck = () => {
        let setup = this.props.setup
        if(this.props.app.id in (setup?.Apps ?? {})){
            delete setup.Apps[this.props.app.id]
            if(!Object.keys(setup.Apps).length)
                delete setup['Apps']
        }
        else
            setup['Apps'] = {...(setup?.Apps ?? {}), [this.props.app.id]: true}
        this.props.saveSetup(setup)
    }

    render() {
        const isActive = this.props.app.id in (this.props.setup?.Apps ?? {})
        return <React.Fragment>
            <ApplicationCard app={this.props.app} data={{icon: this.state.iconPath + this.props.app.icon}} isActive={isActive} onClick={this.onCheck} showHeart={true} showInfo={true} showLinks={false}/>
        </React.Fragment>
    }
}

const mapStateToProps = (state) => {
    return {
        setup: state.setupReducer.setup,
    }
  }
  
const mapDispatchToProps = (dispatch) => ({
    saveSetup: (setup) => dispatch(saveSetup(setup))
});
  
export default connect(mapStateToProps, mapDispatchToProps)(App);