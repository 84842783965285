import React, { Component } from 'react';
import Widget from '../common/Widget';

class MyRegistration extends Component {
    state = {
        isTitleStylized: true,
        title: ["my", "Registration"],
        widgetId: "widget__myRegistration",
    }

    render() {
        //TODO: Add Updated time
        return <React.Fragment>
            <Widget data={{id: this.state.widgetId, isTitleStylized: this.state.isTitleStylized, title: this.state.title, menu: this.state.menu}} {...this.props}>
                registration
            </Widget>
        </React.Fragment>
    }
}

export default MyRegistration