import React, {Component} from 'react';
import {Link, Tooltip, Typography} from '@mui/material';
import PortalTooltip from '../common/PortalTooltip';

class AlertDescription extends Component {
  render() {
    return (
      <Typography component="p" sx={{mb: 1}}>
        <Typography
          variant="p"
          dangerouslySetInnerHTML={{
            __html: this.props.description
          }}
        />
        {this.props.linkTitle && this.props.linkUrl && (
          <span>
            <PortalTooltip id={this.props.alertKey + '_' + this.props.index + '_tooltip'}>
              <Link
                href={this.props.linkUrl}
                underline="always"
                target="_blank"
                color="inherit"
                id={this.props.alertKey + '_' + this.props.index}
                sx={{ml: 1, cursor: 'pointer'}}
                variant="small"
              >
                {this.props.linkTitle}
              </Link>
            </PortalTooltip>
          </span>
        )}
      </Typography>
    );
  }
}

export default AlertDescription;
