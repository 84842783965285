import React, { Component } from 'react';
import { Typography, Link, List, ListItem, Stack, Tooltip } from '@mui/material';

class AnnouncementList extends Component {

    getCss = category => {
        if (category === 'Activities/Social') category = 'Social';
        return category.replaceAll(' ', '');
    };
    
    render() {
        return (
            <List sx={{ pt: 0 }} dense={true} className="myOdu__announcements myOdu__announcements_wrapper">
                {this.props.list?.map((group, index) => (
                    <ListItem key={"myOdu__announcements_" + index} sx={{ p: 0, ml: 1 }}>
                        <Stack>
                            <Typography
                                variant="h6"
                                component="h4"
                                className={
                                    this.getCss(group.category) +
                                    ' iconHeader'
                                }
                            >
                                {group.category}
                            </Typography>
                            <List dense={true} sx={{ pl: 1 }}>
                                {group.announcements.map((announcement, index) => (
                                    <ListItem key={'myAnnouncements__list_item_' + index} sx={{ p: 0 }}>
                                        <Tooltip title="Opens in new window" enterDelay={750} leaveDelay={0} enterNextDelay={750} arrow>
                                            <Link
                                                href={announcement.url}
                                                sx={{ pb: 2 }}
                                                target="_blank"
                                                variant="small"
                                                underline="hover"
                                            >
                                                {announcement.title}
                                            </Link>
                                        </Tooltip>
                                    </ListItem>
                                ))}
                            </List>
                        </Stack>
                    </ListItem>
                )) ?? <Typography component='p' variant='small'>No announcements found</Typography>}
            </List>
        );
    }
}

export default AnnouncementList;
