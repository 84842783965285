import React, {useEffect, useState} from 'react';
import {Box, Typography, Paper, Grid} from '@mui/material';
import {useForm} from 'react-hook-form';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import LocationTable from './LocationTable';
import {LocationDetails} from './LocationDetails';
import {FormButton} from '../../common/Form/FormButton';
import {getData, postData, deleteData, putData} from '../../../DataAccessLayer';
import {locations as locationsService} from '../../../DataAccessLayer/services';
import getColumns from './columns';
import {toast} from 'react-toastify';

dayjs.extend(customParseFormat);

export const Locations = () => {
    const [locationsData, setLocationsData] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const defaultValues = {
        title: '',
        url: '',
        mapUrl: '',
        mapIframeEmbedUrl: '',
        address: '',
        hours: []
    };
    const methods = useForm({
        defaultValues,
        mode: 'onTouched'
    });

    const formatTimes = hours =>
        hours.map(hour => ({
            ...hour,
            times: `${dayjs(hour.times.openTime, 'h:mma').format(
                'h:mma'
            )} - ${dayjs(hour.times.closeTime, 'h:mma').format('h:mma')}`
        }));

    const loadLocations = async () => {
        setIsLoading(true);
        try {
            const data = await getData(locationsService.locations);
            setLocationsData(data);
        } catch (err) {
            console.error('Failed to load locations:', err);
        } finally {
            setIsLoading(false);
        }
    };

    const getSpecificLocation = async ({row}) => {
        setIsLoading(true);
        try {
            const data = await getData(
                locationsService.getLocation({
                    catergoryId: row.CATEGORY_ID,
                    placeId: row.PLACE_ID
                })
            );

            setSelectedLocation({
                categoryId: data.CATEGORY_ID,
                id: data.PLACE_ID,
                title: data.TITLE,
                url: data.URL,
                mapUrl: data.MAP_URL,
                mapIframeEmbedUrl: data.MAP_IFRAME_EMBED_URL,
                address: data.ADDRESS,
                hours: data.HOURS.map(hour => {
                    const [openTime, closeTime] = hour.times.split(' - ');

                    return {
                        ...hour,
                        times: {
                            openTime: dayjs(openTime, 'hh:mma'),
                            closeTime: dayjs(closeTime, 'hh:mma')
                        }
                    };
                })
            });
        } catch (err) {
            console.error('Failed to fetch location details:', err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadLocations();
    }, []);

    const handleLocationData = async (location, isUpdating = false) => {
        const hours = formatTimes(location.hours);
        const data = {...location, hours};

        try {
            if (isUpdating) {
                await putData(locationsService.locations, data);
            } else {
                await postData(locationsService.locations, data);
            }
            methods.reset();
            setSelectedLocation(null);
            toast.success(
                `Successfully ${isUpdating ? 'updated' : 'created'} location`
            );
        } catch (err) {
            console.error(
                isUpdating ? 'Updating' : 'Creating',
                'location failed:',
                err
            );

            toast.error(
                `Error ${isUpdating ? 'updating' : 'creating'} location`
            );
        } finally {
            loadLocations();
        }
    };

    const deleteLocationData = async row => {
        try {
            await deleteData(
                locationsService.deleteLocation({
                    catergoryId: row.CATEGORY_ID,
                    placeId: row.PLACE_ID
                })
            );

            toast.success(`Successfully deleted location`);
        } catch (err) {
            console.error('Deleting location failed:', err);
            toast.error(`Error deleting location`);
        } finally {
            loadLocations();
        }
    };

    useEffect(() => {
        if (selectedLocation) {
            const mergedValues = _.merge({}, defaultValues, selectedLocation);
            methods.reset(mergedValues); // Reset form values with merged data
        } else {
            methods.reset(defaultValues); // Reset to default values when no location is selected
        }
    }, [selectedLocation, methods]);

    const mode = selectedLocation ? 'Update' : 'Create';

    return (
        <div className="myOdu__container_maxWidth" style={{marginTop: '2rem'}}>
            <Typography component="h2" variant="h4" gutterBottom>
                Locations
            </Typography>
            {mode === 'Update' && (
                <FormButton
                    className="myOdu__button primary"
                    label="Create Location"
                    onClick={() => setSelectedLocation(null)}
                />
            )}

            <Grid container spacing={2}>
                <Grid item xs={12} xl={5}>
                    <LocationTable
                        rows={locationsData}
                        columns={getColumns(deleteLocationData)}
                        isLoading={isLoading}
                        onClick={getSpecificLocation}
                    />
                </Grid>
                <Grid item xs={12} xl={7}>
                    <Paper
                        sx={{
                            width: '100%',
                            overflow: 'hidden',
                            height: '100%',
                            p: 4
                        }}
                    >
                        <form
                            onSubmit={methods.handleSubmit(data =>
                                handleLocationData(data, mode === 'Update')
                            )}
                        >
                            <LocationDetails
                                control={methods.control}
                                mode={mode}
                            />
                            <Box mt={3} display="flex" justifyContent="center">
                                <FormButton
                                    className="myOdu__button secondary"
                                    type="submit"
                                    label={mode}
                                    disabled={!methods.formState.isValid}
                                />
                            </Box>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};
