import React, {Component} from 'react';
import Widget from '../common/Widget';
// import { Container, Row, Col } from 'reactstrap';
import {
  IconButton,
  InputAdornment,
  Card,
  Link,
  Box,
  Grid,
  Stack,
  Typography,
  Divider,
  List,
  ListItem,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput
} from '@mui/material';
import Place from '../common/PlaceSummary';
import PlaceCard from '../common/PlaceCard';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faForkKnife,
  faUserChef,
  faHeart,
  faChevronDown,
  faSearch
} from '@fortawesome/pro-light-svg-icons';
import PlaceCategories from './PlaceCategories';
import {getData} from '../../DataAccessLayer';
import {placesAndHours} from '../../DataAccessLayer/services';
import {toggleAllCategories} from './actions';
import {connect} from 'react-redux';
import _ from 'lodash';

//TODO: replace search field with custom search component
//and remove this
// const textFieldCss = [
//   {
//     border: '1px solid #CECECE',
//     backgroundColor: 'white',
//     '& .MuiOutlinedInput-root': {
//       backgroundColor: 'white',
//       border: '1px solid #CECECE'
//     },
//     '& .MuiInputLabel-shrink': {
//       display: 'none'
//     },
//     '& .MuiOutlinedInput-notchedOutline': {
//       display: 'none'
//     }
//   }
// ];

class PlacesAndHours extends Component {
  state = {
    widgetId: 'widget__placesAndHours',
    isExpand: true,
    menu: [
      {
        id: 'placesAndHours_expandAll',
        title: 'Expand All Categories',
        onClick: () => this.toggleAllCategoriesState(true)
      },
      {
        id: 'placesAndHours_collapseAll',
        title: 'Collapse All Categories',
        onClick: () => this.toggleAllCategoriesState(false)
      }
      //TODO: enable in V1.1
      // {id: 'placesAndHours_hide', title: 'Hide'},
    ],
    data: [],

    searchContent: '',

    searchResults: []
  };

  componentDidMount() {
    this.loadPlacesAndHours();
  }

  //O(1) Fetches all places and hours
  loadPlacesAndHours = async () => {
    try {
      const data = await getData(placesAndHours);

      this.setState({data});
    } catch (err) {
      this.setState({data: []});
    }
  };

  toggleExpand = () => {
    this.setState({isExpand: !this.state.isExpand});
  };

  /**
   * Θ(N*M) - Toggles the expanded state of all categories and sets places within those categories based on the `isExpanded` parameter.
   */

  toggleAllCategoriesState = isExpanded => {
    this.props.toggleAllCategories(
      this.state.data.reduce((newState, category) => {
        // Directly set the expanded state for the category
        newState[category.id] = isExpanded;

        // Directly set the expanded state for all places within the category to false
        category.places.forEach(place => (newState[place.id] = false));

        return newState;
      }, {})
    );
  };

  render() {
    return (
      <>
        <Widget
          data={{
            title: 'Places & Hours',
            id: this.state.widgetId,
            menu: this.state.menu
          }}
          {...this.props}
        >
          <Card
            sx={{
              // border: '1px solid #CECECE',
              p: 1,
              backgroundColor: 'aliceblue'
            }}
            className="myOdu__border"
            elevation={0}
          >
            <OutlinedInput
              value={this.state.searchContent}
              onChange={e => this.setState({searchContent: e.target.value})}
              fullWidth
              size="small"
              label="Search Places and Hours"
              // sx={textFieldCss}
              className="myOdu__hideFloatLabel myOdu__border"
              placeholder="Search Places and Hours"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Search Places and Hours"
                    edge="end"
                    onClick={() => {
                      const searchResults = this.state.data.reduce(
                        (acc, item) => {
                          // Perform a case-insensitive search
                          const matches = item.places.filter(place =>
                            place.title
                              .toLowerCase()
                              .includes(this.state.searchContent.toLowerCase())
                          );

                          // Concatenate any found matches to the accumulator
                          return acc.concat(matches);
                        },
                        []
                      );

                      this.setState({searchResults});
                    }}
                  >
                    <FontAwesomeIcon icon={faSearch} size="xs" />
                  </IconButton>
                </InputAdornment>
              }
            />

            {/* //if search results */}

            {!_.isEmpty(this.state.searchResults) && (
              <>
                <Typography component="h3" variant="h6" sx={{mt:2, mb:1}}>
                  Search Results
                </Typography>

                {this.state.searchResults.map(result => (
                  <Card
                    className="myOdu__border"
                    elevation="0"
                    sx={{mb:1}}
                  >
                    <PlaceCard
                      data={{location: result}}
                      widgetId={this.state.widgetId}
                    />
                  </Card>
                ))}
              </>
            )}
          </Card>

          <List sx={{pl: 0, pb: 0}}>
            {this.state.data.map(category => (
              <ListItem
                divider
                sx={{p: 0}}
                key={this.state.widgetId + '_ulCategory_li_' + category.id}
              >
                <PlaceCategories data={{category: category}} />
              </ListItem>
            ))}
          </List>
        </Widget>
      </>
    );
  }
}

const mapStateToProps = state => ({
  expandedCategories: state.expandedCategories
});

const mapDispatchToProps = {
  toggleAllCategories
};

export default connect(mapStateToProps, mapDispatchToProps)(PlacesAndHours);
