import React, {Component} from 'react';
import {Typography, Box, Paper, Stack} from '@mui/material';

import LikertFaceScale from './LikertFaceScale';
import {FormCheckbox} from './Form/FormCheckbox';
import {FormTextField} from './Form/FormTextField';
import {FormTextArea} from './Form/FormTextArea';
import {FormButton} from './Form/FormButton';
import {feedback} from '../../DataAccessLayer/services';
import {postData} from '../../DataAccessLayer';
import {toast} from 'react-toastify';
import {getBrowserMetadata} from '../../DataAccessLayer/util';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFaceSmileRelaxed} from '@fortawesome/pro-light-svg-icons';

class FeedbackForm extends Component {
    state = {
        rating: null,
        bugChecked: false,
        ideaChecked: false,
        othersChecked: false,
        pageUrl: '',
        bugDetail: '',
        ideaDetail: '',
        otherDetail: '',
        changeDetail: '',
        isSubmitted: false
    };

    handleBugChecked = () => {
        this.setState({bugChecked: !this.state.bugChecked});
    };

    handleIdeaChecked = () => {
        this.setState({ideaChecked: !this.state.ideaChecked});
    };

    handleOtherChecked = () => {
        this.setState({othersChecked: !this.state.othersChecked});
    };

    submitFeedback = async () => {
        const {
            rating,
            pageUrl,
            bugDetail,
            ideaDetail,
            otherDetail,
            changeDetail
        } = this.state;

        const metadata = await getBrowserMetadata();

        const body = {
            rating,
            pageUrl,
            bugDetail,
            ideaDetail,
            otherDetail,
            changeDetail,
            metadata
        };

        postData(feedback, body)
            .then(result => {
                this.setState({isSubmitted: true});
            })
            .catch(err => toast.error('Error submiiting feedback !'));
    };

    render() {
        const {
            rating,
            bugChecked,
            ideaChecked,
            othersChecked,
            pageUrl,
            bugDetail,
            ideaDetail,
            otherDetail,
            changeDetail,
            isSubmitted
        } = this.state;
        return (
            <>
                <Box sx={{p:0}}>
                    {isSubmitted ? (
                        <Stack alignItems={'center'}>
                            <FontAwesomeIcon
                                icon={faFaceSmileRelaxed}
                                fixedWidth
                                size="4x"
                            />
                            <Typography
                                component="h3"
                                className="myOdu__feedback text-center"
                            >
                                Thanks for the Feedback!
                            </Typography>
                            <Typography
                                compoent="p"
                                className="myOdu__feedback"
                                sx={{mb:2}}
                            >
                                We're always striving to improve, and your input helps us do just that.
                                So, thanks again for being awesome and sharing
                                your thoughts with us!
                            </Typography>
                        </Stack>
                    ) : (
                        <>
                            <Stack alignItems={'center'}>
                                <Typography
                                    component={'label'}
                                    className="myOdu__label"
                                >
                                    How do you like the new myODU portal design?
                                </Typography>

                                <LikertFaceScale
                                    isTextAreaDisplayed
                                    rating={rating}
                                    onChange={e => this.setState({rating: e})}
                                />
                            </Stack>
                            {[0, 1].includes(rating) && (
                                <>
                                    <Typography
                                        component="label"
                                        className="myOdu__label"
                                        htmlFor="feedback_changeDetail"
                                    >
                                        What would you change?
                                    </Typography>
                                    <FormTextArea
                                        id="feedback_changeDetail"
                                        value={changeDetail}
                                        onChange={e =>
                                            this.setState({changeDetail: e})
                                        }
                                        maxChars={500}
                                        inputGridProps={{xs: 12}}
                                    />
                                </>
                            )}

                            <FormCheckbox
                                value={bugChecked}
                                label={'I found a bug'}
                                onChange={this.handleBugChecked}
                                labelGridProps={{xs: 0}}
                            />
                            {bugChecked && (
                                <>
                                    <Typography
                                        component="label"
                                        className="myOdu__label"
                                        htmlFor="feedback_pageUrl"
                                    >
                                        Which Page?
                                    </Typography>
                                    <FormTextField
                                        id="feedback_pageUrl"
                                        value={pageUrl}
                                        onChange={e =>
                                            this.setState({pageUrl: e})
                                        }
                                        inputGridProps={{xs: 12}}
                                    />
                                    <Typography
                                        component="label"
                                        className="myOdu__label"
                                        htmlFor="feedback_bugDetail"
                                        sx={{mt:2}}
                                    >
                                        Tell Us About the Bug
                                    </Typography>
                                    <FormTextArea
                                        id="feedback_Detail"
                                        value={bugDetail}
                                        onChange={e =>
                                            this.setState({bugDetail: e})
                                        }
                                        maxChars={500}
                                        inputGridProps={{xs: 12}}
                                    />
                                </>
                            )}
                            <FormCheckbox
                                value={ideaChecked}
                                label={'I have an idea for somethig new'}
                                onChange={this.handleIdeaChecked}
                                labelGridProps={{xs: 0}}
                            />
                            {ideaChecked && (
                                <>
                                    <Typography
                                        component="label"
                                        className="myOdu__label"
                                        htmlFor="feedback_ideaDetail"
                                    >
                                        Tell Us About Your Idea
                                    </Typography>
                                    <FormTextArea
                                        id="feedback_ideaDetail"
                                        value={ideaDetail}
                                        onChange={e =>
                                            this.setState({ideaDetail: e})
                                        }
                                        maxChars={500}
                                        inputGridProps={{xs: 12}}
                                    />
                                </>
                            )}
                            <FormCheckbox
                                value={othersChecked}
                                label={'I have something else to say'}
                                onChange={this.handleOtherChecked}
                                labelGridProps={{xs: 0}}
                            />
                            {othersChecked && (
                                <>
                                    <Typography
                                        component="label"
                                        className="myOdu__label"
                                        htmlFor="feedback_otherDetail"
                                    >
                                        What else would you like to say?
                                    </Typography>
                                    <FormTextArea
                                        id="feedback_otherDetail"
                                        value={otherDetail}
                                        onChange={e =>
                                            this.setState({otherDetail: e})
                                        }
                                        maxChars={500}
                                        inputGridProps={{xs: 12}}
                                    />
                                </>
                            )}
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <FormButton
                                sx={{mt:2}}
                                    label="Submit Feedback"
                                    onClick={this.submitFeedback}
                                />
                            </Box>
                        </>
                    )}
                </Box>
            </>
        );
    }
}

export default FeedbackForm;
