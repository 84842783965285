import {faX} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography
} from '@mui/material';
import React from 'react';
import {Col, Row} from 'reactstrap';

class DeleteDialog extends React.Component {
    state = {};

    render() {
        return (
            <React.Fragment>
                <Dialog
                    open
                    fullWidth
                    className="myOdu__wizard"
                    onClose={this.props.toggleIsDeleteTutoring}
                >
                    <DialogTitle
                        sx={{
                            backgroundColor: '#003057',
                            color: 'white',
                            borderBottom: '3px solid #2188A2'
                        }}
                    >
                        <Row>
                            <Col>
                                <Typography variant="h4" component="h2">
                                    {this.props.title}
                                </Typography>
                            </Col>
                            <Col
                                xs={1}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'right'
                                }}
                            >
                                <IconButton
                                    size="small"
                                    onClick={this.props.toggleIsDeleteTutoring}
                                >
                                    <FontAwesomeIcon
                                        icon={faX}
                                        style={{color: '#ffffff'}}
                                    />
                                </IconButton>
                            </Col>
                        </Row>
                    </DialogTitle>

                    <DialogContent className="py-4">
                        <Typography>
                            Are you sure you want to delete tutoring for{' '}
                            {this.props.tutoringToDelete?.SUBJECT}{' '}
                            {this.props.tutoringToDelete?.COURSE_NUMBER} at{' '}
                            {this.props.tutoringToDelete?.locations
                                ?.map(location => location.text)
                                .join(', ')}
                            ?
                        </Typography>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={this.props.toggleIsDeleteTutoring}>
                            <span style={{textDecoration: 'underline'}}>
                                no, cancel
                            </span>
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={this.props.onSubmit}
                            disabled={this.props.isLoading}
                        >
                            yes, delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default DeleteDialog;
