import React from "react";
import { faFaceSurprise } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Typography, Button, Stack, Link} from "@mui/material"



const InvalidRoute = () => {
    return <React.Fragment>
        <Stack alignItems={"center"}>
            <FontAwesomeIcon icon={faFaceSurprise} fixedWidth size="9x"/>
            <Typography component="h2" className="myOdu__errorCode">404</Typography>
            <Typography component="h3" className="myOdu__errorCode">Page not Found</Typography>
            <Typography component="p" className="myOdu__errorCode">Oops! The page you are looking for cannot be found.</Typography>
            <Button className="myOdu__errorCodeButton" href="/" sx={{mt:3}} variant="outlined">
                Go to the home page
            </Button>
        </Stack>
    </React.Fragment>
}

export default InvalidRoute