import { faCheck, faX } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, FormControl, FormHelperText, FormLabel, IconButton, InputLabel, OutlinedInput } from '@mui/material';
import { Box } from '@mui/system';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { FormTextField } from '../common/Form/FormTextField';

const boxStyle ={
    xs: 0.5,
    xxl:2 
}

const textFieldCss = [{
    border: '1px solid #CECECE',
    backgroundColor: 'white',
    "& .MuiOutlinedInput-root": {
        backgroundColor: 'white',
        border: '1px solid #CECECE'
    },
    "& .MuiInputLabel-shrink": {
        display: 'none'
    },
    "& .MuiOutlinedInput-notchedOutline":{
        display: 'none'
    }
}];

class AddBookmark extends Component {
    
    state = {

        //title of bookmark
        title: '',

        //url of bookmark
        url: ''
    }

    componentDidMount(){
        if(this.props.isEdit)
            this.setup()
    }

    //Θ(1) setup component for edit
    setup = () => {
        this.setState({
            title: this.props.bookmark.TITLE,
            url: this.props.bookmark.URL
        })
    }

    //Θ(1) reset the form
    reset = () => {
        this.setState({
            title: '',
            url: ''
        })
    }

    //Θ(1) handle title change
    onChangeTitle = (value) => {
        this.setState({title: value})
    }

    //Θ(1) handle url change
    onChangeURL = (value) => {
        this.setState({url: value})
    }

    //Θ(1) check of the url is valid
    isValidURL = () => {
        try{
            new URL(this.state.url)
            return true
        }
        catch(ex){
            return false
        }
    }

    //Θ(1) check if the bookmark to be added is valid
    isValidAdd = () => {
        return (
            this.isValidURL() &&
            this.state.title.length
        )
    }
    
    //Θ(1) check if the edited bookmark is valid
    isValidEdit = () => {
        return (
            (this.state.title != this.props.bookmark.TITLE || this.state.url != this.props.bookmark.URL) &&
            this.isValidAdd()
        )
    }

    //Θ(1) trigger save bookmark and reset component
    onAdd = () => {
        this.props.onAdd(this.state.title, this.state.url)
        this.reset()
    }
    
    render() {
        return <React.Fragment>
                <Row>
                    <Col md={this.props.isEdit ? 11 : 12}>
                        <Row >
                            <Col>
                                <Box py={boxStyle}>
                                    <FormTextField
                                        id="bookmark-title"
                                        label="Title"
                                        required
                                        value={this.state.title}
                                        placeholder="Title"
                                        onChange={this.onChangeTitle}
                                        maxChars={100}
                                        charCountEnabled
                                        textAlign={{xs: "start"}}
                                        labelGridProps={{xs: 12, md: 2}}
                                        inputGridProps={{xs: 12, md: 10}}
                                        isTextArea
                                    />
                                </Box>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Box>
                                    <FormTextField
                                        id="bookmark-url"
                                        label="URL"
                                        required
                                        value={this.state.url}
                                        onChange={this.onChangeURL}
                                        placeholder='http:// or https://'
                                        error={!this.isValidURL() && this.state.url.length > 0}
                                        errorText="URL must start with http:// or https://"
                                        maxChars={300}
                                        charCountEnabled
                                        helperText="URL must start with http:// or https://"
                                        textAlign={{xs: "start"}}
                                        labelGridProps={{xs: 12, md:2}}
                                        inputGridProps={{xs: 12, md:10}}
                                        isTextArea

                                    />
                                </Box>
                            </Col>
                        </Row>
                        {!this.props.isEdit && <Row className="text-end">
                            <Col className="p-3">
                                <Button 
                                    variant="outlined" sx={{mt:1}} 
                                    onClick={this.onAdd}
                                    disabled={!this.isValidAdd()}
                                    className="myOdu__button primary bookmarkManagerButton"
                                >
                                    {'Add Bookmark'}
                                </Button>
                            </Col>
                        </Row>}
                    </Col>
                    {this.props.isEdit && <Col md={1}>
                        <Box>
                            <IconButton aria-label="delete" onClick={() => this.props.onEditSubmit(this.state.title, this.state.url)} disabled={!this.isValidEdit()}><FontAwesomeIcon icon={faCheck}/></IconButton>
                            <IconButton aria-label="delete" onClick={this.props.onEditCancel}><FontAwesomeIcon icon={faX} /></IconButton>
                        </Box>
                    </Col>}
                </Row>
        </React.Fragment>
    }
}
 
export default AddBookmark;