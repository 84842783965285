import React, { Component } from 'react';
import Widget from '../common/Widget';
import { UncontrolledTooltip } from 'reactstrap';
import { Typography, Stack, Box, Link, Grid, Divider, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/pro-light-svg-icons';

class itsHelp extends Component {
    state = {
        isExpand: true,
        //TODO: enable in v1.1
        // menu:[
        //     {id: "itsHelp_hide", title: "Hide"},
        // ],
        helpDeskTicketCount: 3, //TODO: remove when API wired in
        // quickTip: "Set your password equal to your MIDAS ID to make it easy to remember!",
    }

    toggleExpand = () => {
        this.setState({isExpand: !this.state.isExpand})
    }

    render() {
        
        return <>
            <Widget data={{title: "ITS Help", menu: this.state.menu, id: 'widget__itsHelp'}} {...this.props}>
               <Grid container spacing={2} >
                    {/* //Update sm to 4 when tickets part and quickfact part are enabled again */}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{justifyContent: 'center'}} className="ms-xl-3">

                        <Stack alignItems="center" justifyContent="center" sx={{textAlign: "center"}}>
                            <Link id="itsHelp__link__computerIcon" href="http://www.odu.edu/its/helpdesk" target="_blank" underline="none" className={"cursor-pointer"} title="Opens in new window">
                                <Box sx={{height: '40px', width: '40px', margin: '0 auto'}}  className='myOdu__logoZoom'>
                                    <img src="/img/itsHelp.svg" alt="computer with ODU logo on screen" />
                                </Box>
                            </Link>
                            <Typography component="h3" variant="h6">
                                Help Available 24/7
                            </Typography>
                            <Stack direction={{xs:'column', md: "column", sm: "row", lg: "column", xl: "column", xxl: "col", xxxl: "column", xxxxl: "row"}} sx={{width: '100%'}} divider={<Divider orientation="vertical" flexItem sx={{borderColor: 'black'}} role="presentation" />} spacing={0} justifyContent="space-around" className="text-center">
                           
                                <Tooltip title="Opens in new window" enterDelay={850} leaveDelay={0} enterNextDelay={850} arrow>
                                    <Link id="itsHelp__link_helpOnline" sx={{mx:1}} href="http://www.odu.edu/its/helpdesk" variant="small" underline="hover">ITS Help Online</Link>
                                </Tooltip>
                                <Tooltip title="Email ITS Help" enterDelay={850} leaveDelay={0} enterNextDelay={850} arrow>
                                    <Link id="itsHelp__link_email_itsHelp" sx={{mx:1}} href="mailto:itsHelp@odu.edu" variant="small" underline="hover">
                                        <FontAwesomeIcon icon={faEnvelope} style={{marginRight: '.25rem'}}></FontAwesomeIcon>itsHelp@odu.edu
                                    </Link>
                                </Tooltip>
                                <Tooltip title="Call ITS Help" enterDelay={850} leaveDelay={0} enterNextDelay={850} arrow>
                                    <Link id="itsHelp__link_phone_757-683-3192" sx={{mx:1}} href="tel:757-683-3192" variant="small" underline="hover">
                                        <FontAwesomeIcon icon={faPhone} style={{marginRight: '.25rem'}}></FontAwesomeIcon>
                                        757-683-3192
                                    </Link>
                                </Tooltip>
                           
                            </Stack>

                            {/* <Link href="http://www.odu.edu/its/helpdesk" variant="small" underline="hover" title="Opens in new window">ITS Help Online</Link>
                            <Link href="mailto:itsHelp@odu.edu" variant="small" underline="hover" title="Email ITS Help">
                                <FontAwesomeIcon icon={faEnvelope} style={{marginRight: '.25rem'}}></FontAwesomeIcon>itsHelp@odu.edu
                            </Link>
                            <Link href="tel:757-683-3192" variant="small" underline="hover" title="Call ITS Help">
                                <FontAwesomeIcon icon={faPhone} style={{marginRight: '.25rem'}}></FontAwesomeIcon>
                                757-683-3192
                            </Link> */}
                        </Stack>
                    </Grid>
                    {/* <Grid item xs={12} sm={8} md={12} lg={12} xl={12}> */}
                        {/* <Box className="bt-1 bt-sm-0 bt-xs-0 pt-md-3" sx={{mb:2}}>
                            <Link href="http://www.odu.edu/its/helpdesk" target="_blank" underline="none" className={"cursor-pointer"} title="Opens Help Desk in new window">
                                <Box sx={{height: '30px', width: '40px', margin: '0 auto'}}  className='myOdu__logoZoom'>
                                    <img src="/img/ticket.svg" alt="ticket" />
                                </Box>
                            </Link>
                            <Stack direction="row" alignItems="center" justifyContent="center">
                                <Typography component="p" variant="small">You have </Typography>
                                <Typography sx={{fontWeight: 700, mx:"0.25rem"}}>
                                    { this.state.helpDeskTicketCount ?? 'no'} open</Typography>
                                <Link href="http://www.odu.edu/its/helpdesk" target="_blank" underline='always' title="Opens in new window">
                                    help desk tickets
                                </Link>
                            </Stack>
                        </Box> */}

                        {/* {this.state.quickTip && 
                            <>
                                <Box className="bt-1" sx={{mt:2}}></Box>
                                <Box className="myOdu__callOutBox quickTip" sx={{p:1, mb:2, textAlign: "center", mt:2}}>
                                    <Link component="h3" variant="h6" href="#" underline="hover">Quick Tip</Link>
                                    <Typography component="p" variant="smaller">
                                        {this.state.quickTip} 
                                    </Typography>
                                </Box>
                            </>
                        } */}
                    {/* </Grid> */}
                </Grid>          
            </Widget>
        </>
    }
}
 
export default itsHelp;