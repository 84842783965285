import React, { Component } from 'react';
import Widget from '../common/Widget';
import { Button, Link, List, ListItem, Typography, Tooltip } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { deleteData, getData, postData, putData } from '../../DataAccessLayer';
import { myBookmarks } from '../../DataAccessLayer/services';
import BookmarksManager from './BookmarksManager';
import PortalTooltip from '../common/PortalTooltip';
import {toast} from 'react-toastify';

class MyBookmarks extends Component {

    //Θ(1) toggle the bookmarks manager
    onToggle = () => {
        this.setState({isManager: !this.state.isManager})
    }

    state = {

        //control widget expantion
        isExpand: true,

        //widget id for uniqueness
        widgetId: 'widget__myBookmarks',
        
        isTitleStylized: true,
        
        title: ["my", "Bookmarks"],

        //menu for the widget
        menu:[
            {id: "myBookmarks_editBookmarks", title: "Edit Bookmarks", onClick: this.onToggle},
            //TODO: enable in V1.1
            // {id: "myBookmarks_hide", title: "Hide"},
        ],

        //array of user bookmarks
        bookmarks: [],

        //control bookmarks manager
        isManager: false
    }


    componentDidMount(){
        this.loadBookmarks()
    }

    //Θ(1) load user bookmarks form server
    loadBookmarks = () => {
        getData(myBookmarks).then(bookmarks => {
            if(bookmarks)
                this.setState({bookmarks})
        })
    }

    //toggle the widget expantion
    toggleExpand = () => {
        this.setState({isExpand: !this.state.isExpand})
    }

    //Θ(1) Add a new bookmark for the user and reload
    onAdd = (title, url) => {
        const body = {
            bookmark: {
                TITLE: title,
                URL: url
            }
        }
        putData(myBookmarks, body).then(result => {
            this.loadBookmarks()
            toast.success('Bookmark added')
        })
    }

    //Θ(1) Edit the selected bookmark and reload
    onEdit = (title, url, CREATED_AT) => {
        const body = {
            bookmark: {
                TITLE: title,
                URL: url,
                CREATED_AT
            }
        }
        postData(myBookmarks, body).then(result => {
            this.loadBookmarks()
            toast.success('Bookmark changes saved')
        })
    }

    //Θ(1) delete the selected bookmark and reload
    onDelete = (CREATED_AT) => {
        deleteData(myBookmarks + '/' + CREATED_AT).then(result => {
            this.loadBookmarks()
            toast.success('Bookmark deleted')
        })
    }

    render() {
        
        return <>
            {/* Let the AddBookmark unmount to reset internal state */}
            {this.state.isManager && <BookmarksManager isOpen={this.state.isManager} bookmarks={this.state.bookmarks} onToggle={this.onToggle} onAdd={this.onAdd} onEdit={this.onEdit} onDelete={this.onDelete}/>}

            <Widget data={{id: this.state.widgetId, isTitleStylized: this.state.isTitleStylized, title: this.state.title, menu: this.state.menu}} {...this.props}>
                <div className="myOdu__myBookmarks_wrapper">
                    <List sx={{pt:0}} dense={true}>
                        {this.state.bookmarks.map(bookmark =>
                            <ListItem sx={{pl:1}} key={this.state.widgetId + "_ul_li_" + bookmark.CREATED_AT} divider>
                                <PortalTooltip>
                                    <Link id={"myBookmarks__link_" + bookmark.CREATED_AT} variant="small" href={bookmark.URL} underline={'hover'} target="_blank">{bookmark.TITLE}</Link>
                                </PortalTooltip>
                        </ListItem>
                        )}
                        {this.state.bookmarks.length == 0 &&
                            <>
                                <Typography variant="smaller" xs={{display: 'inline'}}>No bookmarks found. </Typography>
                                <PortalTooltip title="Open bookmark editor">
                                    <Link 
                                        id="myBookmarks__link_noBookmarksFoundAddBookmarks" 
                                        className="cursor-pointer"
                                        variant="smaller" 
                                        xs={{display: 'inline', cursor: "pointer"}} 
                                        onClick={this.onToggle}>
                                        Add bookmarks.
                                    </Link>
                                </PortalTooltip>
                            </>
                        }
                    </List>
                </div>
            </Widget>
        </>
    }
}
 
export default MyBookmarks;