import React, { Component } from 'react';
import Widget from '../common/Widget';
import { ResourceLinks } from './config';
import { Link, List, ListItem, Tooltip } from '@mui/material';
import { UncontrolledTooltip } from 'reactstrap';

class Resources extends Component {
    state = {
        isExpand: true,
        widgetId: 'widget__Resources',
        //TODO: enable in v1.1
        // menu:[
        //     {id: "Resources_hide", title: "Hide"},
        // ]
    }

    toggleExpand = () => {
        this.setState({isExpand: !this.state.isExpand})
    }

    render() {
        
        return <>
            <Widget data={{title: "Resources", menu: this.state.menu, id: this.state.widgetId}} {...this.props}>
                <div className="myOdu__resources">
                    <List sx={{pt:0}} dense={true}>
                        {ResourceLinks.map(resource =>
                            <ListItem sx={{pl:1}} key={this.state.widgetId + "_ul_li_" + resource.id} divider>
                                <Tooltip id={resource.id + "_tooltip"} title="Opens in new window" enterDelay={750} leaveDelay={0} enterNextDelay={750} arrow>
                                    <Link variant="small" href={resource.link} underline={'hover'} target="_blank" id={resource.id}>{resource.title}</Link>
                                </Tooltip>
                            </ListItem>
                        )}
                    </List>
                </div>
            </Widget>
        </>
    }
}
 
export default Resources;