import React, { Component } from "react";

import { Typography } from "@mui/material";
import Widget from "../common/Widget";
import { WidgetMapping } from "./config";
import { WidgetConfigs } from "../SetUp/config";

class WidgetCard extends Component {
    state ={
    }

    render() {
        const WidgetComponent = WidgetMapping[this.props.widget.id];
        return <React.Fragment>
            <div  className="myOdu__widgetDragAndDrop" style={{marginBottom:'1rem'}}>
                <WidgetComponent widget={this.props.widget} {...this.props}/>
            </div>
        </React.Fragment>
    }
}

export default WidgetCard;