import React, { Component } from 'react';
import { connect } from 'react-redux';
import App from './App';
import { AppConfigs } from './config';
import { Grid, Box, Typography, Button} from '@mui/material';
import { saveSetup } from './actions';
import { Col, Container, Row } from 'reactstrap';

class Apps extends Component {
    state = {  }

    //Θ(N) where N is length of appsToDisplay
    //Selects all the apps for a user
    onSelectAll = () => {
        let setup = {...this.props.setup};
        this.props.setup.appsToDisplay
        .forEach((app) => {
            if(!(app.id in (setup?.Apps ?? {}))) {
                setup['Apps'] = {...(setup?.Apps ?? {}), [app.id]: true}
            }
        });
        this.props.saveSetup(setup);
    }

    //Θ(1) Clears all the selected applications
    onClearSelection = () => {
        let setup = {...this.props.setup}
        delete setup.Apps
        this.props.saveSetup(setup)
    }

    render() {
        return <React.Fragment>
            <Container fluid>
                <Row className="justify-content-end">
                    <Col xs={12} className="ps-0 mt-4"><Typography component="h3" variant="h5" sx={{pl:0}} color='#2188A2'>Favorite Apps</Typography></Col>
                    <Col className="text-start text-sm-end ps-0 ps-sm-0">
                        <Button className="myOdu__button textButton ps-0 ps-sm-2" variant="text" onClick={this.onSelectAll}>Select All</Button>
                        <Button className="myOdu__button textButton" variant="text" onClick={this.onClearSelection}>Clear Selection</Button>
                    </Col>
                </Row>
                <Row>
                    <Grid container alignItems={'stretch'} sx={{p:0}}>
                        {
                            this.props.setup.appsToDisplay
                            ?.map(app => <Grid item sx={{pt:1}} xl={3} lg={4} md={4} sm={6} xs={4}>
                                    <Box sx={{mr:1, height: '100%'}}><App app={app} /></Box>
                                </Grid>)
                        }
                    </Grid>
                </Row>
            </Container>
        </React.Fragment>
    }
}
 
const mapStateToProps = (state) => {
    return {
        setup: state.setupReducer.setup,
        user: state.AWSReducer.user,
    }
  }
  
const mapDispatchToProps = (dispatch) => ({
    saveSetup: (setup) => dispatch(saveSetup(setup))
});
  
export default connect(mapStateToProps, mapDispatchToProps)(Apps);