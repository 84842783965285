import { Button, InputLabel, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";
import React from "react";
import { Col, Row } from "reactstrap";
import Box from '@mui/material/Box';
import PortalAutoComplete from "../../common/PortalAutocomplete";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const DdProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const boxStyle ={
    xs: 0.5,
    xxl:2 
}
class TutoringDetails extends React.Component {

    state = {
    }

    render() {
        return <React.Fragment>
            
            <Typography variant="h4" className="py-3">Tutoring Details</Typography>
            <Row>
                <Row>
                    <Col xs={12} xxl={2}>
                        <Box pt={3} pb={boxStyle}>
                            <InputLabel className="myOdu__label" htmlFor="course-select">
                                Course
                            </InputLabel>
                        </Box>
                    </Col>
                    <Col>
                        <Box py={boxStyle}>
                            <PortalAutoComplete
                                id = "course-select" 
                                options={this.props.courses}
                                disableOption = {this.props.disableOption}
                                selectedTutoring = {this.props.selectedTutoring}
                                isDetails = {this.props.isDetails}
                                placeholder="Enter Course subject, number or title"
                                getOptionDisabled = {(option) => {
                                    return this.props.disableOption(option) && !this.props.compareCourses(option, this.props.selectedTutoring)
                                }}
                                getOptionLabel={(option) => {
                                    if(!option.SUBJECT)
                                        return '';
                                    return option.SUBJECT.concat(" ", option.COURSE_NUMBER, " ", option.COURSE_TITLE)
                                }}
                                onChange = {(_, selectedValue) => {
                                    this.props.onCourseUpdate(selectedValue)
                                }}
                            />
                        </Box>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} xxl={2}>
                        <Box pt={3} pb={boxStyle}>
                            <InputLabel className="myOdu__label" id="location-select-label" htmlFor="location-select-input">Locations</InputLabel>
                        </Box>
                    </Col>
                    <Col>
                        <Box py={boxStyle}>
                            <Select
                                labelId="location-select-label" //for ADA
                                fullWidth size="small"
                                displayEmpty
                                id="location-select"
                                multiple
                                onChange={this.props.onLocationsUpdate}
                                MenuProps={DdProps}
                                value={this.props.selectedTutoring.locations}
                                disabled={!this.props.isDetails}
                                renderValue={(selected) => {
                                    if(!selected.length)
                                        return <span>Select location(s)</span>
                                    
                                    return selected.map((select) => select.text).join(', ')
                                }}
                                input={<OutlinedInput id = "location-select-input" />}
                                >
                                {this.props.locations.map((location) => (
                                    <MenuItem
                                        key={location.text}
                                        value={location}
                                    >
                                    {location.text}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Col>
                </Row>
                <Row>
                    <Col className="p-3">
                        <Box style={{float: 'right'}}>
                            <Button 
                                variant="outlined" sx={{mt:1}} 
                                onClick={this.props.onSubmit}
                                disabled={!this.props.isDetails || !this.props.selectedTutoring.locations.length || !this.props.selectedTutoring.SUBJECT || this.props.isLoading}>
                                {this.props.buttonText}
                            </Button>
                        </Box>
                    </Col>
                </Row>
            </Row>
        </React.Fragment>
    }
}

export default TutoringDetails;