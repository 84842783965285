import React, { Component } from 'react';
import { connect } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { faHouse, faEnvelope, faChalkboardUser, faBallotCheck, faBullhorn, faRightToBracket, faIdBadge, faUserSecret, faTriangleExclamation} from '@fortawesome/pro-light-svg-icons';
import MenuListItem from './MenuListItem';
import { toggleImpersonation } from '../../Impersonation/actions';
import {checklists, profilePhoto, statusAlerts} from '../../../DataAccessLayer/services';
import {getData} from '../../../DataAccessLayer';
import {saveStatusAlerts} from '../../StatusAlerts/actions';
import {saveChecklists} from '../../Checklists/actions';
import { isUserPowerUser } from '../utils';
import WithRouter from '../Router/WithRouter';

export const isProfileUrlValid = (img) => {
    if (img == null)  // if img is null or undefined
        return false;
    if(img.includes('placeholder'))
        return false;
    return true;
};

class SideNav extends Component {
    state = {
        img: ''
    }

    componentDidMount(){
        this.loadStatusAlerts();
        this.loadChecklists();
        this.loadProfilePhoto();
    }

    loadStatusAlerts = () => {
        getData(statusAlerts).then(statusAlerts => {
            this.props.saveStatusAlerts(statusAlerts);
        })
    }

    loadChecklists = () => {
        getData(checklists).then(checklists => {
            this.props.saveChecklists(checklists?.checklists);
        })
    }

    loadProfilePhoto = () => {
        getData(profilePhoto)
        .then(result => {
            this.setState({
                img: result?.img
            })
        })
    }

    avatarSize = () => {
        return (!this.props.data.isOpen ? {width: '40px', height: '40px', margin: '0 auto'}: {width: '100px', height: '100px', margin: '0 auto'})
    }

    render() {
        const isLoggedIn = this.props.user?.firstName ? true : false
        const user = this.props.impersonation?.isImpersonating ? this.props.impersonation : this.props.user
        const isValidUrl = isProfileUrlValid(this.state.img);
        const isPowerUser = isUserPowerUser(user)
        return <React.Fragment>
            <nav style={{overflowY: 'auto'}}>
                <Box sx={{margin: '1rem auto', textAlign: 'center'}}>
                        <Avatar id="sideNav__avatar" sx={this.avatarSize} title={isLoggedIn ? user?.firstName + ' ' + user?.lastName : 'unknown user'} src={isLoggedIn && isValidUrl ? this.state.img : ''} />
                    {this.props.data.isOpen && <Typography id="sideNav__name" component="h2" variant="h6" sx={{margin: '1rem auto'}}>
                        {isLoggedIn ? user?.firstName + ' ' + user?.lastName : 'unknown user'}
                    </Typography>}
                    {!this.props.data.isOpen && <Typography id="sideNav__initials" component="h2" variant="h4" sx={{mt: 2}}>
                        {isLoggedIn ? user?.firstName.charAt(0) + user?.lastName.charAt(0): '?'}
                        </Typography>}
                </Box>

                <Divider style={{backgroundColor: 'white'}} />

                <List sx={{pt:0}}>
                {/* <List sx={{ width: '100%', maxWidth: 360, backgroundColor: '#003057', color: '#DADADA' }}> */}

                {!isLoggedIn && <MenuListItem data={{title: 'Login', isOpen: this.props.data.isOpen }} icon={faRightToBracket}/>}

                {isLoggedIn && <React.Fragment>
                    {isPowerUser && <React.Fragment>
                        <MenuListItem title='Admin Panel' isOpen={this.props.data.isOpen} icon={faIdBadge} onClick={() => this.props.navigate('/admin')}/>
                        <MenuListItem role="button" title='Impersonate' isOpen={this.props.data.isOpen} icon={faUserSecret} onClick={this.props.toggleImpersonation} />
                        <li><Divider style={{backgroundColor: 'white'}} /></li>
                    </React.Fragment>}

                    <MenuListItem
                        title='Home'
                        isOpen={this.props.data.isOpen}
                        icon={faHouse}
                        onClick={() => this.props.navigate('/')}
                    />
                    <MenuListItem
                        title='Outlook'
                        isOpen={this.props.data.isOpen}
                        icon={faEnvelope}
                        onClick={() => {window.open('https://outlook.odu.edu/', '_blank')}}
                    />
                    <MenuListItem
                        title='Canvas'
                        isOpen={this.props.data.isOpen}
                        icon={faChalkboardUser}
                        onClick={() => {window.open('https://canvas.odu.edu/', '_blank')}}
                    />
                    <MenuListItem
                        title='Status Alerts'
                        isOpen={this.props.data.isOpen}
                        icon={faTriangleExclamation}
                        badgeCount={this.props.statusAlertsCount}
                        onClick={() => this.props.navigate('/statusAlerts')}
                    />
                    <MenuListItem title='Checklists'
                        isOpen={this.props.data.isOpen}
                        icon={faBallotCheck}
                        variant={this.props.checklists?.length > 0 ? 'dot' : ''}
                        onClick={() => this.props.navigate('/checklists')}
                    />
                    {/* <MenuListItem title='Communications' isOpen={this.props.data.isOpen} icon={faBullhorn}/> */}
                </React.Fragment>}
                </List>
             </nav>
        </React.Fragment>
    }
}
 
const mapStateToProps = (state) => {
    return {
      user: state.AWSReducer.user,
      impersonation: state.impersonationReducer.impersonation,
      statusAlertsCount: state.myStatusAlertsReducer.count,
      checklists: state.myChecklistsReducer.checklists
    }
  }
  
  const mapDispatchToProps = (dispatch) => ({
    toggleImpersonation: () => dispatch(toggleImpersonation()),
    saveStatusAlerts: (statusAlerts) => dispatch(saveStatusAlerts(statusAlerts)),
    saveChecklists: (checklists) => dispatch(saveChecklists(checklists))
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(SideNav));
