import React, {Component} from 'react';
import Widget from '../common/Widget';
import {Link, List, ListItem, Box, Grid, Typography} from '@mui/material';
import ContactCard from '../common/ContactCard';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAddressBook} from '@fortawesome/pro-light-svg-icons';
import { getData, postData, putData } from '../../DataAccessLayer';
import { myContacts } from '../../DataAccessLayer/services';
import { connect } from 'react-redux';
import { saveContacts, saveContactsResults, saveContactsUpdate, toggleContactsManager, toggleContactsLoading } from './actions';
import ContactsManager from './ContactsManager';
import PortalTooltip from '../common/PortalTooltip';
import {toast} from 'react-toastify';

class MyContacts extends Component {

  state = {
    isExpand: true,
    widgetId: "widget__myContacts",
    isTitleStylized: true,
    title: ["my", "Contacts"],
    menu: [
      {id: 'myContacts_editContacts', title: 'Edit Contacts', onClick: this.props.toggleContactsManager}
      //TODO:  enable in v1.1
      // {id: "myContacts_hide", title: "Hide"},
    ],
    isSearching: false,
  };

  componentDidMount(){
    this.loadContacts()
  }

  componentDidUpdate(prevProps){
    if(this.props.updates && (JSON.stringify(prevProps.updates) != JSON.stringify(this.props.updates)))
      this.onUpdate()
  }

  //Θ(1) load contacts and save
  loadContacts = () => {
    getData(myContacts).then(contacts => {
      this.props.saveContacts(contacts)
    })
    .finally(_ => {
      this.props.toggleContactsLoading()
    })
  }

  //Θ(1) toggle widget expand
  toggleExpand = () => {
    this.setState({isExpand: !this.state.isExpand});
  };

  toggleIsSearching = () => {
    this.setState({
        isSearching: !this.state.isSearching
    })
  }

  //search contacts and save results
  onSearch = () => {
    this.props.toggleContactsLoading();
    this.toggleIsSearching()
    postData(myContacts, this.props.search).then(results => {
      this.props.saveContactsResults(results)
    })
    .finally(() => {
      this.props.toggleContactsLoading();
      this.toggleIsSearching()
    })
  }

  //Θ(1) update contacts and reload
  onUpdate = () => {
    this.props.toggleContactsLoading()
    putData(myContacts, {contacts: this.props.updates.sort()}).then(data => {
      this.loadContacts()
      this.props.saveContactsUpdate();
    })
  }

  render() {
    return (
      <>
        <ContactsManager onSearch={this.onSearch} onUpdate={this.onUpdate} isSearching = {this.state.isSearching} toggleIsSearching = {this.toggleIsSearching}/>
        <Widget
          data={{id: this.state.widgetId, isTitleStylized: this.state.isTitleStylized, title: this.state.title, menu: this.state.menu}}
          {...this.props}
        >
          <Box
            sx={{
              width: '100%',
              py: 2
            }}
            className="bb-1"
          >
            <FontAwesomeIcon
              icon={faAddressBook}
              size="lg"
              style={{marginRight: '0.51rem', marginLeft: '0.5rem'}}
            />
            <Link
              id="myContacts__link_universityDirectory"
              href="http://odu.edu/directory"
              sx={{fontWeight: 600}}
              underline={'hover'}
              target="_blank"
            >
              University Directory
            </Link>
          </Box>
          <Grid container alignItems={'stretch'} sx={{p: 0, mt: 1}} spacing={1} className="myOdu__myContacts_list_wrapper">
            { 
              this.props.contacts.length ?
              this.props.contacts?.map(contact => (
                <Grid
                  item
                  sx={{pt: 1}}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={6}
                  xs={12}
                  key={contact.midas}
                >
                  <ContactCard data={contact} />
                </Grid>
              ))
              : <Grid item>
                  <Typography variant="smaller" xs={{display: 'inline'}}>No contacts found. </Typography>
                  <PortalTooltip id="myBookmarks__addBookmarksLink" title="Open bookmark editor">
                      <Link 
                          id="myContacts__link_addContacts"
                          className="cursor-pointer"
                          variant="smaller" 
                          xs={{display: 'inline', cursor: "pointer"}} 
                          onClick={this.props.toggleContactsManager}>
                          Add contacts.
                      </Link>
                  </PortalTooltip>
              </Grid>
            }
          </Grid>
        </Widget>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contacts: state.myContactsReducer.contacts,
    search: state.myContactsReducer.search,
    updates: state.myContactsReducer.updates,

  }
}

const mapDispatchToProps = (dispatch) => ({
  saveContacts: (contacts) => dispatch(saveContacts(contacts)),
  saveContactsUpdate: (contacts) => dispatch(saveContactsUpdate(contacts)),
  saveContactsResults: (results) => dispatch(saveContactsResults(results)),
  toggleContactsManager: () => dispatch(toggleContactsManager()),
  toggleContactsLoading: () => dispatch(toggleContactsLoading()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MyContacts);
