import React, {Component} from 'react';
import {Link, Stack, Tooltip, Typography} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope, faPhone} from '@fortawesome/pro-light-svg-icons';

class AlertContact extends Component {
  render() {
    const address = this.props.addressLine2
      ? this.props.addressLine1 + ', ' + this.props.addressLine2
      : this.props.addressLine1;
    return (
      <Stack>
        <Typography
          sx={{
            color: 'primary.main',
            fontWeight: '600'
          }}
          variant="small"
          component="h4"
        >
          Contact
        </Typography>
        {this.props.addressLine1 &&
          (this.props.contactUrl ? (
            <div>
              <Tooltip
                id={
                  this.props.alertKey +
                  '_' +
                  this.props.index +
                  '_address_tooltip'
                }
                title="Opens in new window"
                enterDelay={750}
                leaveDelay={0}
                enterNextDelay={750}
                arrow
              >
                <Link
                  href={this.props.contactUrl}
                  variant="small"
                  target="_blank"
                  underline="always"
                  color="inherit"
                  sx={{cursor: 'pointer'}}
                >
                  {address}
                </Link>
              </Tooltip>
            </div>
          ) : (
            <Typography variant="small">{address}</Typography>
          ))}
        {this.props.phoneNumber && (
          <div>
            <Tooltip
              id={
                this.props.alertKey +
                '_' +
                this.props.index +
                '_phone_number_tooltip'
              }
              title={'Call ' + this.props.phoneNumber}
              enterDelay={750}
              leaveDelay={0}
              enterNextDelay={750}
              arrow
            >
              <Link
                href={'tel:' + this.props.phoneNumber}
                variant="small"
                underline="always"
                color="inherit"
                sx={{cursor: 'pointer'}}
              >
                <FontAwesomeIcon
                  icon={faPhone}
                  style={{
                    marginRight: '0.25rem'
                  }}
                />
                {this.props.phoneNumber}
              </Link>
            </Tooltip>
          </div>
        )}
        {this.props.email && (
          <div>
            <Tooltip
              id={
                this.props.alertKey + '_' + this.props.index + '_email_tooltip'
              }
              title={'Email ' + this.props.email}
              enterDelay={750}
              leaveDelay={0}
              enterNextDelay={750}
              arrow
            >
              <Link
                href={'mailTo:' + this.props.email}
                variant="small"
                underline="always"
                color="inherit"
                sx={{cursor: 'pointer'}}
              >
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{
                    marginRight: '0.25rem'
                  }}
                />
                {this.props.email}
              </Link>
            </Tooltip>
          </div>
        )}
      </Stack>
    );
  }
}

export default AlertContact;
