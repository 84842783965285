import React, { Component } from 'react';
import { Box, Typography } from '@mui/material';

class DateBox extends Component {

    state = {
        month: '',
        dateNumber: ''
    }
    
    componentDidMount() {
        const epochTime = this.props.date;
        const date = new Date(epochTime);
        const month = date.toLocaleString('default', { month: 'short' });
        const day = date.getDate();
        this.setState({month, day});
    }
    render() {
        return <Box className="myOdu__dateBox" sx={{ p: 2 }}><Typography className='myOdu__month'>{this.state.month}</Typography><Typography className="myOdu__dateNumber">{this.state.day}</Typography></Box>;
    }
}

export default DateBox;