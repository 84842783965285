
import React, { Component } from 'react';
import { Navigate } from "react-router-dom";
import { Stack, Button, IconButton, Box, Typography, CssBaseline, Theme, useTheme, ThemeProvider } from '@mui/material';
import {Container, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceHandPeeking } from '@fortawesome/pro-light-svg-icons';
import {stopPreview} from './utils';



class SwapBanner extends Component {
	state = {  }

	render() { 
																						// title and label for ADA
		return <>
			<Container fluid className={"myOdu__swapBanner " + (this.props?.className ? this.props.className : '') } role="banner" aria-label="Swap Between New Portal and Old Portal Banner" title="Swap Between New Portal and Old Portal Banner">
				<Row className="justify-content-md-end justify-content-sm-center">
					<Col md={9} sm={12} style={{paddingLeft: 0}}>
						
							
							<div style={{marginTop: '.5rem'}} className="text-md-start text-sm-center text-center ps-md-2">
							   <FontAwesomeIcon icon={faFaceHandPeeking} fixedWidth size="2xl" style={{marginRight: '.5rem', display: 'inline'}}/>
								<Typography sx={{mt: 1, display: 'inline'}}>You are using the 
								<span style={{ fontWeight: 'bold'}}>&nbsp;new&nbsp;</span>
								sneak preview version of the myODU Portal. </Typography>
							</div>
							
					</Col>
					<Col md={3} sm={12} className="text-md-end text-sm-center text-center" style={{paddingLeft: 0}}>
						<Button
                            variant="outlined"
                            className="myOdu__button secondary"
                            sx={{mt:1}}
                            onClick={() => {stopPreview('https://portal.odu.edu/')}}
                        >Stop Sneak Preview</Button>
					</Col>
				</Row>
			</Container>
		</>
	}
}

export default SwapBanner;


  			{/*  */}