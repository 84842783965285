import React from "react";
import { Alert, AlertTitle, Link, Typography, Box, Stack } from "@mui/material";
import PortalTooltip from "./PortalTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


class PortalAlert extends React.Component {
    state = {

    };

    render() {
        return <React.Fragment>
            <Box {...this.props} className={"myOdu__alert " + this.props?.severity} variant="outlined" severity={this.props.severity} sx={{p: 1}}>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    {this.props?.icon && 
                        <Box sx={{height: '40px', width: '40px', margin: '0 auto'}}>
                            <img src={this.props.icon} alt={this.props.alt} />
                        </Box>
                    }
                    <Box sx={{mb: 0}}>
                        {this.props?.titleLink ? 
                            <PortalTooltip id={this.props.id + '__tooltip'}>
                                <Link href={this.props.titleLink} target="_blank" underline="hover" sx={{ cursor: 'pointer', fontWeight: 600 }} >
                                    <Typography component="h3" variant="h6">
                                        {this.props.title}
                                    </Typography>
                                </Link>
                            </PortalTooltip>
                        :
                            <Typography component="h3" variant="h6">
                                {this.props.title}
                            </Typography>
                        }

                        {this.props?.subTitle && 
                            <Typography component="p" variant="smallest" sx={{fontStyle: 'italic'}} >
                                {this.props.subTitle}
                            </Typography>
                        }
                    </Box>
                </Stack>
                <Box className="alertBody">
                    {this.props.children}
                </Box>
            </Box>
        </React.Fragment>
    }
}

export default PortalAlert;