import React, { Component } from 'react';
import { Stack, Typography, Link } from '@mui/material';

export function SafetyContacts (props){
    return(
       <>
            <Stack direction={{md:"row", sm:"row", xs:"row"}} className="mb-2 mb-sm-0" justifyContent={"space-between"} key={props.data.id}>
                <Typography variant="small">{props.data.title}</Typography>
                <Link id={"safetyContacts__link_phone_" + props.data.phoneNumber} href={"tel:" + props.data.phoneNumber} variant="small" underline='hover'>{props.data.phoneNumber}</Link>
            </Stack>
        </>
    );
}

