import { TOGGLE_CCT } from "./actions";

const initialStateCCT = {
    isCCT: false,
    course: {}
}
//needs to persist across pages
export const CCTReducer = (state = initialStateCCT, action) => {
    const { type, payload } = action;
    switch (type) {
        case TOGGLE_CCT: {
            return {...state, isCCT: payload?.course?.SUBJ_DESC ? true : false, course: payload?.course ?? {}}
        }
        default:
            return state;
    }
}