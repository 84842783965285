import React from "react";
import { faFaceAnxiousSweat } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Typography, Button, Stack, Link} from "@mui/material"

const GenericError = () => {
    return <React.Fragment>
        <Stack alignItems={"center"}>
            <FontAwesomeIcon icon={faFaceAnxiousSweat} fixedWidth size="6x"/>
            <Typography component="h2" className="myOdu__errorCode">Oh No!</Typography>
            <Typography component="h3" className="myOdu__errorCode">Something Went Wrong</Typography>
            <Typography component="p" className="myOdu__errorCode">Please try again later, or <a href="https://oduprod.service-now.com/auth_redirect.do?sysparm_stack=no&sysparm_url=https%3A%2F%2Fshibboleth.odu.edu%2Fidp%2Fprofile%2FSAML2%2FRedirect%2FSSO%3FSAMLRequest%3DlVLLTsMwEPyVyPfEbfq2mkqlFaISlKgJHLg59oZacuzgdQr8PWlaVLgUcfXOzszOeI680nHNlo3fmx28NYA%252B%252BKi0QXaaJKRxhlmOCpnhFSDzgmXLh3sWRz1WO%252ButsJoES0RwXlmzsgabClwG7qAEPO3uE7L3vkZGqZVNuyEjPM1CY98jYStq%252BKHmrxBJS4J160AZfqS6LOJeFYXV4PdRyxGBbKiSNW3JSqWBHv3EdAdSORCeZtkjCW6tE9CdlZCSawQSbNYJybaraVwWfChFDOVUFkMxLrkY92cTyUsxKqazFogpR1QHuKwiNrAx6LnxCYl78TDsDcJ%252BP%252B9P2GDEhnE0mU1fSJCeA7lRRirzej294gRCdpfnaZg%252BZnlHcFAS3LZF%252Fy%252B4Z3DYhdZSk8W8q491vt3PRq9b4t81ksUf2nP6U%252BGsV7Oj7806tVqJz2CptX1fOeC%252BvcW7BrpaKu6vmzi%252BKBmWHZR5xw0qMJ7QxVn0939dfAE%253D%26RelayState%3Dhttps%253A%252F%252Foduprod.service-now.com%252Fsp">contact the ITS Help Desk </a>if the issue persists.</Typography>
            <Button className="myOdu__errorCodeButton" href="/" sx={{mt:3}} variant="outlined">
                Go to the home page
            </Button>
        </Stack>
    </React.Fragment>
}

export default GenericError