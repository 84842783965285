import {faTrash} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link, List} from '@mui/material';
import {GridActionsCellItem} from '@mui/x-data-grid';
import {faLocationDot} from '@fortawesome/pro-solid-svg-icons';

const getColumns = actionFunction => {
    return [
        {
            field: 'SUBJECT',
            valueGetter: params => params.row.SUBJECT,
            headerName: 'Subject',
            width: 80
        },
        {
            field: 'COURSE_NUMBER',
            valueGetter: params => params.row.COURSE_NUMBER,
            headerName: '#',
            width: 40
        },
        {
            field: 'COURSE_TITLE',
            valueGetter: params => params.row.COURSE_TITLE,
            headerName: 'Title',
            flex: 3
        },
        {
            field: 'locations',
            headerName: 'Tutoring Info/Location',
            minWidth: 150,
            flex: 2,
            renderCell: params => {
                return (
                    <List>
                        {params.row.locations.map((location, index) => (
                            <span key={location.text}>
                                <span>{index !== 0 ? ', ' : ''}</span>
                                <Link
                                    mr={1}
                                    target="_blank"
                                    href={location.url}
                                >
                                    {location.text}
                                </Link>
                                {location.mapUrl && (
                                    <Link
                                        target="_blank"
                                        href={location.mapUrl}
                                    >
                                        <FontAwesomeIcon
                                            fixedWidth
                                            size="lg"
                                            icon={faLocationDot}
                                        />
                                    </Link>
                                )}
                            </span>
                        ))}
                    </List>
                );
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 80,
            cellClassName: 'actions',
            type: 'actions',
            getActions: event => [
                <GridActionsCellItem
                    icon={<FontAwesomeIcon icon={faTrash} />}
                    label="Delete"
                    onClick={() => actionFunction(event.row)}
                />
            ]
        },
        {field: 'createdAt', valueGetter: params => params.row.createdAt}
    ];
};

export default getColumns;
